import React from "react";
import {Link} from "react-router-dom";
import {LeftArrow} from "../icons/icons/LeftArrow";
import {themeColors} from "../../styles/theme";


const BackToSignInButton = ({title}) => {
    return (
        <Link
            to="/signin"
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px'
            }}
            state={{from: 'auth'}}
            replace={true}
        >
            <LeftArrow color={themeColors.purple}/>
            {title}
        </Link>
    )
}

export default BackToSignInButton;
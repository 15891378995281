import React from "react";
import Header from "../../components/Header";
import {useTranslation} from "react-i18next";
import IFrameComponent from "../../components/IFrameComponent";

function Help() {
  const {t} = useTranslation();

  return (
    <>
      <Header projectName={t('page-titles.settings-help')}/>
      <IFrameComponent
        url={'https://docs.google.com/document/d/1A7C1-p2gfR5Ny9_BF8-y2hTdUbvNLloqAEp86us8BUc/edit?usp=sharing'}
      />
    </>
  );
}

export default Help;

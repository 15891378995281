import {useEffect, useState} from "react";
import {isErrorResponse} from "../../../utils/utils";


type Props = {
  promiseOrPromises: Promise<any> | Promise<any>[];
}

type LoadChartType<T> = {
  loading: boolean;
  data: T | null;
}

export const useLoadChartData = <T>({promiseOrPromises}: Props): LoadChartType<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!loading) {
      loadData(promiseOrPromises);
    }
  }, [promiseOrPromises])

  async function loadData(promiseOrPromises: Promise<any> | Promise<any>[]) {
    setLoading(true);

    const resp = Array.isArray(promiseOrPromises) ? await Promise.all(promiseOrPromises) : await promiseOrPromises;
    if (!isErrorResponse(resp)) {
      setData(resp);
    }

    setLoading(false);
  }

  return {loading, data};
}
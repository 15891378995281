import React, {memo, useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import DropDown, {OptionsAlign} from "../DropDown/DropDown";
import {themeColors} from "../../styles/theme";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectProjectRuns, selectRunIdToCompareWith, selectSelectedRunId} from "../../redux/selectors/selectors";
import {Run} from "../../hooks/projects/useRunsLoader";
import * as CheckboxField from "../form/CheckboxField/CheckboxField";
import {Box} from "@mui/material";
import './run-to-compare-with-selector.scss';
import {setRunIdToCompareWith} from "../../redux/reducers/projectsReducer";
import {isUuidString} from "../../utils/utils";


const RunsToCompareSelector = memo(() => {
    const [enabledComparison, setEnabledComparison] = useState<boolean>(false);
    const runs = useTypedSelector(selectProjectRuns);
    const selectedRunId = useTypedSelector(selectSelectedRunId);
    const runIdToCompareWith = useTypedSelector(selectRunIdToCompareWith);
    const {t} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        setEnabledComparison(false);

        return () => {
            dispatch(setRunIdToCompareWith());
        }
    }, [selectedRunId]);

    const updatedRuns: Run[] = runs.map(run => ({
        ...run,
        onClick: () => dispatch(setRunIdToCompareWith(run.id))
    }));

    const selectedRunIndex = isUuidString(runIdToCompareWith) ? updatedRuns.findIndex(({id}) => id === runIdToCompareWith) : 0;

    const checkboxClickHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const {checked} = event.target;
        const {id} = updatedRuns[selectedRunIndex];

        setEnabledComparison(checked);
        dispatch(setRunIdToCompareWith(checked && id ? id : ''));
    }, [updatedRuns, selectedRunIndex]);

    return (
        <>
            {
                runs.length ?
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '10px',
                            border: `1px solid ${themeColors.grey3}`,
                            borderRadius: 1,
                            padding: '5px'
                        }}
                    >
                        <div className="run-to-compare-with-selector">
                            <CheckboxField.Labeled
                                name=""
                                value={enabledComparison}
                                label={t('header.select-run-to-compare-with')}
                                onChange={checkboxClickHandler}
                                inline
                            />
                            <DropDown
                                selectedOptionInd={selectedRunIndex}
                                options={updatedRuns}
                                coloredButton={true}
                                optionsAlign={OptionsAlign.LEFT}
                                // disabled={allowedModesDontExist}
                                titleColorLight={themeColors.navyBlue}
                                titleColorDark={themeColors.white}
                                optionColorLight={themeColors.navyBlue}
                                optionColorDark={themeColors.white}
                                iconColorLight={themeColors.white}
                                iconColorDark={themeColors.navyBlue}
                                iconRectangleWidth={35}
                                tooltip={t('header.select-run')}
                                disabled={!enabledComparison}
                            />
                        </div>
                    </Box>
                    : null
            }
        </>
    )
})

export default RunsToCompareSelector;
import * as React from "react";
import {Route} from "react-router-dom";
import TransitStopsList from "./TransitStopsList";
import StopEdit from "../Stops/StopEdit";
import BreadcrumbedComponent from "../../../../components/BreadcrumbedComponent/BreadcrumbedComponent";


export const useTransitStopsRoutes = () => {
    return (
        <Route
            path="stops"
            element={<BreadcrumbedComponent/>}
            handle={{crumb: (data: any) => ({name: 'Stops', ...data})}}
        >
            <Route index element={<TransitStopsList/>}/>
            <Route path="new" element={<StopEdit/>}/>
            <Route
                path=":stopId/edit"
                element={<StopEdit/>}
                loader={({params}) => params}
                handle={{crumb: ({stopId}) => ({id: stopId})}}
            />
        </Route>
    )
};
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {getCachedToken} from "../utils/authUtils";


const axiosClient = axios.create({
    baseURL: new URL(process.env.REACT_APP_API_POINT!, process.env.REACT_APP_API_HOST).toString(),
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

axiosClient.interceptors.request.use((req: AxiosRequestConfig) => {
    const token = getCachedToken();

    if (token && req.headers) {
        req.headers['Authorization'] = `Bearer ${token?.accessToken}`;
    }
    return req;
});

axiosClient.interceptors.response.use(
    function (response: AxiosResponse) {
        return response;
    },
    function (error) {
        if (error?.response?.status === 401) {
            localStorage.removeItem('userToken');
            localStorage.removeItem('userName');
            window.location.href = '/'
        }
        return Promise.reject(error);
    }
);

export default axiosClient;
import {useEffect, useRef, useState} from "react";
import {dashboardApi} from "../../api/dashboardApi";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId} from "../../redux/selectors/selectors";
import {isErrorResponse} from "../../utils/utils";
import {LoadingBackdrop} from "../../components/LoadingBackdrop/LoadingBackdrop";
import {getDashboardByType} from "../../containers/SidePanelDashboard/SidePanelDashboard";
import {
  DashboardEmbeddingResultEnvelopeEntity
} from "../../api/entities/local/replancity_DashboardEmbeddingResultEnvelope";


const BiDashboard = () => {
  const [dashboard, setDashboard] = useState<DashboardEmbeddingResultEnvelopeEntity>();
  const [loading, setLoading] = useState<boolean>(false);
  const projectId = useTypedSelector(selectCurrentProjectId);
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    (async function () {
      setLoading(true);

      const resp = await dashboardApi.getMainDashboard({
        projectId,
        abortSignal: abortControllerRef.current.signal
      });
      if (!isErrorResponse(resp)) {
        setDashboard(resp);
      }

      setLoading(false);
    })();
  }, [])

  return (
    <LoadingBackdrop isVisible={loading}>
      {
        dashboard
            ? getDashboardByType(dashboard)
            : null
      }
    </LoadingBackdrop>
  )
}

export default BiDashboard;
import React, {useMemo, useState} from 'react';
import './map-legend.scss';
import {
    selectDistributionsForVisibleLayersById,
    selectLegendsForVisibleLayersById,
} from "../../../redux/selectors/selectors";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {useTranslation} from "react-i18next";
import {DataById} from "../../../redux/map/types";
import {MapConfigLegendProperties} from "../../../api/entities/replancity_MapConfigLegendProperties";
import FeaturesDistribution from "../FeaturesDistribution/FeaturesDistribution";
import {useTheme} from "../../../context/themeContext";
import {MenuDownIcon} from "../../../components/icons/icons/MenuDownIcon";
import {IconButton} from "../../../components/buttons/IconButton/IconButton";
import {themeColors} from "../../../styles/theme";
import {MenuUpIcon} from "../../../components/icons/icons/MenuUpIcon";
import {MapLayerDistributionType} from "../../../api/entities/replancity_MapLayer";


const MapLegend = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const legendsForVisibleLayersById: DataById<MapConfigLegendProperties> = useTypedSelector(selectLegendsForVisibleLayersById);
  const {t} = useTranslation();
  const {theme} = useTheme();

  const legendEntries = useMemo(() => {
      return Object.entries(legendsForVisibleLayersById);
  }, [legendsForVisibleLayersById]);
  const [layerId, legend] = legendEntries?.[0] ?? [];
  const {colorLegend, widthLegend, layerName} = legend ?? {};

  const distributionsForVisibleLayersById: DataById<MapLayerDistributionType & {
        layerName: string
    }> = useTypedSelector(selectDistributionsForVisibleLayersById);
  const distributionsEntries = useMemo(() => {
      return Object.entries(distributionsForVisibleLayersById);
  }, [distributionsForVisibleLayersById]);

  const themeColor = theme === 'light' ? themeColors.navyBlue : themeColors.white;

  const onClick = () => {
    setOpen(open => !open);
  }

  //TODO make normal html layout => remove duplications
  return (
      <>
          {
              legendEntries.length || distributionsEntries.length ?
                  <div className={`map-legend-wrapper ${theme} ${isOpen ? 'open' : ''}`}>
                      <div
                          className={`map-legend-wrapper__header ${theme}`}
                          onClick={onClick}
                      >
                          {t('map.distributions-chart-title')}
                          <IconButton
                              icon={isOpen ? MenuDownIcon : MenuUpIcon}
                              iconColor={themeColor}
                              backgroundColor={theme === 'light' ? themeColors.white : themeColors.navyBlue}
                          />
                      </div>
                      <div className="map-legend-wrapper__content">
                          <FeaturesDistribution distributionsEntries={distributionsEntries}/>
                          {
                              legendEntries.length ?
                                  <div className="map-legend">
                                      <div className="legend-header">
                                          {t('map.legend-title')}
                                      </div>
                                      <div className="legend-body">
                                          <div className="layer-legend">
                                              <div className="legend-column">
                                                  <div className="column-header">
                                                      {t('map.legend-layer-title')}
                                                  </div>
                                                  <span className="row">
                            <span className="row__value">{layerName}</span>
                          </span>
                                              </div>
                                              {
                                                  colorLegend?.length ?
                                                      <div className="legend-column">
                                                          <div className="column-header">
                                                              {t('map.legend-color-title')}
                                                          </div>
                                                          {
                                                              colorLegend?.map(item => {
                                                                  return (
                                                                      <span className="row" key={item.id}>
                                <span
                                    className="color-legend__color row__key"
                                    style={{backgroundColor: item.color}}
                                />
                                <span className="row__value">{item.sign}</span>
                              </span>
                                                                  )
                                                              })
                                                          }
                                                      </div>
                                                      : null
                                              }
                                              {
                                                  widthLegend?.length ?
                                                      <div className="legend-column">
                                                          <div className="column-header">
                                                              {t('map.legend-width-title')}
                                                          </div>
                                                          {
                                                              widthLegend?.map(item => {
                                                                  return (
                                                                      <span className="row" key={item.id}>
                                <span
                                    className="row__key"
                                    style={{
                                        height: parseFloat(item.width),
                                        width: '10px',
                                        backgroundColor: themeColor
                                    }}>
                                </span>
                                <span className="row__value">{item.sign}</span>
                              </span>
                                                                  )
                                                              })
                                                          }
                                                      </div>
                                                      : null
                                              }
                                          </div>
                                      </div>
                                  </div>
                                  : null
                          }
                      </div>
                  </div>
                  : null
          }
      </>
  )
}

export default React.memo(MapLegend);
import React, {useCallback} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {PublicTransitLayerEntityName} from "../../../../api/entities/replancity_PublicTransitLine";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../../../redux/selectors/selectors";
import EntityEditor from "../../../../components/Entities/EntityEditor";
import EntityEditorWrapper from "../../../../components/Entities/EntityEditorWrapper";


const TransitLineEdit = () => {
    const entityName = PublicTransitLayerEntityName.LINES;
    const {lineId} = useParams<any>();
    const sourceId = useTypedSelector((state) => selectMapLayerIdByEntityName(state, entityName));
    const navigate = useNavigate();

    const onSaveFn = useCallback((entity: any) => {
        navigate(lineId ? `../../${entity.id}/edit` : `../${entity.id}/edit`);
    }, [lineId]);

    const onDeleteFn = useCallback((entity: any) => {
        navigate(`../../`);
    }, [lineId]);

    //TODO check response view params in saveFn. there was params: {responseView: 'form'},

    return (
        <EntityEditorWrapper entityName={entityName}>
            <EntityEditor
                entityId={lineId!}
                entityName={entityName}
                layerId={sourceId}
                presetProperty={'publicTransitPreset'}
                onSaveFn={onSaveFn}
                onDeleteFn={onDeleteFn}
            />
        </EntityEditorWrapper>
    )
}

export default TransitLineEdit;
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";


type Props = {
    milliSeconds: number;
    message?: string;
}

const Timer = ({milliSeconds, message}: Props) => {
    let timer = milliSeconds / 1000;
    const [seconds, setSeconds] = useState<number>(milliSeconds / 1000);
    const {t} = useTranslation();

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(timer);
            if (--timer < 0) {
                timer = milliSeconds / 1000;
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, [milliSeconds, timer]);

    return <div>{message} {seconds}</div>;
}

export default Timer;
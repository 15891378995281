import Button from "../components/buttons/Button";
import "./config-editor.scss";
import {projectsApi} from "../api/projectsApi";
import {useEffect, useRef} from "react";
import {useTypedSelector} from "../redux/Hooks/storeSelectors";
import {selectCurrentProjectId} from "../redux/selectors/selectors";


const ConfigEditorPopup: React.FC<{ popupVisibleHandler: any }> = ({
                                                                     popupVisibleHandler,
                                                                   }) => {
  const projId = useTypedSelector(selectCurrentProjectId)
  const text = useRef<Document | null>(null);

  useEffect(() => {
    const parser = new DOMParser();

    if (projId)
      projectsApi.getProjectConfig(projId).then(response => {
        text.current = parser.parseFromString(response, "application/xml")
      })
  }, [projId])

  return (
    <div className="ConfigEditorPopup-bg">
      <div className="ConfigEditorPopup-container">
        <div className="ConfigEditorPopup-content">
          <textarea>
            {text.current as any}
          </textarea>
          <div className="ConfigEditorPopup-buttonBlock">
            <Button text="Ок" colorType="dark" />
            <Button
              onClick={() => popupVisibleHandler(false)}
              text="Отменить"
              colorType="light"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConfigEditorPopup;

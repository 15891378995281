import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId} from "../../../redux/selectors/selectors";
import {useTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import {dashboardApi} from "../../../api/dashboardApi";
import {Card, CardActions, CardContent, CardHeader, Grid} from "@mui/material";
import {isErrorResponse} from "../../../utils/utils";
import {useTheme} from "../../../context/themeContext";
import {themeColors} from "../../../styles/theme";


type Modes = {
  bike: boolean;
  car: boolean;
  delivery: boolean;
  drt: boolean;
  freight: boolean;
  pt: boolean;
  taxi: boolean;
  walk: boolean;
}

const AllowedModes = () => {
  const {t} = useTranslation();
  const {theme} = useTheme();
  const [availableModes, setAvailableModes] = useState<{ name: string; label: string; checked: boolean }[]>([
    {name: 'bike', label: t('dashboard.bike-label'), checked: false},
    {name: 'car', label: t('dashboard.personal-car-label'), checked: false},
    // {name: 'delivery', label: t('dashboard.delivery-label'), checked: false},
    {name: 'drt', label: t('dashboard.drt-label'), checked: false},
    // {name: 'freight', label: t('dashboard.freight-label'), checked: false},
    {name: 'pt', label: t('dashboard.public-transit-label'), checked: false},
    {name: 'taxi', label: t('dashboard.taxi-label'), checked: false},
    {name: 'walk', label: t('dashboard.walk-label'), checked: false},
  ]);
  const projectId: string = useTypedSelector(selectCurrentProjectId);
  const abortControllerRef = useRef(new AbortController());

  const cardBackground = theme === 'light' ? themeColors.white : themeColors.navyBlue;
  const labelColor = theme === 'light' ? themeColors.navyBlue : themeColors.white;
  const mailAddModesMessage = `mailto:jaro@replan.city?subject=Add modes claim #${projectId} !&body=Please fill in modes what do you need and the purpose of it!`;

  useEffect(() => {
    (async function () {
      const allowedModes: Modes = await dashboardApi.getAvailableModes({
        projectId,
        abortSignal: abortControllerRef.current.signal
      })
      if (!isErrorResponse(allowedModes)) {
        setAvailableModes(prevModes => prevModes.map(mode => ({...mode, checked: allowedModes[mode.name]})));
      }
    })();
  }, [projectId])

  return (
    <Card sx={{background: cardBackground}}>
      <CardHeader
        title={t('dashboard.allowed-modes-title')}
        sx={{color: labelColor}}
      />
      <CardContent>
        <Grid container flexDirection='column'>
          {
            availableModes.map(({name, label, checked}, ind) => {
              return (
                <label
                  key={ind}
                  style={{color: labelColor}}
                >
                  <input
                    type="checkbox"
                    name={name}
                    disabled={true}
                    checked={checked}
                    style={{marginRight: '10px'}}
                  />
                  {label}
                </label>
              )
            })
          }
        </Grid>
      </CardContent>
      <CardActions disableSpacing>
        <a href={mailAddModesMessage}>{t('dashboard.click-to-add-modes-msg')}</a>
      </CardActions>
    </Card>
  )
}

export default AllowedModes;
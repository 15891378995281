import {IconProps} from "../icons";


export const HelpIcon = ({width = 16, height = 16, color = "#6F3BB1"}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00065 14.6666C4.32043 14.6626 1.33803 11.6802 1.33398 7.99993V7.8666C1.40727 4.20295 4.42371 1.28523 8.08777 1.33385C11.7518 1.38247 14.6898 4.37919 14.6658 8.04349C14.6419 11.7078 11.665 14.6659 8.00065 14.6666ZM7.98999 13.3333H8.00065C10.9451 13.3303 13.3301 10.9417 13.3287 7.99727C13.3272 5.05279 10.9398 2.6666 7.99532 2.6666C5.05084 2.6666 2.66346 5.05279 2.66198 7.99727C2.66051 10.9417 5.04551 13.3303 7.98999 13.3333ZM8.66732 11.9999H7.33399V10.6666H8.66732V11.9999ZM8.66732 9.99993H7.33399C7.31292 9.13171 7.76474 8.32046 8.51399 7.88126C8.95399 7.54393 9.33399 7.25326 9.33399 6.6666C9.33399 5.93022 8.73703 5.33326 8.00065 5.33326C7.26427 5.33326 6.66732 5.93022 6.66732 6.6666H5.33398V6.6066C5.3447 5.65389 5.86287 4.77927 6.6933 4.3122C7.52373 3.84512 8.54026 3.85656 9.35997 4.3422C10.1797 4.82783 10.678 5.71389 10.6673 6.6666C10.6196 7.38594 10.2338 8.0401 9.62732 8.42993C9.07912 8.77406 8.72381 9.35514 8.66732 9.99993Z"
        fill={color}
      />
    </svg>
  )
}
import {useCallback, useEffect, useRef, useState} from "react";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId, selectProjectPresets, selectSelectedPresetId} from "../../redux/selectors/selectors";
import {presetsApi} from "../../api/presetsApi/presetsApi";
import {isErrorResponse} from "../../utils/utils";
import {useDispatch} from "react-redux";
import {PublicTransitPresetEntity} from "../../api/entities/replancity_PublicTransitPreset";
import {CopyPresetFnArgs} from "../../api/presetsApi/types";
import {addedPresets, ProjectPreset, setSelectedPreset} from "../../redux/reducers/projectsReducer";
import {ApiError} from "../../api/RestClient";
import {entityApi} from "../../api/entityApi";


export type PresetEntityName =
    | 'replancity_PublicTransitPreset'
    | 'replancity_RoadInfrastructurePreset'
    | 'replancity_MaasPreset'
    | 'replancity_EvPreset'
    | 'replancity_ScenarioCasePreset';

type Props = {
    presetEntityName: PresetEntityName;
}

export type AddPresetForm = {
    serviceName: string;
    data: {
        sourcePresetId?: string;
        presetName: string;
    }
}

export const usePresetsLoader = ({presetEntityName}: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const projectId = useTypedSelector(selectCurrentProjectId);
    const presets: ProjectPreset[] = useTypedSelector(selectProjectPresets);
    const selectedPresetId = useTypedSelector(selectSelectedPresetId);
    const dispatch = useDispatch();
    const abortControllerRef = useRef(new AbortController());

    const loadPresets = async (presetEntityName: PresetEntityName) => {
        const resp: PublicTransitPresetEntity[] = await presetsApi.getPresets({
            projectId,
            presetEntityName,
            abortSignal: abortControllerRef.current.signal
        });

        if (!isErrorResponse(resp)) {
            let selectedPresetId = '';
            const options: ProjectPreset[] = resp.map(({id, name, defaultPreset}) => {
                    if (defaultPreset) {
                        selectedPresetId = id;
                    }

                    return {
                        id,
                        title: name,
                        defaultPreset
                    }
                }
            )

            dispatch(addedPresets(options));
            if (resp.length) {
                const id = selectedPresetId ? selectedPresetId : resp[0].id;
                dispatch(setSelectedPreset({presetId: id, presetType: presetEntityName}));
            }
        }
    }

    const cleanPresets = useCallback(() => {
        dispatch(addedPresets());
        dispatch(setSelectedPreset());
    }, []);


    useEffect(() => {
        return () => {
            cleanPresets();
        }
    }, []);

    useEffect(() => {
        (async function () {
            setLoading(true);
            await loadPresets(presetEntityName);
            setLoading(false);
        }());
    }, [projectId, presetEntityName])

    const addPreset = useCallback(async (args: AddPresetForm): Promise<PublicTransitPresetEntity | ApiError> => {
        setLoading(true);

        let resp;
        if (args.data['sourcePresetId'] && args['serviceName']) {
            resp = await presetsApi.copyPreset({
                ...args,
                abortSignal: abortControllerRef.current.signal
            } as CopyPresetFnArgs);
        } else {
            const {presetName, ...restData} = (args).data;

            resp = await entityApi.saveEntity<typeof presetEntityName>({
                entityName: presetEntityName,
                data: {
                    project: {
                        id: projectId
                    },
                    ...restData,
                    name: presetName
                },
                abortSignal: abortControllerRef.current.signal
            });
        }

        if (!isErrorResponse(resp)) {
            await loadPresets(presetEntityName);

            const {id} = resp;
            dispatch(setSelectedPreset({presetId: id, presetType: presetEntityName}));
        }
        setLoading(false);

        return resp;
    }, [presetEntityName, projectId])

    return {loading, presets, selectedPresetId, addPreset};
}
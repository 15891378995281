import React from "react";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {
  selectDistributionsForVisibleLayersById
} from "../../../redux/selectors/selectors";
import {DataById} from "../../../redux/map/types";
import {MapLayerDistributionType} from "../../../api/entities/replancity_MapLayer";
import './features-distribution.scss';
import '../MapLegend/map-legend.scss';
import {useTranslation} from "react-i18next";
import {formatNumber} from "../../../utils/utils";
import {
  FeatureCollectionDistributionGroupEntity
} from "../../../api/entities/replancity_FeatureCollectionDistributionGroup";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type Props = {
  distributionsEntries: [string, MapLayerDistributionType & { layerName: string }][]
}

const FeaturesDistribution = ({distributionsEntries}: Props) => {
  const {t} = useTranslation();

  const requiredProperties: Record<string, {label: string; toShowPropertyName?: string;}> = {
    units: {label: t('feature-distribution.units')},
    avgValue: {label: t('feature-distribution.average-value'), toShowPropertyName: 'showAverage'},
    minValue: {label: t('feature-distribution.min-value'), toShowPropertyName: 'showMinimal'},
    maxValue: {label: t('feature-distribution.max-value'), toShowPropertyName: 'showMaximum'},
    sum: {label: t('feature-distribution.sum'), toShowPropertyName: 'showSum'},
    medianValue: {label: t('feature-distribution.median-value'), toShowPropertyName: 'showMedian'},
    totalElements: {label: t('feature-distribution.elements-num')}
  }

  const getValuesTable = (layerId: string, layerName: string, index: number, entriesObj: MapLayerDistributionType & {
    layerName: string
  }) => {
    return <div key={layerId} className="layer-legend">
      <div className="legend-column">
        {
          !index ?
            <div className="column-header">
              {t('map.legend-layer-title')}
            </div>
            : null
        }
        <span className="row">
          <span className="row__value">{layerName}</span>
        </span>
      </div>
      {
        Object.entries(requiredProperties)
          .map(([property, {label, toShowPropertyName}]) => {
            return (
              <React.Fragment key={property}>
                {
                  label && (toShowPropertyName === undefined || entriesObj[toShowPropertyName]) ?
                    <div className="legend-column">
                      {
                        !index ?
                          <div className="column-header">
                            <span>{label}</span>
                          </div>
                          : null
                      }
                      <span className="row">
                        <span className="row__value">{formatNumber(entriesObj[property])}</span>
                      </span>
                    </div>
                    : null
                }
              </React.Fragment>
            )
          })
      }
    </div>
  }

  const getDistributionChart = (distribution: FeatureCollectionDistributionGroupEntity[], units?: string) => {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          // position: 'top' as const,
          display: false
        },
        title: {
          display: true,
          text: t('map.distributions-chart-title'),
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: `${t('map.distribution-chart-x-axis-label')}, ${units}`
          }
        },
        y: {
          title: {
            display: true,
            text: t('map.distribution-chart-y-axis-label')
          }
        }
      }
    };

    const labels: string[] = [];
    const _data: number[] = [];
    distribution.forEach(item => {
      labels.push(item.groupName);
      _data.push(item.groupCount);
    })

    const data = {
      labels,
      datasets: [
        {
          // label: 'Dataset 1',
          data: _data,
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        }
      ],
    };

    return <Bar options={options} data={data}/>;
  }

  return (
    <>
      {
        distributionsEntries.length ?
          <div className="map-legend">
            <div className="legend-body">
              {
                distributionsEntries.map((distributionById, index) => {
                  const [layerId, entriesObj] = distributionById;
                  const {
                    layerName,
                    distribution,
                    units,
                    showDistribution,
                  } = entriesObj;

                  return (
                    <React.Fragment key={index}>
                      {getValuesTable(layerId, layerName, index, entriesObj)}
                      {
                        showDistribution
                            ? getDistributionChart(distribution, units)
                            : null
                      }
                    </React.Fragment>
                  )
                })
              }
            </div>
          </div>
          : null
      }
    </>
  )
}

export default FeaturesDistribution;
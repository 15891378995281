import React, {useEffect} from "react";
import {useAuth} from "../context/authContext";
import {useNavigate} from "react-router-dom";
import {AuthPagesWrapper} from "../components/AuthPagesWrapper/AuthPagesWrapper";
import {useTranslation} from "react-i18next";
import {LogOutIcon} from "../components/icons/icons/LogOutIcon";

const SignOutPage = () => {
  const {token, loading} = useAuth();
  const navigate = useNavigate();
  const {t} = useTranslation();


  useEffect(() => {
    if (token && !loading) {
      navigate('/', {replace: true});
    }
  }, [token])

  return (
    <AuthPagesWrapper showLogoColumn={false}>
      <div className="check-email-page__row check-email-page__icon">
        <LogOutIcon width={70} height={70}/>
      </div>
      <h1 className="check-email-page__row check-email-page__title">
        {t('sign-out-page.title')}
      </h1>
    </AuthPagesWrapper>
  )
}

export default SignOutPage;
import React, {BaseSyntheticEvent, useCallback, useEffect, useState} from "react";
import {UserIcon} from "./icons/icons/UserIcon";
import {SettingsIcon} from "./icons/icons/SettingsIcon";
import {HelpIcon} from "./icons/icons/HelpIcon";
import {ExitIcon} from "./icons/icons/ExitIcon";
import {Link, useNavigate} from "react-router-dom";
import DropDown, {OptionsAlign} from "./DropDown/DropDown";
import {useTranslation} from "react-i18next";
import {DropDownOptionType} from "./DropDown/Option";
import HeaderLogo from "./HeaderLogo/HeaderLogo";
import i18n from '../translations/i18n';
import {useAuth} from "../context/authContext";
import {isReplanApp} from "../utils/utils";
import ThemeSetter from "./ThemeSetter/ThemeSetter";
import {useTheme} from "../context/themeContext";
import {themeColors} from "../styles/theme";
import {useTypedSelector} from "../redux/Hooks/storeSelectors";
import {selectCurrentProjectId} from "../redux/selectors/selectors";
import LinkWrapper from "./LinkWrapper/LinkWrapper";


type Props = {
  projectName?: string;
}

function Header({projectName}: Props) {
  const projectId = useTypedSelector(selectCurrentProjectId)
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {theme} = useTheme();
  const {user, runLogout} = useAuth();
  const [langOptionInd, setLangOptionInd] = useState<number>();

  const getLangOptions = useCallback((): DropDownOptionType[] => {
    const langOptions: DropDownOptionType[] = [
      {
        title: 'En',
        name: 'en-US',
        onClick: () => changeLanguage('en-US'),
      },
      {
        title: 'De',
        name: 'de-DE',
        onClick: () => changeLanguage('de-DE'),
      }
    ];

    if (!isReplanApp(window.location.origin)) {
      langOptions.push({
        title: 'Ру',
        name: 'ru-RU',
        onClick: () => changeLanguage('ru-RU')
      });
    }

    return langOptions;
  }, [])

  useEffect(() => {
    const storedLang = localStorage.getItem('i18nextLng');
    const ind = getLangOptions().findIndex(({name}) => name === storedLang);
    if (ind !== -1) {
      setLangOptionInd(ind);
    } else {
      setLangOptionInd(0);
    }
  }, [getLangOptions])

  function settingsClickHandler(name: string | null) {
    navigate(`/settings/${name}`);
  }

  const onSignOut = async () => {
    await runLogout();
  }

  const dropDownOptions: DropDownOptionType[] = [
    {
      link: '/settings/profile',
      title: t('header.user-profile'),
      onClick: (e: BaseSyntheticEvent) => {
        settingsClickHandler('profile');
      },
      image: <SettingsIcon color={theme === 'light' ? themeColors.bluePurple : themeColors.grey3}/>,
    },
    {
      link: '/settings/help',
      title: t('header.help'),
      onClick: (e: BaseSyntheticEvent) => {
        settingsClickHandler('help');
      },
      image: <HelpIcon color={theme === 'light' ? themeColors.bluePurple : themeColors.grey3}/>,
    },
    {
      link: '/signout',
      replace: true,
      title: t('header.exit'),
      onClick: onSignOut,
      image: <ExitIcon color={theme === 'light' ? themeColors.bluePurple : themeColors.grey3}/>,
    }
  ]

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  return (
    <header className={`header ${theme}`}>
      <div className="header__tab-block">
        <Link to="/">
          <div className={`${theme} main-tab`}>
            <div className="tab-content">
              <HeaderLogo/>
            </div>
          </div>
        </Link>
        {
          projectName &&
            <>
                <div className="tab-content">
                    <span className={`${theme} tab-text`}>/</span>
                </div>
                <div className="tab-content">
                  <span className={`${theme} tab-text`}>
                    <LinkWrapper style={{color: themeColors.grey}} to={`/${projectId}`} hasLink={!!projectId}>
                      {projectName}
                    </LinkWrapper>
                  </span>
                </div>
            </>
        }
      </div>
      <div className="header__right-block">
        <DropDown
          title={
            <>
              <span className={`user-text ${theme}`}>{user}</span>
              <UserIcon color={theme === 'light' ? themeColors.grey : themeColors.grey3}/>
            </>
          }
          options={dropDownOptions}
          containerClassName="user-content"
          optionsAlign={OptionsAlign.RIGHT}
          optionColorLight={themeColors.navyBlue}
          optionColorDark={themeColors.white}
          iconColorDark={themeColors.grey3}
        />
        <div className="lang-selector">
          {
            langOptionInd !== undefined &&
              <DropDown
                  titleColorLight={themeColors.grey}
                  titleColorDark={themeColors.grey3}
                  options={getLangOptions()}
                  selectedOptionInd={langOptionInd}
                  optionsAlign={OptionsAlign.RIGHT}
                  optionColorLight={themeColors.navyBlue}
                  optionColorDark={themeColors.white}
                  iconColorDark={themeColors.grey3}
              />
          }
        </div>
        {/*<ThemeSetter/>*/}
      </div>
    </header>
  );
}

export default Header;

import React, {useState} from "react";
import "./project-start.scss";
import Button from "../components/buttons/Button";
import ConfigEditorPopup from "./ConfigEditorPopup";
import GroupBox from "../components/groupBox/GroupBox";
import ProgressBar from "@ramonak/react-progress-bar";
import {useNavigate} from "react-router-dom";
import {themeColors} from "../styles/theme";
import Header from "../components/Header";
import {useTranslation} from "react-i18next";

const inputName = ["Общая статистика", "Road Pricing", "Emissions"];

interface IProgressBar {
  isVisible: boolean;
  completed: number;
}

function ProjectStart() {
  const navigate = useNavigate();
  const [checkedItems, setcheckedItems] = useState<Array<number>>([]);
  const [popupVisible, setpopupVisible] = useState<boolean>(false);
  //eslint-disable-next-line
  const [progressBar, setProgressBar] = useState<IProgressBar>({
    isVisible: false,
    completed: 0,
  });
  const {t} = useTranslation();

  function popupVisibleHandler(bool: boolean) {
    setpopupVisible(bool);
  }

  function onInputClickHandler(index: number) {
    const arr = checkedItems.slice(0);
    if (!arr.includes(index)) {
      arr.push(index);
      setcheckedItems(arr.sort());
    } else {
      arr.splice(arr.indexOf(index), 1);
      setcheckedItems(arr.sort());
    }
  }

  return (
    <>
      <Header projectName={t('page-titles.generation')}/>
      <div className="proj-block">
        <div className="proj-container">
          <h1>Параметры запуска проекта</h1>
          <GroupBox header="Основные параметры">
            <div className="control-group">
              <label htmlFor="Iterations">Количество итераций</label>
              <input
                type="number"
                defaultValue={100}
                id="Iterations"
                className="datetime"
              />
            </div>
          </GroupBox>
          <GroupBox header="Модули">
            {inputName.map((el, index) => {
              return (
                <div key={index} className="control-group">
                  <label htmlFor={el}>{el}</label>
                  <div className="input">
                    <input
                      id={el}
                      onClick={() => onInputClickHandler(index)}
                      type="checkbox"
                    />
                  </div>
                </div>
              );
            })}
          </GroupBox>

          <div className="proj-start-button-block">
            <Button
              onClick={() => {
                navigate("/Generation/Output");
              }}
              text="Запустить проект"
              colorType="dark"
            />
            <Button
              onClick={() => popupVisibleHandler(!popupVisible)}
              text="Редактор конфига"
              colorType="light"
            />
          </div>
          {progressBar.isVisible && (
            <ProgressBar
              completed={progressBar.completed}
              bgColor={themeColors.bluePurple}
              baseBgColor={themeColors.lightGrey}
              labelColor="#f4f3f3"
              margin="30px 0 0 0"
              transitionDuration=""
              animateOnRender
              maxCompleted={100}
              labelClassName="ProgressBar"
            />
          )}
        </div>

        {popupVisible && (
          <ConfigEditorPopup popupVisibleHandler={popupVisibleHandler}/>
        )}
      </div>
    </>
  );
}

export default ProjectStart;

import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import langDetector from "i18next-browser-languagedetector";
import * as translEn from './locales/en/en.json';
import * as translRu from './locales/ru/ru.json';
import * as translDe from './locales/de/de.json';
import {isReplanApp} from "../utils/utils";


i18n
    .use(langDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {translation: translEn},
            de: {translation: translDe},
            ...(isReplanApp(window.location.origin) ? {} : {ru: {translation: translRu}}),
        },
        fallbackLng: "en",
        // fallbackNS: "en",
        // ns: ['en', 'ru'],
        defaultNS: 'translation',
        interpolation: {
            escapeValue: false
        },
        debug: process.env.NODE_ENV === "development"
    });

export default i18n;


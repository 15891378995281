import React, {memo, useCallback} from "react";
import {IconButton} from "../../buttons/IconButton/IconButton";
import {RestoreIcon} from "../../icons/icons/RestoreIcon";
import Tooltip from "@mui/material/Tooltip";
import {useTranslation} from "react-i18next";
import {SimulationState} from "../../../api/entities/replancity_RunnedAlgorithm";
import {projectsApi} from "../../../api/projectsApi";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectSelectedRunId} from "../../../redux/selectors/selectors";


const RestartSimulationButton = ({simulationState}: { simulationState: SimulationState }) => {
    const selectedRunId = useTypedSelector(selectSelectedRunId);
    const {t} = useTranslation();

    const restartSimulation = useCallback(async (runId: string) => {
        const resp = await projectsApi.restartSimulation({runId});
    }, [])

    return (
        <Tooltip title={t('simulation.restart-simulation-tooltip')}>
            <IconButton
                icon={RestoreIcon}
                width={20}
                height={20}
                onClick={() => restartSimulation(selectedRunId)}
                disabled={
                    simulationState === SimulationState.NEVER
                    || simulationState === SimulationState.RESTARTING
                    || simulationState === SimulationState.TERMINATING
                }
                borderless
            />
        </Tooltip>
    );
}

export default memo(RestartSimulationButton);
import React from 'react';
import './loading-backdrop.scss';
import LoadingSpinner from "../Spinner/LoadingSpinner";

interface Props {
  isVisible: boolean;
  transparent?: boolean;
  showSpinner?: boolean;
  msg?: string;
}

export const LoadingBackdrop = ({isVisible = false, transparent = false, showSpinner = true, msg, children}: React.PropsWithChildren<Props>) => {
  const mouseDownHandler = (event: React.SyntheticEvent) => {
    event.stopPropagation();
  }

  return (
    <>
      {children}
      {isVisible &&
          <div className="loading-backdrop" onMouseDown={mouseDownHandler}>
            {
              showSpinner ? <div>
                    <LoadingSpinner transparent={transparent}/>
                    {
                      msg ?
                          <div className="msg-wrapper">{msg}</div>
                          : null
                    }

                  </div>
                  : null
            }
          </div>
      }
    </>
  )
}
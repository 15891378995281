import React from "react";
import {useTranslation} from "react-i18next";
import {GroupSelectionIcon, Layers1Icon, RoadsEditorIcon} from "../../../components/icons/icons";
import VerticalTabs from "../../../components/VerticalTabs/VerticalTabs";


const RoadNetworkManager = () => {
    const {t} = useTranslation();

    const tabs = [
        {
            url: './',
            title: t('road-network.roads'),
            path: undefined,
            icon: <RoadsEditorIcon/>,
            testId: 'roadsVerticalTab'
        },

        // {
        //   url: './toll-pairs',
        //   title: t('road-network.toll-pairs'),
        //   path: 'toll-pairs'
        // },
        // {
        //   url: './toll-zones',
        //   title: t('road-network.toll-zones'),
        //   path: 'toll-zones'
        // },
        {
            url: './layers',
            title: t('road-network.layers'),
            path: 'layers',
            icon: <Layers1Icon/>,
            testId: 'layersVerticalTab'
        },
        {
            url: './groupselection',
            title: t('road-network.group-selection'),
            path: 'groupselection',
            icon: <GroupSelectionIcon/>,
            testId: 'groupSelectionVerticalTab'
        },
    ];

    return <VerticalTabs tabs={tabs} />;
}

export default RoadNetworkManager;
import {BaseEntity, BaseEntityNameLess} from "./BaseEntity";
import {Mode} from "../../containers/Dashboard/types";

export type PublicTransitLineEntity = {
  routes: any[],
  color: string;
  fare: string;
} & BaseEntity;

export type PublicTransitRouteDirection = 'FORWARD' | 'BACKWARD' | 'UNDEFINED';

export type PublicTransitVehicleType = {
  id?: string;
  name: string;
  wheelchairCapacity: number;
  equivalentToPassengerCarBySize: number;
  maximumVelocityKpH: number;
  bikeAccess: true;
  mode: 'Tram' | 'Bus';
  widthMeters: number;
  additionalStopDelayInSeconds: number;
  egressTimeSeconds: number;
  numberOfDoors: number;
  doorOperationType: string;
  bikeCapacity: number;
  version: number;
  capacitySeatedPersons: number;
  lengthMeters: number;
  capacityStandingPersons: number;
  wheelchairAccess: boolean;
  capacityPersonsTotal: number;
  accessTimeSeconds: number;
}

export type PublicTransitRouteEntity = {
  direction?: PublicTransitRouteDirection;
  color: string;
  geometryJson: string;
  lengthInMeters: number;
  offset: number;
  timeInSeconds: number;
  width: number;
  defaultRouteProfile?: BaseEntityNameLess;
  defaultSchedule?: BaseEntityNameLess;
  defaultVehicleType?: PublicTransitVehicleType;
  publicTransitLine?: PublicTransitLineEntity;
} & BaseEntity;

export type PublicTransitStopEntity = {
  lat: number;
  lon: number;
  mode: Mode; // maybe this is a wrong type
  blocking: boolean;
  geometry: string;
  geometryJson: string;
  publicTransitStopSite: any;
  type: string;
  version: string;
  width: number;
} & BaseEntity;

export type PublicTransitRouteProfileStopEntity = {
  publicTransitRouteProfile: any;
  transitStop: PublicTransitStopEntity;
} & BaseEntityNameLess;

export type PublicTransitScheduleType = 'DEPARTURE' | 'INTERVAL';

export type PublicTransitScheduleElementEntity = {
  "type": PublicTransitScheduleType,
  "startTime": string;
  "interval": number;
  "endTime": string;
} & BaseEntity;

export enum PublicTransitLayerEntityName {
  LINES = 'replancity_PublicTransitLine',
  STOPS = 'replancity_PublicTransitStop',
  ROUTES = 'replancity_PublicTransitRoute',
  ROUTE_STOPS = 'replancity_PublicTransitRouteProfileStop',
  DEPARTURE = 'replancity_PublicTransitScheduleElement'
}
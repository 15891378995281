import React from "react";
import {Link, LinkProps} from "react-router-dom";


type Props = {
    hasLink?: boolean;
} & LinkProps

const LinkWrapper: React.FC<Props> = ({hasLink = true, children, ...linkProps}) => {

    if (hasLink) {
        return (
            <Link
                {...linkProps}
            >
                {children}
            </Link>
        )
    }

    return (
        <div className="breadcrumbs__item">
            {children}
        </div>
    )
}

export default LinkWrapper;
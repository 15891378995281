import * as React from "react";
import {Route} from "react-router-dom";
import BiModePage from "./BiModePage";
import SuperSetDashboard from "../../containers/Dashboard/SuperSetDashboard";


export const useBiDashboardsRoutes = () => {
    return (
        <>
            <Route path="dashboard" element={<BiModePage/>}/>
            <Route path="dashboard/:runId/:dashboardId/sdashboard" element={<SuperSetDashboard/>}/>
        </>
    )
}
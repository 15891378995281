import React, {useCallback} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../../redux/selectors/selectors";
import EntityEditor from "../../../components/Entities/EntityEditor";
import {DrtZoneEntity} from "../../../api/entities/replancity_DrtZone";
import EntityEditorWrapper from "../../../components/Entities/EntityEditorWrapper";


const MaasZoneEdit = () => {
    const entityName = 'replancity_DrtZone';
    const {zoneId} = useParams<any>();
    const sourceId = useTypedSelector((state) => selectMapLayerIdByEntityName(state, entityName));
    const navigate = useNavigate();

    const onSaveFn = useCallback((entity: any) => {
        navigate(`../${entity.id}/edit`);
    }, []);

    //TODO redirect to entities list if opend entity doesn't exist (was deleted)

    return (
        <EntityEditorWrapper entityName={entityName}>
            <EntityEditor<DrtZoneEntity>
                entityId={zoneId!}
                entityName={entityName}
                layerId={sourceId}
                presetProperty={'maasPreset'}
                onSaveFn={onSaveFn}
            />
        </EntityEditorWrapper>
    )
}

export default MaasZoneEdit;
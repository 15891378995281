import {useCallback, useEffect, useRef, useState} from "react";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {
  selectCurrentProjectId,
  selectCurrentProjectSimulationState,
  selectSelectedRunId
} from "../../redux/selectors/selectors";
import {projectsApi} from "../../api/projectsApi";
import {SimulationState} from "../../api/entities/replancity_RunnedAlgorithm";
import {useDispatch} from "react-redux";
import {setSelectedRunId} from "../../redux/reducers/projectsReducer";
import {isErrorResponse} from "../../utils/utils";
import {addedLayerSimulationState} from "../../redux/map/map-reducer";
import {entityApi} from "../../api/entityApi";
import {ProjectRunProcessView} from "../../api/entities/replancity_Project";


export interface SimulationStateData {
  simulationState: SimulationState;
  simulationProgress: number;
}

interface SimulationStateLoader {
  simulation: SimulationStateData;
  startSimulation: (data: any) => Promise<void>;
  getSimulationOptions: () => Promise<ProjectRunProcessView>;
}

type Props = {
  simulationCompletedFn?: () => Promise<void>;
}

const useSimulationStateLoader = ({simulationCompletedFn}: Props): SimulationStateLoader => {
  const entityName = 'replancity_Project';
  const projectSimulationState: SimulationStateData = useTypedSelector(selectCurrentProjectSimulationState);
  const [simulation, setSimulation] = useState<SimulationStateData>(projectSimulationState);
  const projectId = useTypedSelector(selectCurrentProjectId);
  const selectedRunId = useTypedSelector(selectSelectedRunId);
  const sseConnectionRef = useRef<EventSource | null>(null);
  const sseLayersConnectionRef = useRef<EventSource | null>(null);
  const simulationStarted = useRef<boolean>(false);
  const abortControllerRef = useRef(new AbortController());
  const dispatch = useDispatch();

  const sseSimulationMsgHandler = useCallback((event) => {
    try {
      const data = JSON.parse(event.data);
      const {runId, state, percentage, iteration} = data;

      setSimulation({simulationState: state, simulationProgress: parseFloat(percentage)});
    } catch (error) {
      console.error('sseSimulationMsgHandler error:', error);
    }

  }, [setSimulation])

  const sseLayersMsgHandler = useCallback((event) => {
    try {
      const data = JSON.parse(event.data);
      const {runId, layerId, state} = data;

      if (layerId && state) {
        dispatch(addedLayerSimulationState({layerId: layerId, calculationState: state}));
      }
    } catch (error) {
      console.error('sseLayersMsgHandler error:', error);
    }

  }, [addedLayerSimulationState, dispatch])

  const sseErrorHandler = useCallback((event) => {
      console.error('sseErrorHandler:', event);
  }, [])

  useEffect(() => {
    if (selectedRunId) {
      removeSseConnection();
      addSseConnection(selectedRunId);
    }

    return () => {
      if (selectedRunId) {
        removeSseConnection();
      }
    };
  }, [selectedRunId])

  function addSseConnection(selectedRunId: string) {
    if (!sseConnectionRef.current && selectedRunId) {
      sseConnectionRef.current = new EventSource(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_SSE_POINT}replan-run-sse-sim-stream/${selectedRunId}`);
      sseConnectionRef.current.addEventListener('message', sseSimulationMsgHandler);
      sseConnectionRef.current.addEventListener('error', sseErrorHandler);
    }
    if (!sseLayersConnectionRef.current && selectedRunId) {
      sseLayersConnectionRef.current = new EventSource(`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_SSE_POINT}replan-layer-sse-stream/${selectedRunId}`);
      sseLayersConnectionRef.current.addEventListener('message', sseLayersMsgHandler);
      sseLayersConnectionRef.current.addEventListener('error', sseErrorHandler);
    }
  }

  function removeSseConnection() {
    if (sseConnectionRef.current) {
      sseConnectionRef.current.removeEventListener('message', sseSimulationMsgHandler);
      sseConnectionRef.current.removeEventListener('error', sseErrorHandler);
      sseConnectionRef.current.close();
      sseConnectionRef.current = null;
    }
    if (sseLayersConnectionRef.current) {
      sseLayersConnectionRef.current.removeEventListener('message', sseLayersMsgHandler);
      sseLayersConnectionRef.current.removeEventListener('error', sseErrorHandler);
      sseLayersConnectionRef.current.close();
      sseLayersConnectionRef.current = null;
    }
  }

  async function startSimulation(data: any) {
    const resp = await projectsApi.startSimulation(data);

    if (!isErrorResponse(resp)) {
      const {id} = resp;
      dispatch(setSelectedRunId(id));
      simulationStarted.current = true;
    }

    await simulationCompletedFn?.();

    return resp;
  }

  async function getSimulationOptions(): Promise<ProjectRunProcessView> {
    const resp: ProjectRunProcessView = await entityApi.getEntity({
      entityId: projectId,
      entityName,
      params: {view: 'project-view_for_run_process'},
      abortSignal: abortControllerRef.current.signal
    });

    return resp;
  }

  return {simulation, startSimulation, getSimulationOptions};
}

export default useSimulationStateLoader;
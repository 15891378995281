import {StyledSlider} from "../../form/SliderField/SliderField";
import React, {memo} from "react";
import {useTripsLayerContext} from "../../../context/tripsLayerContext";


const marks = Array.from(Array(25), (_, i) => ({
    value: i * 3600,
    label: i
}));

const TripsLayerTimeSlider = () => {
    const {time, updateTimeBySliderClick} = useTripsLayerContext();

    return (
        <StyledSlider
            key={time} // required to rerender => update position of "point"
            value={time}
            marks={marks}
            step={900}
            min={0}
            max={86400}
            onChange={updateTimeBySliderClick}
        />
    )
}

export default memo(TripsLayerTimeSlider);
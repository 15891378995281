import {useCallback, useState} from "react";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId} from "../../redux/selectors/selectors";
import {presetsApi} from "../../api/presetsApi/presetsApi";
import {PublicTransitPresetEntity} from "../../api/entities/replancity_PublicTransitPreset";
import {isErrorResponse} from "../../utils/utils";
import {Option} from "../../components/form/SelectBox/SelectBox";
import {TraceRoutesToNetworkData} from "../../api/presetsApi/types";
import {useDispatch} from "react-redux";
import {setMapConfigReloadRequired} from "../../redux/map/map-reducer";
import {EntityServiceName} from "../../api/enums/enums";


export const useTraceRoutesToNetwork = () => {
    const roadsPresetEntityName = 'replancity_RoadInfrastructurePreset';
    const [loading, setLoading] = useState<boolean>(false);
    const [roadPresetsOptions, setRoadPresetsOptions] = useState<Option[]>([]);
    const projectId = useTypedSelector(selectCurrentProjectId);
    const dispatch = useDispatch();

    const loadRoadPresets = useCallback(async () => {
        const abortController = new AbortController();

        setLoading(true);

        const resp: PublicTransitPresetEntity[] = await presetsApi.getPresets({
            projectId,
            presetEntityName: roadsPresetEntityName,
            abortSignal: abortController.signal
        });

        if (!isErrorResponse(resp)) {
            const options: Option[] = resp.map(({id, name}) => ({
                value: id,
                caption: name,
            }));
            setRoadPresetsOptions(options);
        }

        setLoading(false);
    }, [roadsPresetEntityName, projectId])

    const traceRoutesToNetwork = useCallback(async (data: TraceRoutesToNetworkData) => {
        const abortController = new AbortController();

        setLoading(true);

        // replancity_ReadOnlyLayerEntity
        const resp = await presetsApi.traceRoutesToNetwork({
            serviceName: EntityServiceName.PUBLIC_TRANSIT,
            data,
            abortSignal: abortController.signal
        });

        dispatch(setMapConfigReloadRequired(true));
        setLoading(false);

        return resp;
    }, []);

    return {loading, loadRoadPresets, roadPresetsOptions, traceRoutesToNetwork}
}
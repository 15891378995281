import {Dispatch, SetStateAction} from "react";
import {Step, StepButton, StepLabel, Stepper} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useTheme} from "../../../../context/themeContext";
import {themeColors} from "../../../../styles/theme";


type Props = {
    activeStep: number;
    setActiveStepFn: Dispatch<SetStateAction<number>>;
    onStepChangeFn?: () => void;
}

const CustomizedStep = styled(Step)(() => {
    const {theme} = useTheme();
    const color = theme === 'light' ? themeColors.navyBlue : themeColors.white;

    return {
        '& .MuiStepLabel-label.Mui-active': {
            color
        },
        '& .MuiStepLabel-label.Mui-completed': {
            color
        },
        '& .MuiStepLabel-label': {
            color
        }
    }
});

const SelectionStepper = ({activeStep, setActiveStepFn, onStepChangeFn}: Props) => {
    const goToFirstStep = () => {
        setActiveStepFn(0);
        onStepChangeFn?.();
    }

    return (
        <div>
            <Stepper activeStep={activeStep}>
                <CustomizedStep>
                    <StepButton onClick={goToFirstStep} data-testid={'groupSelectionStepBtn'}>
                        <StepLabel>
                            Selection
                        </StepLabel>
                    </StepButton>
                </CustomizedStep>
                <CustomizedStep>
                    <StepLabel>Update</StepLabel>
                </CustomizedStep>
            </Stepper>
        </div>
    )
}

export default SelectionStepper;
import {OneWayField} from "../../api/entities/replancity_RoadInfrastructureRoadName";

export type RoadType = {
  id: string;
  name: string;
}

export type RoadSegmentType = {
  id: string;
  lanesPsvBackward: number;
  access: string;
  modes: string;
  freeSpeed: number;
  tollTypeFourForward: number;
  lanesPsvForward: number;
  capacityForward: number;
  tollTypeFourBackward: number;
  capacity: number;
  maxSpeedForward: number;
  freeSpeedBackward: number;
  capacityBackward: number;
  lanesForward: number;
  geometryJson: string;
  lanes: number;
  highway: string;
  tollTypeTwoForward: number;
  tollBackward: number;
  tollTypeThreeBackward: number;
  maxSpeed: number;
  maxSpeedBackward: number;
  tollTypeThreeForward: number;
  version: number;
  lanesBackward: number;
  oneway: OneWayField,
  tollTypeTwoBackward: number;
  tollForward: number;
  lanesPsv: number;
  geometry: string;
  freeSpeedForward: number
}

export type TollPairType = {
  toRoadSegment: {
    id: string;
  },
  fromRoadSegment: {
    id: string;
  },
  toll?: number;
  tollTypeTwo?: number;
  tollTypeThree?: number;
  tollTypeFour?: number;
}

export type TollPairFlatType = {
  toRoadSegment: string;
  fromRoadSegment: string;
  toll?: number;
  tollTypeTwo?: number;
  tollTypeThree?: number;
  tollTypeFour?: number;
}

export const HIGHWAY_TYPES = [
    '',
    'MOTORWAY',
    'PRIMARY',
    'SECONDARY',
    'TERTIARY',
    'UNCLASSIFIED',
    'RESIDENTIAL',
    'SERVICE',
    'LIVING_STREET',
    'ROAD',
    'OTHER',
    'MOTORWAY_LINK',
    'TRUNK_LINK',
    'PRIMARY_LINK',
    'SECONDARY_LINK',
    'TERTIARY_LINK'
];
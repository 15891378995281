import React, {useCallback, useState} from "react";
import ProjectsTable from "./ProjectsTable";
import ProjectsGrid from "./ProjectsGrid";
import {ProjectEntity} from "../api/entities";
import DropDown from "./DropDown/DropDown";
import {useTranslation} from "react-i18next";
import SearchHeader from "./SearchHeader";
import AddProjectPopup from "../containers/AddProjectPopup";
import FilesUploader from "../containers/FilesUploader";
import {DropDownOptionType} from "./DropDown/Option";
import {useTypedSelector} from "../redux/Hooks/storeSelectors";
import {selectProjects} from "../redux/selectors/selectors";
import {useNavigate} from "react-router-dom";
import "../css/models.scss";
import {ThemeType, useTheme} from "../context/themeContext";
import {ViewGridOutlineIcon} from "./icons/icons/ViewGridOutlineIcon";
import {ViewHeadlineIcon} from "./icons/icons/ViewHeadlineIcon";
import {IconButton} from "./buttons/IconButton/IconButton";
import {themeColors} from "../styles/theme";
import {CheckIcon} from "./icons/icons/CheckIcon";
import {PlusIcon} from "./icons/icons/PlusIcon";
import Header from "./Header";
import {SearchIcon} from "./icons/icons/SearchIcon";
import DeleteProjectModal from "../containers/DeleteProjectModal/DeleteProjectModal";
import Button from "./buttons/Button";


enum SortBy {
  ALPHABET,
  DATE
}

enum ViewType {
  LINEAR,
  GRID
}

function Projects() {
  const [viewType, setViewType] = useState<ViewType>(ViewType.LINEAR);
  const [sort, setSort] = useState<SortBy>(SortBy.DATE);
  const [search, setSearch] = useState<string>('');
  const [selectedProjectId, setSelectedProjectId] = useState<string>('');
  const [popupVisible, setPopupVisible] = useState<string>("");
  const projects: Array<ProjectEntity> = useTypedSelector(selectProjects);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {theme} = useTheme();

  const sortByAlphabet = (a: ProjectEntity, b: ProjectEntity) => {
    return a.projectName.localeCompare(b.projectName, undefined, {sensitivity: 'base'});
  }

  function sortByDate(a: ProjectEntity, b: ProjectEntity) {
    const dateA: Date = new Date(a.updateTs);
    const dateB: Date = new Date(b.updateTs);
    return dateA < dateB ? 1 : -1;
  }

  const getSortLabel = (sortType: SortBy): string => {
    switch (sortType) {
      case SortBy.ALPHABET:
        return t('projects-main.sort-alphabetically');
      case SortBy.DATE:
        return t('projects-main.sort-by-update-date');
    }
  }

  const sortProjects = useCallback(() => {
    if (search) {
      return projects.slice().filter(({projectName}) => projectName.toLowerCase().includes(search.toLowerCase()));
    }

    return projects.slice().sort(sort === SortBy.DATE ? sortByDate : sortByAlphabet);
  }, [projects, sort, search]);

  function onModelClickHandler(projectName: string, projectId: string) {
    if (!projectName) {
      return;
    }
    navigate(`/${projectId}`);
  }

  function showPopup() {
    switch (popupVisible) {
      case 'model':
        return <AddProjectPopup setVisible={setPopupVisible}/>;
      case 'creation':
          return <FilesUploader setVisible={setPopupVisible}/>;
      case 'delete':
        return <DeleteProjectModal
            projectId={selectedProjectId}
            setVisible={setPopupVisible}
            setSelectedProjectId={setSelectedProjectId}
        />;
      default:
        return null;
    }
  }

  const dropDownOptions: DropDownOptionType[] = [
    {
      title: t('projects-main.sort-alphabetically'),
      onClick: () => setSort(SortBy.ALPHABET),
      image: sort === SortBy.ALPHABET ?
        <CheckIcon color={theme === 'light' ? themeColors.navyBlue : themeColors.white}/>
        : null,
    },
    {
      title: t('projects-main.sort-by-update-date'),
      onClick: () => setSort(SortBy.DATE),
      image: sort === SortBy.DATE ?
        <CheckIcon color={theme === 'light' ? themeColors.navyBlue : themeColors.white}/>
        : null,
    },
  ]

  const getIconColor = (theme: ThemeType, isActive: boolean) => {
    if (theme === 'light') {
      if (isActive) {
        return themeColors.bluePurple;
      }
      return themeColors.navyBlue;
    }
    return themeColors.white;
  }

  const getIconBackgroundColor = (theme: ThemeType, isActive: boolean) => {
    if (theme === 'light') {
      if (isActive) {
        return themeColors.lightGrey;
      }
      return themeColors.white;
    } else {
      if (isActive) {
        return themeColors.purple1;
      }
      return themeColors.navyBlue;
    }
  }

  return (
    <>
      <Header/>
      <SearchHeader
        leftFields={
          <div className={`search-block ${theme}`}>
            <SearchIcon/>
            <input
              type="text"
              placeholder={t('header.projects-search')}
              autoFocus
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
        }
        rightFields={
          <Button
            onClick={() => setPopupVisible("model")}
            img={<PlusIcon color={theme === 'light' ? themeColors.bluePurple : themeColors.white}/>}
            text={t('header.project-add')}
            colorType="transparent"
          />
        }
      />
      {showPopup()}
      <div className={`models ${theme}`} data-testid="projectsList">
        <p>{t('projects-main.title')}</p>
        <table>
          <thead className="models-header">
          <tr>
            <th className="head-left">
              <DropDown
                title={
                  <span className="models-dropdown-text">
                    {getSortLabel(sort)}
                  </span>
                }
                options={dropDownOptions}
                containerClassName="models-dropdown-content"
                optionColorLight={themeColors.navyBlue}
                optionColorDark={themeColors.white}
                iconColorLight={themeColors.textGrey}
                iconColorDark={themeColors.grey3}
              />
            </th>
            {viewType === ViewType.LINEAR && (
              <>
                <th>
                  <div className="head-center">
                    <span className={`head-text ${theme}`}>{t('projects-main.updated')}</span>
                  </div>
                </th>
                <th>
                  <div className="head-center">
                    <span className={`head-text ${theme}`}>{t('projects-main.simulation-state')}</span>
                  </div>
                </th>
                <th></th>
              </>
            )}
            <th className="head-right">
              <div className="sort-block">
                <span className={`head-text ${theme}`}>{t('projects-main.view-type')}:</span>
                <IconButton
                  icon={ViewGridOutlineIcon}
                  iconColor={getIconColor(theme, viewType === ViewType.GRID)}
                  backgroundColor={getIconBackgroundColor(theme, viewType === ViewType.GRID)}
                  isActive={viewType === ViewType.GRID}
                  onClick={() => setViewType(ViewType.GRID)}
                />
                <IconButton
                  icon={ViewHeadlineIcon}
                  iconColor={getIconColor(theme, viewType === ViewType.LINEAR)}
                  backgroundColor={getIconBackgroundColor(theme, viewType === ViewType.LINEAR)}
                  isActive={viewType === ViewType.LINEAR}
                  onClick={() => setViewType(ViewType.LINEAR)}
                />
              </div>
            </th>
          </tr>
          </thead>
          {viewType === ViewType.LINEAR && (
            <ProjectsTable
              projects={sortProjects()}
              onModelClickHandler={onModelClickHandler}
              setSelectedProjectId={setSelectedProjectId}
              setPopupVisible={setPopupVisible}
            />
          )}
        </table>
        {viewType === ViewType.GRID && (
          <ProjectsGrid
            projects={sortProjects()}
            onModelClickHandler={onModelClickHandler}
          />
        )}
      </div>
    </>
  );
}

export default Projects;

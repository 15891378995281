import {useEffect, useRef, useState} from "react";
import {useMatch} from "react-router-dom";
import {useDispatch} from "react-redux";
import {projectsApi} from "../../api/projectsApi";
import {isErrorResponse} from "../../utils/utils";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId, selectCurrentProjectModes} from "../../redux/selectors/selectors";
import {addedProjectModes, AllowedProjectModes, DEFAULT_ALLOWED_MODES} from "../../redux/reducers/projectsReducer";
import {useToastContext} from "../../context/toastContext";
import {useTranslation} from "react-i18next";
import {ApiError} from "../../api/RestClient";


export const MAPPED_URL_TO_MODE: Record<string, keyof AllowedProjectModes> = {
  'layers': 'layers',
  'algorithms': 'runOptimization',
  'report': 'report',
  'dashboard': 'biDashboard',
  'network': 'networkEditor',
  'transit': 'transitScheduleEditor',
  'maas': 'sharedMobilityEditor',
  'config': 'advancedConfig',
  'runs': 'runs',
  'ev': 'evInfrastructureEditor',
  'scenario': 'scenarioPresetEditor'
}

type ReturnType = {
  loaded: boolean;
  error: ApiError | undefined;
  mode: string;
  isModeAllowed: boolean;
  allowedModes: AllowedProjectModes;
};

export const DEFAULT_MODE = 'layers';

export const useAllowedModes = (): ReturnType => {
  const match = useMatch('/:projectId/:mode/*');
  const mode = match?.params?.mode ? match.params.mode! : DEFAULT_MODE;

  const projectId = useTypedSelector(selectCurrentProjectId);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [error, setError] = useState<ApiError>();
  const [isModeAllowed, setIfModeAllowed] = useState<boolean>(false);
  const allowedModes = useTypedSelector(selectCurrentProjectModes);
  const abortControllerRef = useRef(new AbortController());
  const dispatch = useDispatch();
  const {addToast} = useToastContext();
  const {t} = useTranslation();

  //TODO add loaded flag to redux and load once
  const loadAllowedModes = async (projectId: string, mode: string) => {
    const resp = await projectsApi.getAllowedModes(projectId, abortControllerRef.current.signal);

    if (!isErrorResponse(resp)) {
      const {
        runOptimization,
        report,
        biDashboard,
        sharedMobilityEditor,
        networkEditor,
        transitScheduleEditor,
        advancedConfig,
        runs,
        evInfrastructureEditor,
        scenarioPresetEditor
      } = resp;

      dispatch(addedProjectModes({
        runOptimization,
        report,
        biDashboard,
        sharedMobilityEditor,
        networkEditor,
        transitScheduleEditor,
        advancedConfig: !!advancedConfig,
        runs,
        evInfrastructureEditor,
        scenarioPresetEditor
      }))
    } else {
      setError(resp);
      addToast(t('project.allowed-modes-download-error'), 'error');
    }

    setIfModeAllowed(
        mode === DEFAULT_MODE ||
        mode in MAPPED_URL_TO_MODE && (!isErrorResponse(resp) ? resp[MAPPED_URL_TO_MODE[mode]] : DEFAULT_ALLOWED_MODES[mode])
    );
    setLoaded(true);
  }

  useEffect(() => {
    return () => {
      dispatch(addedProjectModes());
    }
  }, []);

  useEffect(() => {
    if (!loaded && projectId) {
      (async function () {
        await loadAllowedModes(projectId, mode);
      }());
    }
  }, [projectId, mode])

  return {
    loaded,
    error,
    mode,
    allowedModes,
    isModeAllowed
  }
}
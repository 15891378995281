import {BaseEntity} from "./BaseEntity";


export enum SimulationState {
  NEVER = 'NEVER',
  OUTDATED = 'OUTDATED',
  IN_PROGRESS = 'IN_PROGRESS',
  PREPARE_MODEL = 'PREPARE_MODEL',
  COMPLETE = 'COMPLETE',
  RESTARTING = 'RESTARTING',
  TERMINATING = 'TERMINATING',
  CANCELED = 'CANCELED',
  ERROR = 'ERROR',
  SINGLE_ENTITY_BASED = 'SINGLE_ENTITY_BASED',
  MULTIPLE_ENTITY_BASED = 'MULTIPLE_ENTITY_BASED',
  DO_NOT_SHOW = 'DO_NOT_SHOW'
}

export type RunnedAlgorithmEntity = {
  progress: number;
  results: any[];
  startTime: string;
  state: SimulationState;
} & BaseEntity;
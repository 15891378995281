import {EntityServiceName} from "../enums/enums";


export interface MapConfigProps {
  projectId: string;
  mapConfigType: EntityServiceName;
  presetId?: string;
  runId?: string;
  abortSignal: AbortSignal;
}

export interface SearchRoadSegmentsDataType {
  roadInfrastructureSelection: {
    presetId: string,
    numberOfLanes: number,
    highwayType: string,
    feature: string
  }
}

export const enum ScenarioEventsEntityNames {
    PopulationEvent = 'replancity_PopulationEvents',
    NetworkEvent = 'replancity_NetworkEvents',
    TransitEvent = 'replancity_TransitEvents'
}
import React, {useMemo, useRef, useState} from "react";
import {dashboardApi} from "../../../api/dashboardApi";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId, selectProjectsById} from "../../../redux/selectors/selectors";
import {useTranslation} from "react-i18next";
import {ChartData} from "chart.js/dist/types";
import {useLoadChartData} from "../hooks/useLoadChartData";
import {combineProjectsDatasets, formatFloat} from "../../../utils/utils";
import {CarSharingSimulaitonResultsEntity} from "../../../api/entities/replancity_CarSharingSimulaitonResults";
import {Card, CardContent, CardHeader, Grid, Paper, Skeleton} from "@mui/material";
import {useTheme} from "../../../context/themeContext";
import {themeColors} from "../../../styles/theme";


type Props = {
  projectIdToCompare?: string
}

const CarSharingStatisticsChart = ({projectIdToCompare = ''}: Props) => {
  const projectId = useTypedSelector(selectCurrentProjectId);
  const projectsById = useTypedSelector(selectProjectsById);
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  const [forceReload, setForceReload] = useState<any>({}); // object is used to simply update state
  const {t} = useTranslation();
  const {theme} = useTheme();
  const abortControllerRef = useRef(new AbortController());

  const background = theme === 'light' ? themeColors.white : themeColors.navyBlue;
  const paperBackground = theme === 'light' ? themeColors.white : themeColors.grey4;
  const projectIds = useMemo(() => [projectId, ...(projectIdToCompare ? [projectIdToCompare] : [])],
    [projectId, projectIdToCompare]);

  const promises: Promise<ChartData>[] = useMemo(() => {
    return projectIds.map(projectId => dashboardApi.getCarSharingStatistics({
      projectId,
      abortSignal: abortControllerRef.current.signal
    }))
  }, [projectIds]);

  const {loading, data} = useLoadChartData<CarSharingSimulaitonResultsEntity[]>({promiseOrPromises: promises});

  const combinedData: Partial<Record<keyof CarSharingSimulaitonResultsEntity, any[]>> = combineProjectsDatasets<CarSharingSimulaitonResultsEntity>(data || []);

  const {
    averageTripDistance,
    averageTripDuration,
    chargingEvents,
    revenuePerVehicle,
    trips,
    tripsPerCarPerDay
  } = combinedData || {};

  const labelsValues = [
    {label: t('dashboard.car-sharing-statistics.average-trip-distance'), value: averageTripDistance},
    {label: t('dashboard.car-sharing-statistics.average-trip-duration'), value: averageTripDuration},
    {label: t('dashboard.car-sharing-statistics.charging-events'), value: chargingEvents},
    {label: t('dashboard.car-sharing-statistics.revenue-per-vehicle'), value: revenuePerVehicle},
    {label: t('dashboard.car-sharing-statistics.trips'), value: trips},
    {label: t('dashboard.car-sharing-statistics.trips-per-car-per-day'), value: tripsPerCarPerDay},
  ]

  return (
    <Grid item>
      <Card sx={{height: '100%', background}}>
        <CardHeader
          title={
            <Grid container>
              <Grid item xs>
                {t('dashboard.car-sharing-statistics.title')}
              </Grid>
            </Grid>
          }
          sx={{color: theme === 'light' ? themeColors.navyBlue : themeColors.white}}
        />
        <CardContent>
          {
            !data || loading || showSkeleton
              ? <Skeleton variant="rectangular"/>
              : <Grid container spacing={2}>
                {
                  projectsById[projectIds[1]] &&
                    <Grid container item spacing={2}>
                        <Grid container item xs={6}>
                            <span className={`dashboard-statistics-color ${theme}`}></span>
                            <span className={`dashboard-statistics-projectname ${theme}`}>
                            {projectsById[projectIds[0]].projectName}
                          </span>
                        </Grid>
                        <Grid container item xs={6}>
                            <span className={`dashboard-statistics-compared-color ${theme}`}></span>
                            <span className={`dashboard-statistics-projectname ${theme}`}>
                            {projectsById[projectIds?.[1]].projectName}
                          </span>
                        </Grid>
                    </Grid>
                }
                <Grid container item spacing={2}>
                  {
                    labelsValues.map(({label, value}, ind) => (
                      <Grid key={ind} item>
                        <Paper sx={{padding: '10px', background: paperBackground}}>
                          <p style={{color: theme === 'light' ? themeColors.navyBlue : themeColors.grey3}}>{label}</p>
                          {
                            value
                              ? <>
                                <h2
                                  style={{color: theme === 'light' ? themeColors.navyBlue : themeColors.white}}
                                >
                                  {formatFloat(value[0])}
                                </h2>
                                {
                                  value?.[1] &&
                                    <h2 style={{color: themeColors.invalidColor1}}>{formatFloat(value[1])}</h2>
                                }
                              </>
                              : <Skeleton variant="rectangular"/>
                          }
                        </Paper>
                      </Grid>
                    ))
                  }
                </Grid>
              </Grid>
          }
        </CardContent>
      </Card>
    </Grid>
  )
}

export default CarSharingStatisticsChart;
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import EntityForm, {FormDataObject} from "../../../../components/Entities/EntityForm";
import {LoadingComponent} from "../../../../components/LoadingComponent/LoadingComponent";
import {setEntity} from "../../../../redux/entity/entity-reducer";
import {isErrorResponse} from "../../../../utils/utils";
import {useEntityLoader} from "../../../../hooks/entites/useEntityLoader";
import {RoadInfrastructureSegmentIdPairEntity} from "../../../../api/entities/replancity_RoadInfrastructureTollPair";
import {useToastContext} from "../../../../context/toastContext";
import {getFeatureFromProperties} from "../../../../utils/mapUtils";
import {FeatureProperties} from "../../../../redux/map/types";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../../../redux/selectors/selectors";
import {removedFeaturesHighlighting, requireToHighlightFeatures} from "../../../../redux/map/map-reducer";


type Props = {
    entityName: string;
    selectedSegments: RoadInfrastructureSegmentIdPairEntity[];
}

const GroupSelectionSelectedEntityForm = ({entityName, selectedSegments}: Props) => {
    const roadSegmentsLayerId = useTypedSelector((state) => selectMapLayerIdByEntityName(state, entityName));
    const [loading, setLoading] = useState<boolean>(false);
    const {
        loading: isSegmentLoading,
        entity,
        metadata,
        loadData,
        groupSaveFn,
    } = useEntityLoader<any>({entityName});
    const dispatch = useDispatch();
    const {addToast} = useToastContext();
    const {t} = useTranslation();

    useEffect(() => {
        (async function () {
            const firstSegment = selectedSegments[0];

            if (firstSegment) {
                await loadData({entityName, entityId: firstSegment.id});

                const features = selectedSegments.map(({featureStateId}) => getFeatureFromProperties({
                    featureStateId,
                    layerId: roadSegmentsLayerId
                })) as GeoJSON.Feature<any, FeatureProperties>[];

                dispatch(requireToHighlightFeatures(features));
            }
        }());

        return () => {
            dispatch(removedFeaturesHighlighting());
        }
    }, [selectedSegments]);

    const changeFormHandler = useCallback((formDataObj: { [key: string]: string | number }) => {
        dispatch(setEntity({entityName, entity: {...entity, ...formDataObj}}));
    }, [])


    const submitFn = useCallback(async (formDataObj: FormDataObject) => {
        setLoading(true);

        const segmentIds = selectedSegments.map(({id}) => id);
        const resp = await groupSaveFn({
                entityName,
                ids: segmentIds,
                data: formDataObj,
            }
        );

        await loadData({entityName, entityId: segmentIds[0]});

        if (!isErrorResponse(resp)) {
            addToast(`${t('common.save-successful')}`, 'success');
        }

        setLoading(false);

        return resp;
    }, [selectedSegments, entityName])

    return (
        <LoadingComponent isLoading={isSegmentLoading || loading}>
            <EntityForm
                entity={entity}
                metadata={metadata}
                changeFormFn={changeFormHandler}
                saveFn={submitFn}
            />
        </LoadingComponent>
    )
}

export default GroupSelectionSelectedEntityForm;
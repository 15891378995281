import MapboxDraw from '@mapbox/mapbox-gl-draw';
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
import {useControl} from 'react-map-gl';
import {
  SnapPolygonMode,
  SnapPointMode,
  SnapLineMode,
  SnapDirectSelect,
  SnapModeDrawStyles,
} from "mapbox-gl-draw-snap-mode";
import CutLineMode from 'mapbox-gl-draw-cut-line-mode';

import type {MapRef, ControlPosition} from 'react-map-gl';

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;

  onCreate?: (evt: MapboxDraw.DrawCreateEvent) => void;
  onUpdate?: (evt: MapboxDraw.DrawUpdateEvent) => void;
  onDelete?: (evt: MapboxDraw.DrawDeleteEvent) => void;
  onDrawModeChange?: (evt: MapboxDraw.DrawModeChangeEvent) => void;
};

type SnapModeType = {
  options:
      {
        snap: boolean
      }
}

export let drawRef: MapboxDraw & SnapModeType | null = null;

export default function DrawControl(props: DrawControlProps) {
  const modes = {
    modes: {
      ...MapboxDraw.modes,
      draw_point: SnapPointMode,
      draw_polygon: SnapPolygonMode,
      draw_line_string: SnapLineMode,
      direct_select: SnapDirectSelect,
      draw_rectangle: DrawRectangle,
      cut_line: CutLineMode
    },
    styles: SnapModeDrawStyles,
    // userProperties: true,
    snap: false,
    snapOptions: {
      snapPx: 5, // defaults to 15
      snapToMidPoints: true, // defaults to false
    //   // snapVertexPriorityDistance: 0.0025, // defaults to 1.25
    },
    guides: false,
  };

  //TODO this is dangerous to have global variable => find a proper solution how to use drawer instance in other components if possible.
  drawRef = useControl<MapboxDraw>(
    () => new MapboxDraw({
      ...props,
      ...modes,
    }),
    ({map}: { map: MapRef }) => {
      props.onCreate && map.on('draw.create', props.onCreate);
      props.onUpdate && map.on('draw.update', props.onUpdate);
      props.onDelete && map.on('draw.delete', props.onDelete);
      props.onDrawModeChange && map.on('draw.modechange', props.onDrawModeChange);
    },
    ({map}: { map: MapRef }) => {
      props.onCreate && map.off('draw.create', props.onCreate);
      props.onUpdate && map.off('draw.update', props.onUpdate);
      props.onDelete && map.off('draw.delete', props.onDelete);
      props.onDrawModeChange && map.off('draw.modechange', props.onDrawModeChange);
    },
    {
      position: props.position
    }
  );

  return null;
}
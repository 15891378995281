import React, {CSSProperties} from "react";
import {styled} from "@mui/material";
import MuiAccordionSummary, {AccordionSummaryProps} from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, {AccordionProps} from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import './custom-accordion.scss';
import {useTheme} from "../../context/themeContext";
import {themeColors} from "../../styles/theme";


export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
    {...props}
  />
))(({theme}) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  alignItems: 'baseline',
  padding: 0,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    flexDirection: 'column',
    margin: 0,
    marginLeft: theme.spacing(1),
    justifyContent: 'space-between',
  },
}));

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  // border: `1px solid ${theme.palette.divider}`,
  width: '100%',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionDetails-root': {
    padding: 0
  },
}));

export interface CustomAccordionProps {
  expanded?: boolean;
  accordeonOnChange?: (event: React.SyntheticEvent, expanded: boolean) => void;
  summaryContent: JSX.Element;
  summaryIcon?: JSX.Element;
  summaryFooterContent?: JSX.Element;
  detailsContent: JSX.Element;
  setExpandedFn?: (event: React.SyntheticEvent, expanded: boolean) => void;
  summaryCSSProperties?: CSSProperties;
  detailsCSSProperties?: CSSProperties;
}

const CustomAccordion = React.memo(({
                                      expanded,
                                      summaryContent,
                                      summaryIcon,
                                      summaryFooterContent,
                                      detailsContent,
                                      setExpandedFn,
                                      summaryCSSProperties = {},
                                      detailsCSSProperties = {}
                                    }: CustomAccordionProps) => {
  const {theme} = useTheme();

  const bgColor = theme === 'light' ? themeColors.white : themeColors.navyBlue;
  const fontColor = theme === 'light' ? themeColors.navyBlue : themeColors.white;

  return (
    <Accordion
      expanded={expanded}
      onChange={setExpandedFn}
      sx={{
        background: bgColor,
        color: fontColor
      }}
    >
      <AccordionSummary
        sx={{
          ...summaryCSSProperties,
          background: bgColor
        }}
      >
        <div className="accordion-summary-wrapper">
          <div className="accordion-summary-content">
            {summaryContent}
          </div>
          <div className="accordion-summary-icon">
            {summaryIcon}
          </div>
        </div>
        <div className="accordion-summary-footer">
          {summaryFooterContent}
        </div>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          ...detailsCSSProperties,
          background: bgColor,
          color: fontColor
        }}
      >
        {detailsContent}
      </AccordionDetails>
    </Accordion>
  )
})

export default CustomAccordion;
import {IconProps} from "../icons";
import {themeColors} from "../../../styles/theme";


export const ImagePlaceholder = ({
                                   width = 32,
                                   height = 32,
                                   color = themeColors.navyBlue,
                                   backgroundColor = themeColors.grey3
                                 }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.4" y="0.4" width="31.2" height="31.2" rx="1.6" fill={backgroundColor}/>
      <path
        d="M25.3333 4H6.66667C5.19391 4 4 5.19391 4 6.66667V25.3333C4 26.8061 5.19391 28 6.66667 28H25.3333C26.8061 28 28 26.8061 28 25.3333V6.66667C28 5.19391 26.8061 4 25.3333 4Z"
        stroke={color} strokeWidth="0.488189" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M11.334 13.3333C12.4386 13.3333 13.334 12.4378 13.334 11.3333C13.334 10.2287 12.4386 9.33325 11.334 9.33325C10.2294 9.33325 9.33398 10.2287 9.33398 11.3333C9.33398 12.4378 10.2294 13.3333 11.334 13.3333Z"
        stroke={color} strokeWidth="0.488189" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M27.9994 19.9999L21.3327 13.3333L6.66602 27.9999" stroke={color} strokeWidth="0.488189"
            strokeLinecap="round" strokeLinejoin="round"/>
      <rect x="0.4" y="0.4" width="31.2" height="31.2" rx="1.6" stroke={color} strokeWidth="0.8"/>
    </svg>
  )
}

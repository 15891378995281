import React from "react";
import replanCityUnactive from "../../assets/ReplanCityUnactive.svg";
import PlansimGreyLogo from '../../assets/plansim-grey-logo.svg';
import {isReplanApp} from "../../utils/utils";


type Props = {
  active?: boolean;
}

const HeaderLogo = ({active = false}: Props) => {

  const getLogoIconByOrigin = (url: string) => {
    if (isReplanApp(url)) {
      return replanCityUnactive;
    }
    return PlansimGreyLogo;
  }

  return (
    <img
      src={getLogoIconByOrigin(window.location.origin)}
      alt="ico"
    />
  )
}

export default React.memo(HeaderLogo);
import React from "react";
import {Row} from "../../../../components/form/Form/Form";
import * as TextField from "../../../../components/form/TextField/TextField";
import {PublicTransitRouteProfileStopEntity} from "../../../../api/entities/replancity_PublicTransitLine";


type Props = {
    entity: PublicTransitRouteProfileStopEntity;
}

const StopEntityRowForm = ({entity}: Props) => {
    return (
        <Row>
            <TextField.default
                name="name"
                type="text"
                value={entity?.transitStop?.name ?? entity.name ?? entity?.id}
                linkable
            />
        </Row>
    )
}

export default StopEntityRowForm;
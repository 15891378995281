import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.scss";
import App from "./App";
import {Provider} from "react-redux";
import store from "./redux/store";
import './translations/i18n';
// import {ThemeProvider} from "@mui/material";
// import {customizedTheme} from "./styles/theme";
import {AuthProvider} from "./context/authContext";
import {ToastProvider} from "./context/toastContext";
import MyThemeProvider from "./context/themeContext";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";


const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    // <ThemeProvider theme={customizedTheme}> // because own themes are used
    <ToastProvider>
        <AuthProvider>
            <Provider store={store}>
                <MyThemeProvider>
                    <ErrorBoundary>
                        <App/>
                    </ErrorBoundary>
                </MyThemeProvider>
            </Provider>
        </AuthProvider>
    </ToastProvider>,
    // </ThemeProvider>,
);

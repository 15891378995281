import {IconProps} from "../icons";


export const ViewHeadlineIcon = ({width, height, color, backgroundColor}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} fill={backgroundColor}/>
      <path d="M4 5V7H20V5H4ZM4 11H20V9H4V11ZM4 19H20V17H4V19ZM4 15H20V13H4V15Z" fill={color}/>
    </svg>
  )
}
import React, {useCallback} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../../../redux/selectors/selectors";
import EntityEditor from "../../../../components/Entities/EntityEditor";


const RoadEdit = () => {
    const entityName = 'replancity_RoadInfrastructureRoadName';
    const layerToReloadEntityName = 'replancity_RoadInfrastructureSegment';
    const {roadId} = useParams<any>();
    const sourceId = useTypedSelector((state) => selectMapLayerIdByEntityName(state, layerToReloadEntityName));
    const navigate = useNavigate();

    const onSaveFn = useCallback((entity: any) => {
        navigate(roadId ? `../../${entity.id}/edit` : `../${entity.id}/edit`);
    }, [roadId]);

    return (
        <EntityEditor
            entityId={roadId!}
            entityName={entityName}
            layerId={sourceId}
            presetProperty={'roadInfrastructurePreset'}
            onSaveFn={onSaveFn}
        />
    )
}

export default RoadEdit;
import {IconProps} from "../icons";
import {themeColors} from "../../../styles/theme";


export const StopCircleOutlineIcon = ({width = 16, height = 16, color = themeColors.navyBlue}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00004 1.33331C7.12456 1.33331 6.25765 1.50575 5.44882 1.84078C4.63998 2.17581 3.90505 2.66688 3.286 3.28593C2.03575 4.53618 1.33337 6.23187 1.33337 7.99998C1.33337 9.76809 2.03575 11.4638 3.286 12.714C3.90505 13.3331 4.63998 13.8241 5.44882 14.1592C6.25765 14.4942 7.12456 14.6666 8.00004 14.6666C9.76815 14.6666 11.4638 13.9643 12.7141 12.714C13.9643 11.4638 14.6667 9.76809 14.6667 7.99998C14.6667 7.1245 14.4943 6.25759 14.1592 5.44876C13.8242 4.63992 13.3331 3.90499 12.7141 3.28593C12.095 2.66688 11.3601 2.17581 10.5513 1.84078C9.74243 1.50575 8.87552 1.33331 8.00004 1.33331ZM8.00004 2.66665C10.94 2.66665 13.3334 5.05998 13.3334 7.99998C13.3334 10.94 10.94 13.3333 8.00004 13.3333C5.06004 13.3333 2.66671 10.94 2.66671 7.99998C2.66671 5.05998 5.06004 2.66665 8.00004 2.66665Z"
        fill={color}/>
      <path
        d="M8.04602 5.98798C9.15059 5.98798 10.046 6.88341 10.046 7.98798C10.046 9.09255 9.15059 9.98798 8.04602 9.98798C6.94145 9.98798 6.04602 9.09255 6.04602 7.98798C6.04602 6.88341 6.94145 5.98798 8.04602 5.98798Z"
        fill={color}/>
    </svg>
  )
}
import React, {useCallback} from "react";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectDeckGlLayerById, selectTripsSource} from "../../../redux/selectors/selectors";
import {isLayerVisibleOnMap} from "../../../utils/mapUtils";
import './trips-layer-control.scss';
import {useTripsLayerContext} from "../../../context/tripsLayerContext";
import {IconButton} from "../../buttons/IconButton/IconButton";
import {StopCircleOutlineIcon} from "../../icons/icons/StopCircleOutlineIcon";
import {PlayCircleIcon} from "../../icons/icons/PlayCircleIcon";
import {useTheme} from "../../../context/themeContext";
import TripsLayerTimeSlider from "./TripsLayerTimeSlider";
import TripsLayerTrailLengthSlider from "./TripsLayerTrailLengthSlider";


const TripsLayerControl = () => {
    const {isPlaying, setPlay, animationSpeedCoefficient, setAnimationSpeedCoefficient} = useTripsLayerContext();
    const {id} = useTypedSelector(selectTripsSource) ?? {};
    const layer = useTypedSelector(state => selectDeckGlLayerById(state, id!));
    const {theme} = useTheme();

    const isVisible = isLayerVisibleOnMap(layer);

    const changeSpeedHandler = useCallback((event, speed: number) => {
        setAnimationSpeedCoefficient(speed === 3 ? 1 : ++speed);
    }, [])

    return (
        <>
            {
                isVisible && !layer.loading
                    ? <div className={`trip-layer-controls ${theme}`}>
                        <div className="trip-layer-controls__buttons">
                            <IconButton
                                icon={isPlaying ? StopCircleOutlineIcon : PlayCircleIcon}
                                width={20}
                                height={20}
                                onClick={setPlay}
                                borderless
                            />
                            <button
                                className="trip-layer-controls__speed-btn"
                                onClick={(event) => changeSpeedHandler(event, animationSpeedCoefficient)}
                            >
                                {`${animationSpeedCoefficient}x`}
                            </button>
                        </div>
                        <div className="trip-layer-controls__timeline">
                            <TripsLayerTimeSlider/>

                        </div>
                        <div className="trip-layer-controls__tail">
                            <TripsLayerTrailLengthSlider/>
                        </div>
                    </div>
                    : null
            }
        </>
    )
}

export default TripsLayerControl
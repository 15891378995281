import {FormFieldProps} from "../Form/Form";
import React, {ComponentType} from "react";
import {IconButton, IconButtonProps} from "../../buttons/IconButton/IconButton";
import './field-with-button.scss';


const FieldWithButton = <P extends FormFieldProps>(
  Wrapped: ComponentType<P>
) => {
  return ({fieldProps, btnProps}: { fieldProps: P, btnProps: IconButtonProps }) => {
    return (
      <div className="field-with-btn">
        <div className="field-with-btn__field">
          <Wrapped {...fieldProps} />
        </div>
        <div className="field-with-btn__button-block">
          <IconButton
            {...btnProps}
          />
        </div>
      </div>
    )
  };
};

export default FieldWithButton;
import React, {useMemo, useRef} from 'react';
import GaugeChart from 'react-gauge-chart'
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId} from "../../../redux/selectors/selectors";
import {dashboardApi} from "../../../api/dashboardApi";
import {useLoadChartData} from "../hooks/useLoadChartData";
import {ChartData} from "chart.js";
import {Skeleton} from "@mui/material";
import {useTheme} from "../../../context/themeContext";
import {themeColors} from "../../../styles/theme";


const GaugeComponent = () => {
  const projectId = useTypedSelector(selectCurrentProjectId);
  const abortControllerRef = useRef(new AbortController());
  const {theme} = useTheme();

  const promise: Promise<ChartData> = useMemo(() => dashboardApi.getModalSplit({
    projectId,
    modal: 'model',
    abortSignal: abortControllerRef.current.signal
  }), [projectId]);
  //TODO real data isn't used, but hardcoded
  const {loading, data} = useLoadChartData<ChartData>({promiseOrPromises: promise});

  return (
    <div className="gauge-chart-container">
      {
        loading || !data
          ? <Skeleton variant="rectangular"/>
          : <GaugeChart
            nrOfLevels={30}
            colors={["#FF5F6D", "#FFC371"]}
            arcWidth={0.3}
            percent={0.81}
            textColor={theme === 'light' ? themeColors.navyBlue : themeColors.white}
            // height={200}
          />
      }
    </div>
  )
}

export default GaugeComponent;
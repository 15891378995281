import React, {useCallback, useEffect, useMemo} from "react";
import {useOutletContext, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import EntitiesList from "../../../../components/Entities/EntitiesList/EntitiesList";
import StopEntityRowForm from "../Stops/StopEntityRowForm";
import {
    PublicTransitLayerEntityName,
    PublicTransitRouteProfileStopEntity
} from "../../../../api/entities/replancity_PublicTransitLine";
import AddStopToRoute from "./AddStopToRoute";
import {useEntitiesLoader} from "../../../../hooks/entites/useEntitiesLoader";
import './transit-route-stops-list.scss';
import {useEntityLoader} from "../../../../hooks/entites/useEntityLoader";
import {EntityServiceName} from "../../../../api/enums/enums";
import {FeatureProperties} from "../../../../redux/map/types";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../../../redux/selectors/selectors";
import {getFeatureCenterByEntity} from "../../../../utils/mapUtils";
import {TransitRouteContext} from "../../types";
import EntitiesControls from "../../../../components/Entities/EntitiesControls/EntitiesControls";
import {flewToPoint, requireToHighlightFeatures, setZoom} from "../../../../redux/map/map-reducer";


const TransitRouteStopsList = () => {
    const routeProfileStopsEntityName = PublicTransitLayerEntityName.ROUTE_STOPS;
    const stopsEntityName = PublicTransitLayerEntityName.STOPS;
    const serviceName = EntityServiceName.PUBLIC_TRANSIT;
    const stopsLayerId: string = useTypedSelector((state) => selectMapLayerIdByEntityName(state, stopsEntityName));
    const {deleteFn} = useEntityLoader<PublicTransitRouteProfileStopEntity>({entityName: routeProfileStopsEntityName});
    const {routeId} = useParams();
    const {
        loading: stopsLoading,
        entities: routeStops,
        loadData: loadStopsData,
        onRowClick,
        searchPhrase,
        setSearch
    } = useEntitiesLoader<PublicTransitRouteProfileStopEntity>({
        entityName: routeProfileStopsEntityName,
        serviceName: serviceName,
        parentId: routeId
    });
    const {routeProfileId, loadRoute} = useOutletContext<TransitRouteContext>();
    const dispatch = useDispatch();

    const stopFeatures: GeoJSON.Feature<GeoJSON.Geometry, FeatureProperties>[] = useMemo(() => routeStops
        .filter(stop => !!stop?.transitStop?.geometryJson)
        .map(stop => JSON.parse(stop.transitStop.geometryJson)), [routeStops]);

    useEffect(() => {
        if (stopsLayerId && stopFeatures.length) {
            dispatch(requireToHighlightFeatures(stopFeatures));
        }
    }, [stopsLayerId, stopFeatures]);

    //TODO here were clearedSelectedMapFeatures and enableLayersInteractivity called

    const onStopAddOrDelFn = useCallback(async (reloadLayer = false) => {
        await loadStopsData();
        await loadRoute(reloadLayer);
    }, [])

    const deleteRouteStop = useCallback(async ({entity}: { entity: PublicTransitRouteProfileStopEntity }) => {
        await deleteFn({entity});
        await onStopAddOrDelFn(true);
    }, [loadStopsData, loadRoute])

    const rowClickHandler = useCallback(async (entity: PublicTransitRouteProfileStopEntity) => {
        // await onRowClick(entity);

        const stopFeature = JSON.parse(entity?.transitStop?.geometryJson);

        const [lng, lat] = getFeatureCenterByEntity(stopFeature);
        dispatch(flewToPoint({lng, lat}));
        dispatch(requireToHighlightFeatures(stopFeature));

        dispatch(setZoom(15));
    }, [])

    return (
        <>
            <div className="transit-route-stops-controls">
                <EntitiesControls
                    searchPhrase={searchPhrase}
                    onSearch={setSearch}
                    showNewButton={false}
                    controls={
                        <AddStopToRoute
                            routeProfileId={routeProfileId}
                            onStopAdditionFn={onStopAddOrDelFn}
                        />
                    }
                />
            </div>
            <EntitiesList<PublicTransitRouteProfileStopEntity>
                loading={stopsLoading}
                entities={routeStops}
                form={StopEntityRowForm}
                onRowClick={rowClickHandler}
                // getUrlFunc={getUrlFunc}
                deleteFn={deleteRouteStop}
                hasLink={false}
            />
        </>
    )
}

export default TransitRouteStopsList;
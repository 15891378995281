import React from "react";
import EntitiesLoader from "../../../components/Entities/EntitiesLoader/EntitiesLoader";
import {EntityServiceName} from "../../../api/enums/enums";
//TODO change form
import EvEntityRowForm from "../../EvInfrastructure/EvManagement/EvEntityRowForm";
import {ScenarioEventsEntityNames} from "../../../api/types/types";


const getUrlFunc = ({id}: any) => `./${id}/edit`

const NetworkEventsList = () => {
    const serviceName = EntityServiceName.SCENARIO;

    return (
        <EntitiesLoader
            entityName={ScenarioEventsEntityNames.NetworkEvent}
            serviceName={serviceName}
            form={EvEntityRowForm}
            // linkable={true}
            getUrlFunc={getUrlFunc}
        />
    )
}

export default NetworkEventsList;
import React, {useCallback} from "react";
import CustomAccordion from "../CustomAccordion/CustomAccordion";
import Typography from "@mui/material/Typography";
import {useEntityDashboardLoader} from "../../hooks/links/useEntityDashboardsLoader";
import {LoadingBackdrop} from "../LoadingBackdrop/LoadingBackdrop";
import {useTranslation} from "react-i18next";
import {getDashboardByType} from "../../containers/SidePanelDashboard/SidePanelDashboard";


export const LinkInfoDashboards = () => {
  const [expanded, setExpanded] = React.useState<string | false>('0');
  const {data: clickResponseElements, loading} = useEntityDashboardLoader();
  const {t} = useTranslation();

  const handleExpansion = useCallback(
    (dashboardId: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? dashboardId : false);

      (async function () {
        if (newExpanded && dashboardId) {
          setExpanded(dashboardId);
        }
      })();
    }, [setExpanded]);

  return (
    <LoadingBackdrop isVisible={loading} transparent>
      {
        clickResponseElements.length ?
          clickResponseElements.map(({embeddingData, title, id}, ind) => {
            return (
              <CustomAccordion
                key={ind}
                expanded={expanded === ind.toString()}
                summaryContent={<Typography>{title}</Typography>}
                detailsContent={
                  <>
                    {expanded && getDashboardByType(embeddingData)}
                  </>
                }
                setExpandedFn={handleExpansion(ind.toString())}
              />
            )
          })
          : t('map.no-objects-selected')
      }
    </LoadingBackdrop>
  )
}
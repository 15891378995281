import React from "react";
import EntitiesLoader from "../../../components/Entities/EntitiesLoader/EntitiesLoader";
import {EntityServiceName} from "../../../api/enums/enums";
import EvEntityRowForm from "./EvEntityRowForm";
import {CHARGER_PROPERTY_ENTITYNAME} from "../../../api/entities/replancity_ChargerProperty";


const getUrlFunc = ({id}: any) => `./${id}/edit`

const EvChargersList = () => {
    const entityName = CHARGER_PROPERTY_ENTITYNAME;
    const serviceName = EntityServiceName.EV;

    return (
        <EntitiesLoader
            entityName={entityName}
            serviceName={serviceName}
            form={EvEntityRowForm}
            // linkable={true}
            getUrlFunc={getUrlFunc}
        />
    )
}

export default EvChargersList;
import React from "react";
import {useParams} from "react-router-dom";
import EntityListRowForm from "../../../../components/Entities/EntityListRowForm";
import {RoadInfrastructureSegmentEntity} from "../../../../api/entities/replancity_RoadInfrastructureRoadName";
import EntitiesLoader from "../../../../components/Entities/EntitiesLoader/EntitiesLoader";


const getUrlFunc = ({id}: RoadInfrastructureSegmentEntity) => `./${id}/edit`

const RoadSegments = () => {
    const entityName = 'replancity_RoadInfrastructureSegment';
    const serviceName = 'replancity_RoadInfrastructureService'
    const {roadId} = useParams<any>();

    return (
        <EntitiesLoader
            entityName={entityName}
            serviceName={serviceName}
            parentId={roadId}
            form={EntityListRowForm}
            getUrlFunc={getUrlFunc}
        />
    )
}

export default RoadSegments;
import "./group-box.scss";

type GroupBoxType = React.PropsWithChildren<{ header: string }>;

const GroupBox = ({ children, header }: GroupBoxType) => {
  return (
    <fieldset className="groupbox-border">
      <legend className="groupbox-border">{header}</legend>
      {children}
    </fieldset>
  );
};

export default GroupBox;

import React, {ChangeEvent} from "react";
import * as SelectBox from "../SelectBox/SelectBox";
import {Option} from "../SelectBox/SelectBox";
import {PublicTransitVehicleType} from "../../../api/entities/replancity_PublicTransitLine";


type Props = {
    options: Option[]; // MetadataPropertyOptionEntity[];
    label: string;
    name: string;
    value: Pick<PublicTransitVehicleType, 'id' | 'name'>;
    onChange?: (event: ChangeEvent<HTMLElement>) => void;
    alertLabel?: string;
    required?: boolean;
    visible?: boolean;
};

const PublicTransitFareField = React.forwardRef(({
                                                     options,
                                                     label,
                                                     name,
                                                     alertLabel,
                                                     value,
                                                     onChange,
                                                     required = false,
                                                     visible = true
                                                 }: Props, ref: React.ForwardedRef<HTMLSelectElement>) => {
    const fairOptions = options.map(({value, caption}) => ({
        caption,
        value: JSON.stringify({id: value})
    }))

    return (
        <SelectBox.Labeled
            label={label}
            alertLabel={alertLabel}
            name={name}
            options={fairOptions}
            value={value?.id}
            required={required}
            onChange={onChange}
            ref={ref}
            visible={visible}
        />
    )
});

export default PublicTransitFareField;
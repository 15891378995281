import React from 'react';
import './loading-spinner.scss';
import CircularProgress from "@mui/material/CircularProgress";
import {CircularProgressProps} from "@mui/material/CircularProgress/CircularProgress";
import {useTheme} from "../../context/themeContext";
import {themeColors} from "../../styles/theme";


type Props = {
  backgroundColor?: string;
  transparent?: boolean;
} & CircularProgressProps

const LoadingSpinner = ({backgroundColor, transparent = false, ...props}: Props) => {
  const {theme} = useTheme();
  const themeBackground = theme === 'light' ? themeColors.white : themeColors.darkGrey;
  const background = transparent ? `rgba(${backgroundColor ?? themeBackground}, 0.5)` : backgroundColor ?? themeBackground;

  return (
    <div
      className={`loading-spinner ${theme}`}
      style={{background}}
    >
      <CircularProgress color="secondary" {...props} />
    </div>
  )
}

export default LoadingSpinner;
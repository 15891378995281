import {useControl} from "react-map-gl";
import {MapContextValue} from "react-map-gl/dist/esm/components/map";
import {MapboxGLButtonControlClass} from "./MapboxGLButtonControlClass";
import {ControlPosition} from "react-map-gl/src/types";


type Props = {
  title: string;
  eventHandler: (event: any) => void;
  position: ControlPosition;
  className?: string;
  icon?: string;
}

const MapControlButton = ({title, eventHandler, position, className, icon}: Props) => {

  useControl<any>(
    ({map}: MapContextValue) => {
      return new MapboxGLButtonControlClass({
        className,
        title,
        icon,
        eventHandler
      })
    },
    {
      position
    }
  );

  return null;
}

export default MapControlButton;
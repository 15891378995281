import React, {useEffect, useState} from 'react';
import DropDown from "../DropDown/DropDown";
import {DropDownOptionType} from "../DropDown/Option";
import './layers-time-selectors.scss';
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectProjectDateTypes} from "../../redux/selectors/selectors";
import {DataById, DateType} from "../../redux/map/types";
import {useDispatch} from "react-redux";
import {selectedDateType} from "../../redux/map/map-reducer";
import {useTheme} from "../../context/themeContext";
import {themeColors} from "../../styles/theme";
import {CheckIcon} from "../icons/icons/CheckIcon";


const LayersTimeSelectors = () => {
  const [periodMode, setPeriodMode] = useState<string>('');
  const dateTypesByIds = useTypedSelector(selectProjectDateTypes);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {theme} = useTheme();

  useEffect(() => {
    const dateTypesById = Object.entries(dateTypesByIds);
    if (dateTypesById.length) {
      const [id, dateType] = dateTypesById[0];
      setPeriodMode(dateType.name);
      dispatch(selectedDateType(id));
    }
  }, [dateTypesByIds, dispatch])

  const selectPeriod = (id: string, dateType: DateType) => {
    setPeriodMode(dateType.name);
    dispatch(selectedDateType(id));
  }

  const getOptions = (dateTypesByIds: DataById<DateType>): DropDownOptionType[] => {
    return Object.entries(dateTypesByIds).map(([id, dateType]) => ({
      title: dateType.name,
      onClick: () => selectPeriod(id, dateType),
      image: periodMode === dateType.name ?
        <CheckIcon color={theme === 'light' ? themeColors.navyBlue : themeColors.white}/>
        : null,
    }))
  }

  return (
    <div className={`layers-time-selectors-wrapper ${theme}`}>
      <DropDown
        title={
          <>
            <span className="time-period-label">{t('layers-list.period')}:</span>
            <span className="time-period">{periodMode}</span>
          </>
        }
        titleColorLight={themeColors.navyBlue}
        titleColorDark={themeColors.white}
        options={getOptions(dateTypesByIds)}
        iconColorLight={themeColors.navyBlue}
        iconColorDark={themeColors.white}
      />
    </div>
  )
}

export default LayersTimeSelectors;
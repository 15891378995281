import {useMemo} from "react";
import {ArcLayer} from "deck.gl";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {
    selectDeckGlLayersById,
    selectDeckGlLayersDataById,
    selectDeckGlSourcesWithoutTripsById,
} from "../../redux/selectors/selectors";


type DeckGlLayerWrapperProps = {
    id: string;
    visible: boolean;
    features: GeoJSON.FeatureCollection['features'];
}

const inFlowColors = [
    [255, 255, 204, 50],
    [199, 233, 180, 50],
    [127, 205, 187, 50],
    [65, 182, 196, 50],
    [29, 145, 192, 50],
    [34, 94, 168, 50],
    [12, 44, 132, 50]
];

const outFlowColors = [
    [255, 255, 178, 50],
    [254, 217, 118, 50],
    [254, 178, 76, 50],
    [253, 141, 60, 50],
    [252, 78, 42, 50],
    [227, 26, 28, 50],
    [177, 0, 38, 50]
];

const getWidth = d => d.properties.width;

const getSourcePosition = d => d.geometry.coordinates[0];

const getTargetPosition = d => d.geometry.coordinates[1];

export const getArcLayer = ({id, visible, features}: DeckGlLayerWrapperProps) => {
    const colorInd = 4;

    return new ArcLayer({
        id,
        data: features,
        pickable: true,
        getWidth,
        getSourcePosition,
        getTargetPosition,
        getSourceColor: inFlowColors[colorInd] as any, //d => d.properties.color,
        getTargetColor: outFlowColors[colorInd] as any, //d => d.properties.color,
        visible
    })
}

export const useGetArcLayers = (): { arcLayers: ArcLayer[] } => {
    const sourcesById = useTypedSelector(selectDeckGlSourcesWithoutTripsById);
    const dataById = useTypedSelector(selectDeckGlLayersDataById);
    const layersById = useTypedSelector(selectDeckGlLayersById);

    const arcLayers = useMemo(() => Object.keys(sourcesById).map(id => getArcLayer({
            id,
            visible: !!layersById[id].layout.visibility,
            features: dataById[id].features,
        })
    ), [sourcesById, dataById]);

    return {arcLayers};
}
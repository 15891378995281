import {useState} from "react";
import {projectsApi} from "../../api/projectsApi";
import {useDispatch} from "react-redux";
import {isErrorResponse} from "../../utils/utils";
import {ApiError} from "../../api/RestClient";
import {addedProjects} from "../../redux/reducers/projectsReducer";


type ProjectLoaderType = {
  loading: boolean;
  loaded: boolean;
  loadData: () => Promise<void>;
  deleteProject: (id: string) => Promise<any>;
  error: ApiError | undefined;
}

export const useProjectsLoader = (): ProjectLoaderType => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [error, setError] = useState<ApiError>();
  const dispatch = useDispatch();

  async function loadData() {
    setLoading(true);

    try {
      const resp = await projectsApi.getProjects();

      if (isErrorResponse(resp)) {
        setError(resp);
      } else {
        dispatch(addedProjects(resp));
      }
      setLoading(false);
      setLoaded(true);
    } catch (e) {
      console.error('useProjectsLoader.loadData error', e);
      setLoading(false);
      setLoaded(true);
    }
  }

  const deleteProject = async (id: string) => {
    await projectsApi.deleteProject(id);
  }

  return {loading, loaded, loadData, deleteProject, error};
}
import * as React from 'react';


type Props = {
    children: React.ReactElement;

}

class ErrorBoundary extends React.Component<Props, { hasError: boolean; }> {
    constructor(props) {
        super(props);
        // initialize the error state
        this.state = {hasError: false};
    }

    // if an error happened, set the state to true
    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    render() {
        // if error happened, return a fallback component
        if (this.state.hasError) {
            return <>Oh no! Epic fail!</>
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
import React from "react";
import {useTranslation} from "react-i18next";
import {Outlet, useParams} from "react-router-dom";
import IconTabs from "../../../../components/LeftPanelTabs/IconTabs";
import {RoadEditorIcon, RoadSegmentEditorIcon} from "../../../../components/icons/icons";


const Road = () => {
    const {t} = useTranslation();
    const {roadSegmentId, roadId} = useParams<any>();

    //TODO add icons
    const tabs = [
        {
            path: '',
            label: t('road-network.road-segments'),
            url: './',
            icon: <div style={{color: '#fff'}}>S</div>,
            testId: 'roadSegmentsTab'
        },
        {
            path: 'edit',
            label: t('road-network.edit-road'),
            url: './edit',
            icon: <RoadEditorIcon/>,
            testId: 'editRoadTab'
        },

    ];

    if (roadSegmentId) {
        tabs.push({
            path: ":roadSegmentId/edit",
            label: "Segment",
            url: `${roadSegmentId}/edit`,
            icon: <RoadSegmentEditorIcon/>,
            testId: 'editRoadSegmentTab'
        })
    }

    return (
        <>
            <IconTabs tabs={tabs}/>
            <Outlet/>
        </>
    )
}

export default Road;
import React, {useCallback, useEffect} from "react";
import MapboxGL, {
  AttributionControl,
  MapProps,
  NavigationControl,
  ScaleControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {clearedSelectedMapFeatures, selectedMapFeatures} from "../../redux/showInfo/showInfoReducer";
import {selectClickableMapboxSourcesIds} from "../../redux/selectors/selectors";
import {MapLayerMouseEvent} from "react-map-gl/dist/esm/types";
import {getUniqueFeatures} from "../../utils/mapUtils";
import {MapboxGeoJSONFeature} from "mapbox-gl";


type OmittedProps =
  | "width"
  | "height"
  | "style"
  | "mapboxApiAccessToken"
// | "mapStyle"

export type UpdatedMapProps = Omit<MapProps, OmittedProps>;

const staticStyle = {
  width: "100%",
  height: "100%",
  mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  projection: 'mercator',
  doubleClickZoom: false,
  // mapStyle: "mapbox://styles/mapbox/streets-v11?optimize=true", // mapbox://styles/mapbox/light-v11?optimize=true
};

const MapGL = React.forwardRef((props: UpdatedMapProps, ref) => {
  const clickableSourcesIds = useTypedSelector(selectClickableMapboxSourcesIds);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearedSelectedMapFeatures());
    }
  }, []);

  const onClick = useCallback((e: MapLayerMouseEvent) => {
    props.onClick && props.onClick(e);

    const {lngLat, features} = e;
    //TODO draw mode isn't switched on if uniqueFeatures is used
    const uniqueFeatures = getUniqueFeatures(features!);

    dispatch(selectedMapFeatures({
      selectedFeatures: e.features?.map(({id, type, layer, source, properties, geometry}) => ({
        id,
        type,
        source,
        properties,
        geometry
      } as MapboxGeoJSONFeature)),
      lngLat: {...lngLat}
    }));
  }, [props, dispatch]);

  const onError = (event) => {
    console.log('==error', event);
  }

  return (
    <MapboxGL
      // reuseMaps={true}
      ref={ref as any}
      {...props as any}
      interactiveLayerIds={clickableSourcesIds}
      {...staticStyle}
      onLoad={props.onLoad}
      onClick={onClick}
      attributionControl={false}
      boxZoom={false}
      // preventStyleDiffing={true}
    >
      <BaseControls/>
      {props.children}
    </MapboxGL>
  );
});

const BaseControls = React.memo(() => {
  return (
    <>
      <AttributionControl
        position="bottom-left"
        compact
      />
      <ScaleControl unit="metric"/>
      <NavigationControl
        showCompass={true}
        visualizePitch={true}
        position="bottom-right"
      />
    </>
  );
});

export default MapGL;

import {Action} from "redux";
import {AvailableAlgorithmType, RunningAlgorithmType} from "./types";


export const ADD_RUNNING_ALGORITHMS = 'add_running_algorithms';
export const ADD_AVAILABLE_ALGORITHMS = 'add_available_algorithms';

export interface AddRunningAlgorithmsAction extends Action {
  type: 'add_running_algorithms';
  projectId: string;
  runningAlgorithms: RunningAlgorithmType[];
  simulationState: string;
  simulationProgress: number;
}

export interface AddAvailableAlgorithmsAction extends Action {
  type: 'add_available_algorithms';
  projectId: string;
  availableAlgorithms: AvailableAlgorithmType[];
}

export type AlgorithmsActionTypes =
  | AddRunningAlgorithmsAction
  | AddAvailableAlgorithmsAction

export const addRunningAlgorithms = (
  projectId: string,
  runningAlgorithms: RunningAlgorithmType[],
  simulationState: string,
  simulationProgress: number
): AddRunningAlgorithmsAction => ({
  type: ADD_RUNNING_ALGORITHMS,
  projectId,
  runningAlgorithms,
  simulationState,
  simulationProgress
})

export const addAvailableAlgorithms = (projectId: string, availableAlgorithms: AvailableAlgorithmType[]): AddAvailableAlgorithmsAction => ({
  type: ADD_AVAILABLE_ALGORITHMS,
  projectId,
  availableAlgorithms
})
import React, {useEffect} from "react";
import {createPortal} from "react-dom";


type Props = {
    children?: React.ReactElement | null;
    targetNodeId: string;
}

const Portal: React.FC<Props> = ({targetNodeId, children}) => {
    const target = document.getElementById(targetNodeId);
    const el = document.createElement("div");

    useEffect(() => {
        if (target) {
            target.appendChild(el);
        }

        return () => {
            target?.removeChild(el);
        }
    }, [el, target]);

    return createPortal(children, el);
};

export default Portal;
import React, {useCallback, useRef} from "react";
import Tooltip from '@mui/material/Tooltip';
import {useTranslation} from "react-i18next";
import {presetsApi} from "../../../../api/presetsApi/presetsApi";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId} from "../../../../redux/selectors/selectors";
import {IconButton} from "../../../../components/buttons/IconButton/IconButton";
import {RestoreIcon} from "../../../../components/icons/icons/RestoreIcon";
import {themeColors} from "../../../../styles/theme";
import {useTheme} from "../../../../context/themeContext";
import {isErrorResponse} from "../../../../utils/utils";
import {useToastContext} from "../../../../context/toastContext";


type Props = {
    onFinishFn?: () => any;
}

const UndoLastOperationButton = ({onFinishFn}: Props) => {
    const projectId = useTypedSelector(selectCurrentProjectId)
    const abortControllerRef = useRef(new AbortController());
    const {theme} = useTheme();
    const {t} = useTranslation();
    const {addToast} = useToastContext();

    const btnClickHandler = useCallback(async () => {
        const resp = await presetsApi.undoLastOperation({
            projectId,
            abortSignal: abortControllerRef.current.signal
        });

        if (!isErrorResponse(resp)) {
            addToast(`${t('road-network.undo-successful-toast')}`, 'success');
            onFinishFn?.();
        }
    }, [projectId])

    return (
        <Tooltip title={t('road-network.undo-tooltip')} placement="top">
            <IconButton
                icon={RestoreIcon}
                onClick={btnClickHandler}
                iconColor={theme === 'light' ? themeColors.navyBlue : themeColors.white}
                backgroundColor={theme === 'light' ? themeColors.white : themeColors.navyBlue}
                width={24}
                height={24}
            />
        </Tooltip>
    )
}

export default UndoLastOperationButton;
import * as React from "react";
import {Icon, InnerProps} from "../../icons";


const InProcess  = ({width, height, color}: InnerProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 0.0299988V0.0499988V2.05C15.39 2.59 18.5 6.58 17.96 10.97C17.5 14.61 14.64 17.5 11 17.93V19.93C16.5 19.38 20.5 14.5 19.9501 9C19.5 4.25 15.73 0.499999 11 0.0299988ZM9.00005 0.0599988C7.05005 0.249999 5.19005 0.999999 3.67005 2.26L5.10005 3.74C6.22005 2.84 7.57005 2.26 9.00005 2.06V0.0599988ZM2.26005 3.67C1.00005 5.19 0.250049 7.04 0.0500488 9H2.05005C2.24005 7.58 2.80005 6.23 3.69005 5.1L2.26005 3.67ZM0.0600488 11C0.260049 12.96 1.03005 14.81 2.27005 16.33L3.69005 14.9C2.81005 13.77 2.24005 12.42 2.06005 11H0.0600488ZM5.10005 16.37L3.67005 17.74C5.18005 19 7.04005 19.79 9.00005 20V18C7.58005 17.82 6.23005 17.25 5.10005 16.37Z"
                fill={color}/>
        </svg>
    )
}

export const InProcessIcon = Icon(InProcess);
import {IconProps} from "../icons";
import {themeColors} from "../../../styles/theme";


export const LeftArrow = ({width = 16, height = 16, color = themeColors.grey}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.9999 11V13H7.99991L13.4999 18.5L12.0799 19.92L4.15991 12L12.0799 4.07999L13.4999 5.49999L7.99991 11H19.9999Z"
        fill={color}/>
    </svg>
  )
}
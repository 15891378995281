import React from "react";
import ProjectPage from "../../pages/ProjectPages/ProjectPage";
import BiDashboard from "./BiDashboard";


const BiModePage = () => {
  return (
    <ProjectPage
      main={<BiDashboard/>}
    />
  )
}

export default BiModePage;
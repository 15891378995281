import {ViewState} from "react-map-gl/src/types/external";
import {getLocalStorageItem} from "./utils";

export const getStorageMapViewStatesByProjectId = () => getLocalStorageItem('mapViewStateByProject');

export const addProjectStorageMapViewState = (projectId: string, viewState: ViewState) => {
    const storedViewStateByProjects = getStorageMapViewStatesByProjectId()[projectId];

    localStorage.setItem('mapViewStateByProject', JSON.stringify({
        ...getStorageMapViewStatesByProjectId(),
        [projectId]: {
            ...storedViewStateByProjects,
            ...viewState
        }
    }));
}

export const deleteProjectStorageMapViewState = (projectId: string) => {
    const {
        [projectId]: deletedMapViewState,
        ...restMapViewStatesByProject
    } = getStorageMapViewStatesByProjectId();
    localStorage.setItem('mapViewStateByProject', JSON.stringify({
        ...restMapViewStatesByProject,
    }));
}
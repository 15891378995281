import {useEffect, useRef, useState} from "react";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {
    selectCurrentProjectId,
    selectIfSourceReloadRequired,
    selectLayerById,
    selectLayerCalculationState,
    selectSelectedRunId,
} from "../../redux/selectors/selectors";
import mapApi from "../../api/mapApi";
import {isErrorResponse} from "../../utils/utils";
import {setLayerLoading, setSourceReloadRequired} from "../../redux/map/map-reducer";
import {useDispatch} from "react-redux";
import {isLayerVisibleOnMap, layerHasDontShowState} from "../../utils/mapUtils";
import {TripsLayerDataType} from "../../api/entities/replancity_MapLayer";
import {SimulationState} from "../../api/entities/replancity_RunnedAlgorithm";
import {DeckLayerType, MapboxLayerType} from "../../api/enums/enums";


type Props = {
    sourceId: string;
    type: MapboxLayerType | DeckLayerType;
    layerQueryable?: boolean;
};

type LoaderType = {
    sourceData: TripsLayerDataType;
}

export async function loadTripsLayerData({
                                             selectedRunId,
                                             abortSignal
                                         }: {
    selectedRunId?: string,
    abortSignal: AbortSignal
}): Promise<TripsLayerDataType> {
    return await mapApi.fetchTripsLayerData({runId: selectedRunId},
        abortSignal
    );
}

export const useTripsLayerDataLoader = ({sourceId, type, layerQueryable = true}: Props): LoaderType => {
    const projectId = useTypedSelector(selectCurrentProjectId);
    const [sourceData, setSourceData] = useState<any>();
    // const sourceData: TripsLayerDataType = useTypedSelector(state => selectSourceDataById(state, sourceId));
    const loaded = useRef<boolean>(false);
    const layerCalculationState: SimulationState = useTypedSelector(state => selectLayerCalculationState(state, sourceId));
    const isSourceReloadRequired = useTypedSelector(state => selectIfSourceReloadRequired(state, sourceId));
    const layer = useTypedSelector(state => selectLayerById(state, sourceId));
    const selectedRunId = useTypedSelector(selectSelectedRunId);
    const dispatch = useDispatch();

    const layerVisible = isLayerVisibleOnMap(layer);
    const {layerName} = layer ?? {};

    useEffect(() => {
        const abortController = new AbortController();

        if (
            ((layerVisible && !layerHasDontShowState(layerCalculationState) && layerQueryable)
                || isSourceReloadRequired)
            && !loaded.current
        ) {
            (async function () {
                dispatch(setLayerLoading({layerId: sourceId, loading: true}));

                const resp: TripsLayerDataType = await loadTripsLayerData({
                    selectedRunId,
                    abortSignal: abortController.signal
                });


                if (resp) {
                    if (!isErrorResponse(resp)) {
                        setSourceData(resp);
                        // dispatch(updatedSourceData({
                        //     sourceId,
                        //     data: resp,
                        // }));
                        loaded.current = true;
                    }

                    // required to keep spinner to be visible after request cancellation
                    if (!abortController.signal.aborted) {
                        dispatch(setSourceReloadRequired({sourceId, isReloadRequired: false}));
                        dispatch(setLayerLoading({layerId: sourceId, loading: false}));
                    }
                }

            })();
        }

        return () => {
            abortController.abort();
        }
    }, [
        selectedRunId,
        layerVisible,
        layerCalculationState,
        layerName,
        projectId,
        sourceId,
        type,
        dispatch,
        layerQueryable,
        isSourceReloadRequired,
    ])

    return {sourceData};
}
import {ViewPortCoordinatesObsolete, ViewPortCoordinates} from "../api/entities/local/Borders";


export const MAP_LAYER_BORDER_COORDINATES: ViewPortCoordinatesObsolete = {
  bottom: -1000,
  top: 1000,
  left: -1000,
  right: 1000
}

export const DEFAULT_MAP_BOUNDS_COORDINATES: ViewPortCoordinates = {
  minLat: 0,
  maxLat: 0,
  minLon: 0,
  maxLon: 0,
  zoom: 13
}

export const HIGHLIGHTED_TRANSIT_ROUTE_FEATURE_PROPERTIES = {
  featureStateId: 1,
  color: "red",
  width: 4,
  id: "1"
};

export const HIGHLIGHTED_TRANSIT_STOP_FEATURE_PROPERTIES = {
  featureStateId: 1,
  color: "#fff",
  width: 4,
  id: "1"
};
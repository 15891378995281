import restClient, {ApiError, OAuthResponse} from "./RestClient";

export const usersApi = {
  login: function (username: string, password: string): Promise<OAuthResponse | ApiError> {
    return restClient.login(username, password);
  },

  refreshToken: function (): Promise<OAuthResponse | null | ApiError> {
    return restClient.refreshToken();
  },

  getUserInfo: function () {
    return restClient.fetch({
      method: "GET",
      path: `userInfo`,
    });
  },

  logout: function (): Promise<string> {
    return restClient.logout();
  },
};
import React, {InputHTMLAttributes, useEffect, useState} from "react";
import {FieldWithLabel} from "../../../hoc/withClass";
import {FormFieldProps} from "../Form/Form";
import './checkbox-field.scss';
import classNames from "classnames";


interface Props extends Omit<FormFieldProps, 'name' | 'value' | 'onChange'>,
  InputHTMLAttributes<HTMLInputElement> {
  linkable?: boolean;
}

const CheckboxField = ({name, value, linkable = false, onChange, style, ...props}: Props) => {
  const [checkedState, setChecked] = useState<boolean>(!!value && value !== 'false');

  useEffect(() => {
    setChecked(!!value && value !== 'false');
  }, [value]);

  const onChangeHandler = (e) => {
    setChecked(e.target.checked);
    onChange?.(e);
  }

  const className = classNames(
    linkable && 'checkbox-field_link'
  );

  return (
    <div
      className="form-field-md"
      style={style?.minWidth ? {minWidth: style?.minWidth} : {}}
    >
      <input
        type="hidden"
        name={name}
        value="false"
      />
      <input
        type="checkbox"
        name={name}
        className={className}
        value="true"
        checked={checkedState}
        onChange={onChangeHandler}
        {...props}
      />
    </div>
  )
}

const Labeled = FieldWithLabel(CheckboxField);

export {
  Labeled
};

export default CheckboxField;
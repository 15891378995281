import {useCallback, useEffect, useState} from "react";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {
    selectCurrentProjectId,
    selectMapConfigReloadRequired,
    selectSelectedPresetId,
    selectSelectedRunId
} from "../../redux/selectors/selectors";
import {MapConfig} from "../../api/entities";
import mapApi from "../../api/mapApi";
import {LayerTypes, SourceItem} from "../../redux/map/types";
import {
    createClustersCountLayer,
    createClustersLayer,
    createDirectionSymbolLayer,
    createLabelsLayer,
    createLayer,
    getDateTypesByIds,
    toSourceItem
} from "../../utils/mapUtils";
import {
    addedDateTypesByIds,
    addedLayers,
    addedSources,
    clearedMapData,
    setMapConfigReloadRequired
} from "../../redux/map/map-reducer";
import {useDispatch} from "react-redux";
import {EntityServiceName} from "../../api/enums/enums";
import {isErrorResponse} from "../../utils/utils";
import {ApiError} from "../../api/RestClient";
import {DefaultLayerType, useDefaultLayers} from "./useDefaultLayers";


type MapConfigLoader = {
    mapConfig: MapConfig | null;
    loading: boolean;
    error?: ApiError | null;
}

const useMapConfigLoader = (mapConfigType: EntityServiceName, defaultSources?: DefaultLayerType[]): MapConfigLoader => {
    const [mapConfig, setMapConfig] = useState<MapConfig | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<ApiError | null>();
    const projectId = useTypedSelector(selectCurrentProjectId);
    const presetId = useTypedSelector(selectSelectedPresetId);
    const selectedRunId = useTypedSelector(selectSelectedRunId);
    const mapConfigReloadRequired = useTypedSelector<boolean>(selectMapConfigReloadRequired);
    const {defaultLayersArr, addProjectBordersLayerData} = useDefaultLayers(mapConfigType, defaultSources);
    const dispatch = useDispatch();

    const loadMapConfig = useCallback(async ({
                                                 projectId,
                                                 mapConfigType,
                                                 presetId,
                                                 runId,
                                                 abortSignal
                                             }: {
                                                 projectId: string,
                                                 mapConfigType: EntityServiceName,
                                                 presetId: string,
                                                 runId: string,
                                                 abortSignal: AbortSignal
                                             }
    ): Promise<MapConfig | ApiError> => {
        switch (mapConfigType) {
            case EntityServiceName.PUBLIC_TRANSIT:
            case EntityServiceName.ROAD_INFRASTRUCTURE:
            case EntityServiceName.MAAS:
            case EntityServiceName.EV:
            case EntityServiceName.SCENARIO:
                return mapApi.getMapConfigByEntityName({entityName: mapConfigType, presetId, abortSignal});
            default:
                return await mapApi.getMapConfig({
                    projectId,
                    presetId,
                    runId,
                    mapConfigType,
                    abortSignal
                });
        }
    }, [])

    const createLayers = useCallback((mapConfig: MapConfig) => {
        const sources: SourceItem[] = [
            ...defaultLayersArr,
            ...(mapConfig?.entityLayers || []),
            ...(mapConfig?.readOnlyLayers || [])
        ]
            .map(toSourceItem)
            .sort((source1, source2) => source1.order - source2.order);

        const layers: LayerTypes[] = [];
        sources.forEach((source) => {
            layers.push(createLayer(source));

            source.labelFieldName && layers.push(createLabelsLayer(source));
            source.showDirection && layers.push(createDirectionSymbolLayer(source));
            if (source.cluster) {
                layers.push(createClustersLayer(source));
                layers.push(createClustersCountLayer(source));
            }
        })

        dispatch(addedSources(sources));
        dispatch(addedLayers(layers));

        if (mapConfig?.allowedTypes?.length) {
            const dateTypeByIds = getDateTypesByIds(mapConfig?.allowedTypes);
            dispatch(addedDateTypesByIds(dateTypeByIds));
        }

        addProjectBordersLayerData();
    }, [addProjectBordersLayerData, defaultLayersArr, dispatch])

    useEffect(() => {
        setLoading(true);
    }, []);

    useEffect(() => {
        return () => {
            dispatch(clearedMapData());
        }
    }, [dispatch])

    useEffect(() => {
        const abortController = new AbortController();

        // required to clean map for example on run or preset change
        dispatch(clearedMapData());

        (async function () {
            if (projectId) {
                setLoading(true);

                const mapConfig = await loadMapConfig({
                    projectId,
                    mapConfigType,
                    presetId,
                    runId: selectedRunId,
                    abortSignal: abortController.signal
                });

                if (isErrorResponse(mapConfig)) {
                    setError(mapConfig);
                } else {
                    setError(null);
                    setMapConfig(mapConfig);
                    createLayers(mapConfig);
                }
                setLoading(false);

                if (mapConfigReloadRequired) {
                    dispatch(setMapConfigReloadRequired(false));
                }
            }
        })();

        return () => {
            abortController.abort();
        }
    }, [projectId, presetId, selectedRunId, mapConfigType, defaultLayersArr, mapConfigReloadRequired, dispatch, loadMapConfig, createLayers])

    return {mapConfig, loading, error}
}

export default useMapConfigLoader;
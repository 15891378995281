import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {CUT_CONTROL_CLASSNAME} from "../components/map/Controls/MapCutMode";


export type AvailableDrawTypes = 'Point' | 'LineString' | 'Polygon' | 'AllControls';

function clickWrapper(f) {
    return new Proxy(f, {
        apply(target, thisArg, args) {
            console.log('==');

            if (thisArg?.classList.contains('mapboxgl-ctrl-icon_active')) {
                thisArg.style.backgroundColor = '#C8C8C8';
            } else {
                thisArg!.style.backgroundColor = 'initial';
            }

            target.apply(thisArg, args);
        }
    });
}

const click = (event) => {
    console.log('==click');
}

export class DrawControlsClass {
    private static readonly lineControlClass = MapboxDraw.constants.classes.CONTROL_BUTTON_LINE;
    private static readonly polygonControlClass = MapboxDraw.constants.classes.CONTROL_BUTTON_POLYGON;
    private static readonly pointControlClass = MapboxDraw.constants.classes.CONTROL_BUTTON_POINT;
    private static readonly trashControlClass = MapboxDraw.constants.classes.CONTROL_BUTTON_TRASH;
    private static readonly cutControlClass = CUT_CONTROL_CLASSNAME;
    static drawLineElem: HTMLElement;
    static drawPolygonElem: HTMLElement;
    static drawPointElem: HTMLElement;
    static trashElem: HTMLElement;
    // static scissorsElem: HTMLElement;

    static findAndHideDrawControls() {
        const elems = document.querySelectorAll<HTMLElement>('.mapbox-gl-draw_ctrl-draw-btn,.mapbox-gl-scissors');
        for (const elem of elems) {

            // console.log('==1', elem.click);

            // const click = clickWrapper(elem.click);

            // const targetProxy = new Proxy(elem, {
            //     get: (obj, key) => {
            //         const value = Reflect.get(obj, key);
            //         if (typeof (value) == "function") {
            //             return value.bind(obj);
            //         }
            //         return value;
            //     }
            // });
            // elem.addEventListener('click', click);


            // const onClick = elem.click;
            // elem.click = clickWrapper(elem.click);

            // elem.classList.toggle('active');

            if (elem.classList.contains(DrawControlsClass.lineControlClass)) {
                DrawControlsClass.drawLineElem = elem;
            } else if (elem.classList.contains(DrawControlsClass.polygonControlClass)) {
                DrawControlsClass.drawPolygonElem = elem;
            } else if (elem.classList.contains(DrawControlsClass.pointControlClass)) {
                DrawControlsClass.drawPointElem = elem;
            } else if (elem.classList.contains(DrawControlsClass.trashControlClass)) {
                DrawControlsClass.trashElem = elem;
            }
            // else if (elem.classList.contains(DrawControlsClass.cutControlClass)) {
            //     DrawControlsClass.scissorsElem = elem;
            // }

            elem['style'].display = 'none';
        }
    }

    static setDrawControlVisibility({visible, drawType}: {
        visible: boolean,
        drawType?: AvailableDrawTypes
    }) {
        DrawControlsClass.trashElem && (DrawControlsClass.trashElem.style.display = visible ? 'block' : 'none');

        switch (drawType) {
            case "LineString": {
                DrawControlsClass.drawLineElem && (DrawControlsClass.drawLineElem.style.display = visible ? 'block' : 'none');
                // DrawControlsClass.scissorsElem && (DrawControlsClass.scissorsElem.style.display = visible ? 'block' : 'none');
                return;
            }
            case "Polygon": {
                DrawControlsClass.drawPolygonElem && (DrawControlsClass.drawPolygonElem.style.display = visible ? 'block' : 'none');
                // DrawControlsClass.scissorsElem && (DrawControlsClass.scissorsElem.style.display = visible ? 'block' : 'none');
                return;
            }
            case "Point": {
                DrawControlsClass.drawPointElem && (DrawControlsClass.drawPointElem.style.display = visible ? 'block' : 'none');
                return;
            }
            case "AllControls": {
                DrawControlsClass.drawLineElem && (DrawControlsClass.drawLineElem.style.display = visible ? 'block' : 'none');
                DrawControlsClass.drawPolygonElem && (DrawControlsClass.drawPolygonElem.style.display = visible ? 'block' : 'none');
                DrawControlsClass.drawPointElem && (DrawControlsClass.drawPointElem.style.display = visible ? 'block' : 'none');
                // DrawControlsClass.scissorsElem && (DrawControlsClass.scissorsElem.style.display = visible ? 'block' : 'none');
                return;
            }
        }
    }
}
import React, {useCallback, useRef} from "react";
import MapControlButton from "./MapControls/MapControlButton";
import ScissorsIcon from "../../../assets/scissors-icon.svg";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {setCutMode} from "../../../redux/draw/draw-reducer";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectCutMode} from "../../../redux/selectors/selectors";

export const CUT_CONTROL_CLASSNAME = 'mapbox-gl-scissors';

const MapCutMode = () => {
    const cutMode = useTypedSelector(selectCutMode);
    const cutModeRef = useRef<boolean>(cutMode);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    cutModeRef.current = cutMode;

    const clickHandler = useCallback(() => {
        dispatch(setCutMode(!cutModeRef.current));
    }, []);

    return (
        <MapControlButton
            title={t('map.scissors-btn')}
            position="top-right"
            className={CUT_CONTROL_CLASSNAME}
            icon={ScissorsIcon}
            eventHandler={clickHandler}
        />
    )
}

export default MapCutMode;
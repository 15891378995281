import React, {useCallback, useEffect, useState} from "react";
import mapboxgl from "mapbox-gl";
import {Source, Layer, Popup} from "react-map-gl";
import {AnyLayer, LngLat, MapboxMap} from "react-map-gl/src/types";
import {useTranslation} from "react-i18next";
import * as turf from "@turf/turf";
import RulerIcon from "../../../../assets/ruler.svg";
import MapControlButton from "../MapControls/MapControlButton";
import {FeatureProperties} from "../../../../redux/map/types";


type Props = {
    map: MapboxMap;
}

const pointsLayer: AnyLayer = {
    id: 'measure-points',
    type: 'circle',
    source: 'geojson',
    paint: {
        'circle-radius': 5,
        'circle-color': '#fff',
        // "circle-opacity": 0,
        "circle-stroke-width": 1,
        "circle-stroke-color": '#000'
    },
    filter: ['in', '$type', 'Point']
};

const linesLayer: AnyLayer = {
    id: 'measure-lines',
    type: 'line',
    source: 'geojson',
    layout: {
        'line-cap': 'round',
        'line-join': 'round'
    },
    paint: {
        'line-color': '#fff',
        'line-width': 2.5
    },
    filter: ['in', '$type', 'LineString']
};

const linestring: GeoJSON.Feature<GeoJSON.LineString | GeoJSON.Point, Partial<FeatureProperties>> = {
    'type': 'Feature',
    'geometry': {
        'type': 'LineString',
        'coordinates': []
    },
    properties: {}
};

const defaultGeoJsonData: GeoJSON.FeatureCollection<GeoJSON.LineString | GeoJSON.Point, Partial<FeatureProperties>> = {
    'type': 'FeatureCollection',
    'features': []
};

const MemoSource = React.memo(Source);
const MemoLayer = React.memo(Layer);

const RulerControl = ({map}: Props) => {
    const [rulerMode, setRulerMode] = useState<boolean>(false);
    const [distance, setDistance] = useState<string>('');
    const [lngLat, setLngLat] = useState<LngLat | null>(null);
    const [geoJsonData, setGeoJsonData] = useState<GeoJSON.FeatureCollection<GeoJSON.LineString | GeoJSON.Point, Partial<FeatureProperties>>>(defaultGeoJsonData);
    const {t} = useTranslation();

    const onClickListener = useCallback((event: mapboxgl.MapMouseEvent & mapboxgl.EventData,) => {
        setGeoJsonData(data => {
            const features = map.queryRenderedFeatures(event.point, {
                layers: ['measure-points']
            });

            const updatedData = {
                ...data,
                features: [...data.features]
            };

            if (updatedData.features.length > 1) {
                updatedData.features.pop();
            }

            if (features.length) {
                const id = features[0].properties?.id;

                updatedData.features = updatedData.features.filter(
                    (point) => point.properties.id !== id
                );
                //TODO move popup to proper place after some join deletion
                // const selectedFeaturesLength = updatedData.features.length;
                // if (selectedFeaturesLength) {
                //     const {geometry: {coordinates: [lng, lat]}} = updatedData.features[selectedFeaturesLength - 1] as GeoJSON.Feature<GeoJSON.Point>;
                //     setLngLat({lng, lat} as LngLat);
                // }
            } else {
                const {lngLat: {lng, lat}} = event;
                const point: GeoJSON.Feature<GeoJSON.Point, Partial<FeatureProperties>> = {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [lng, lat]
                    },
                    'properties': {
                        'id': String(new Date().getTime())
                    }
                };

                updatedData.features.push(point);
            }

            if (updatedData.features.length >= 1) {
                linestring.geometry.coordinates = updatedData.features.map(
                    (point) => point.geometry.coordinates
                ) as GeoJSON.Position[];

                updatedData.features.push(linestring);

                const distance = turf.length(linestring).toLocaleString();

                setLngLat(event.lngLat);
                setDistance(`${distance} km`);
            }

            return updatedData;
        })
    }, [map]);

    const onMouseMoveListener = useCallback((event: mapboxgl.MapMouseEvent) => {
        const features = map.queryRenderedFeatures(event.point, {
            layers: ['measure-points']
        });

        map.getCanvas().style.cursor = features.length
            ? 'pointer'
            : 'crosshair';
    }, [map]);

    useEffect(() => {
        if (map) {
            if (rulerMode) {
                map.on('click', onClickListener);
                map.on('mousemove', onMouseMoveListener);
            } else {
                map.off('click', onClickListener);
                map.off('mousemove', onMouseMoveListener);
                setLngLat(null);
                setDistance('');
                map.getCanvas().style.cursor = 'pointer';

                if (!rulerMode) {
                    setGeoJsonData(defaultGeoJsonData);
                    return;
                }
            }
        }
    }, [map, rulerMode]);

    const changeModeHandler = () => {
        setRulerMode(mode => !mode);
    }

    const closePopup = () => {
        setRulerMode(false);
    }

    return (
        <>
            <MapControlButton
                title={t('map.ruler-btn')}
                position="top-right"
                icon={RulerIcon}
                eventHandler={changeModeHandler}
            />
            {
                distance && lngLat ?
                    <Popup
                        longitude={lngLat.lng}
                        latitude={lngLat.lat}
                        anchor="bottom"
                        offset={7}
                        closeButton={true}
                        closeOnClick={false}
                        onClose={closePopup}
                    >
                        {distance}
                    </Popup>
                    : null
            }
            <MemoSource
                id="geojson"
                type={'geojson'}
                data={geoJsonData}
            />
            <MemoLayer {...pointsLayer} />
            <MemoLayer {...linesLayer} />
        </>
    )
}

export default RulerControl;
import {useState} from "react";
import './dashboard.scss';
import PieChart from "./charts/PieChart";
import DoubleLineChart from "./charts/DoubleLineChart";
import Chart, {ArcElement} from 'chart.js/auto';
import {Card, CardActions, CardContent, CardHeader, Grid} from '@mui/material';
import GaugeComponent from "./charts/GaugeComponent";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId} from "../../redux/selectors/selectors";
import {Mode} from "./types";
import {useTranslation} from "react-i18next";
import ProjectComparison from "./ProjectComparison";
import DrtStatisticsChart from "./charts/DrtStatisticsChart/DrtStatisticsChart";
import CarSharingStatisticsChart from "./charts/CarSharingStatisticsChart";
import AllowedModes from "./charts/AllowedModes";
import ModesCounter from "./ModesCounter";
import {useTheme} from "../../context/themeContext";
import {themeColors} from "../../styles/theme";


Chart.register(ArcElement);

function Dashboard() {
  const [projectIdToCompare, setProjectIdToCompare] = useState<string>('');
  const currentProjectId: string = useTypedSelector(selectCurrentProjectId);
  const {t} = useTranslation();
  const {theme} = useTheme();

  const background = theme === 'light' ? themeColors.white : themeColors.navyBlue
  const mailMessage = `mailto:jaro@replan.city?subject=Quality improvement claim #${currentProjectId} !&body=Please explain the main purpose of model!`;

  const modes: { label: string; mode: Mode; }[] = [
    {label: t('dashboard.personal-car-label'), mode: 'car'},
    {label: t('dashboard.public-transit-label'), mode: 'pt'},
    {label: t('dashboard.walk-label'), mode: 'walk'},
    {label: t('dashboard.bike-label'), mode: 'bike'},
  ];

  const compareToProjectFn = (projectId: string) => {
    setProjectIdToCompare(projectId);
  }

  return (
    <div className={`dashboard ${theme}`}>
      <Grid container spacing={2} flexDirection='row'>
        <Grid xs={4} container spacing={2} item flexDirection='column'>
          <Grid item>
            <Card sx={{background}}>
              <CardHeader
                title={t('dashboard.project-to-compare')}
                sx={{color: theme === 'light' ? themeColors.navyBlue : themeColors.white}}
              />
              <CardContent>
                <ProjectComparison compareToProjectFn={compareToProjectFn}/>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card sx={{background}}>
              <CardHeader
                title={t('dashboard.modal-split-title')}
                sx={{color: theme === 'light' ? themeColors.navyBlue : themeColors.white}}
              />
              <CardContent>
                <Grid container spacing={2} flexDirection='row'>
                  <Grid xs={6} item>
                    <p
                      style={{color: theme === 'light' ? themeColors.navyBlue : themeColors.white}}>{t('dashboard.model-title')}</p>
                    <PieChart source={"model"} showLegend={true}/>
                  </Grid>
                  <Grid xs={6} item>
                    <p
                      style={{color: theme === 'light' ? themeColors.navyBlue : themeColors.white}}>{t('dashboard.statistics-title')}</p>
                    <PieChart source={"statistics"}/>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <AllowedModes/>
          </Grid>
        </Grid>
        <Grid xs={8} spacing={2} item container flexDirection='column'>
          <Grid item container spacing={2}>
            <Grid xs={6} item>
              <ModesCounter modes={modes}/>
            </Grid>
            <Grid xs={6} item>
              <Card sx={{background}}>
                <CardHeader
                  title={t('dashboard.model-quality-title')}
                  sx={{color: theme === 'light' ? themeColors.navyBlue : themeColors.white}}
                />
                <CardContent>
                  <GaugeComponent/>
                </CardContent>
                <CardActions>
                  <Grid container flexDirection='column'>
                    <p
                      style={{
                        textAlign: 'center',
                        color: theme === 'light' ? themeColors.navyBlue : themeColors.white
                      }}
                    >
                      {t('dashboard.model-quality-msg')}
                    </p>
                    <p style={{textAlign: 'center'}}>
                      <a
                        href={mailMessage}
                        style={{color: themeColors.blueSky}}
                      >
                        {t('dashboard.improve-model-click-msg')}
                      </a>
                    </p>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          <Grid item>
            <DrtStatisticsChart
              projectIdToCompare={projectIdToCompare}
            />
          </Grid>
          <Grid item>
            <CarSharingStatisticsChart
              projectIdToCompare={projectIdToCompare}
            />
          </Grid>
          <Grid item>
            <Card sx={{background}}>
              <CardHeader
                title={t('dashboard.trip-distribution-title')}
                sx={{color: theme === 'light' ? themeColors.navyBlue : themeColors.white}}
              />
              <CardContent>
                <Grid container spacing={2} flexDirection='column'>
                  {
                    modes.map(({label, mode}, ind) => {
                      return (
                        <Grid item key={ind}>
                          <p style={{color: theme === 'light' ? themeColors.navyBlue : themeColors.white}}>{label}</p>
                          <DoubleLineChart
                            mode={mode}
                            projectIdToCompare={projectIdToCompare}
                          />
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Dashboard;

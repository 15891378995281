import React, {useCallback} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../../redux/selectors/selectors";
import EntityEditorWrapper from "../../../components/Entities/EntityEditorWrapper";
import EntityEditor from "../../../components/Entities/EntityEditor";
import {ScenarioEventsEntityNames} from "../../../api/types/types";


const TransitEventEdit = () => {
    const entityName = ScenarioEventsEntityNames.TransitEvent;
    const {transitEventId} = useParams<any>();
    const stopsLayerId = useTypedSelector((state) => selectMapLayerIdByEntityName(state, entityName));
    const navigate = useNavigate();

    const onSaveFn = useCallback((entity: any) => {
        if (!transitEventId) {
            navigate(`../${entity.id}/edit`, {relative: 'path'});
        }
    }, [transitEventId]);

    const onDeleteFn = useCallback((entity: any) => {
        navigate('../');
    }, []);

    return (
        <EntityEditorWrapper entityName={entityName}>
            <EntityEditor
                entityId={transitEventId!}
                entityName={entityName}
                layerId={stopsLayerId}
                presetProperty={'scenarioCasePreset'}
                onSaveFn={onSaveFn}
                onDeleteFn={onDeleteFn}
            />
        </EntityEditorWrapper>
    )
}

export default TransitEventEdit;
import restClient from "./RestClient";
import {
  RoadInfrastructureSegmentIdPairEntity,
} from "./entities/replancity_RoadInfrastructureTollPair";
import {SearchRoadSegmentsDataType} from "./types/types";


export const roadManagementApi = {
  searchRoadSegments: function (
    data: SearchRoadSegmentsDataType,
    abortSignal: AbortSignal
  ):
    Promise<RoadInfrastructureSegmentIdPairEntity[]> {
    return restClient.fetch({
      method: "POST",
      path: `services/replancity_RoadInfrastructureService/searchRoadSegments`,
      data,
      fetchOptions: {signal: abortSignal}
    });
  },

  groupUpdate: function (
    data: any,
    abortSignal: AbortSignal
  ):
    Promise<any[]> {
    return restClient.fetch({
      method: "POST",
      path: `services/replancity_RoadInfrastructureService/groupSaveAndUpdate`,
      data,
      fetchOptions: {signal: abortSignal}
    });
  },

}
import {ADD_AVAILABLE_ALGORITHMS, ADD_RUNNING_ALGORITHMS, AlgorithmsActionTypes} from "./actions";
import {AvailableAlgorithmType, RunningAlgorithmType} from "./types";


export type AlgorithmsStoreState = {
  simulationState: string,
  simulationProgress: number,
  availableAlgorithms: {
    [id: string]: AvailableAlgorithmType[]
  },
  runningAlgorithms: {
    [id: string]: RunningAlgorithmType[]
  }
}

const defaultState: AlgorithmsStoreState = {
  simulationState: '',
  simulationProgress: 0,
  availableAlgorithms: {},
  runningAlgorithms: {}
}


const algorithmsReducer = (state = defaultState, action: AlgorithmsActionTypes): AlgorithmsStoreState => {
  switch (action.type) {
    case ADD_RUNNING_ALGORITHMS: {
      let projRunningAlgorithms: RunningAlgorithmType[] = state.runningAlgorithms[action.projectId];
      projRunningAlgorithms = projRunningAlgorithms ?
        projRunningAlgorithms.concat(action.runningAlgorithms) :
        [...action.runningAlgorithms];

      return {
        ...state,
        simulationState: action.simulationState,
        simulationProgress: action.simulationProgress,
        runningAlgorithms: {...state.runningAlgorithms, [action.projectId]: projRunningAlgorithms}
      }
    }
    case ADD_AVAILABLE_ALGORITHMS: {
      let projAvailableAlgorithms: AvailableAlgorithmType[] = state.availableAlgorithms[action.projectId];
      projAvailableAlgorithms = projAvailableAlgorithms
        ? projAvailableAlgorithms.concat(action.availableAlgorithms) :
        [...action.availableAlgorithms];

      return {
        ...state,
        availableAlgorithms: {...state.availableAlgorithms, [action.projectId]: projAvailableAlgorithms}
      }
    }
    default:
      return state
  }
}

export default algorithmsReducer;
import {IconProps} from "../icons";
import {themeColors} from "../../../styles/theme";


export const CircledEnvelopeIcon = ({width = 56, height = 56, color = themeColors.grey}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" r="28" fill="#6F3BB1" fillOpacity="0.1"/>
      <circle cx="28" cy="28" r="20" fill="#6F3BB1" fillOpacity="0.2"/>
      <path
        d="M41.3334 20C41.3334 18.5333 40.1334 17.3333 38.6667 17.3333H17.3334C15.8667 17.3333 14.6667 18.5333 14.6667 20V36C14.6667 37.4667 15.8667 38.6667 17.3334 38.6667H38.6667C40.1334 38.6667 41.3334 37.4667 41.3334 36V20ZM38.6667 20L28.0001 26.6667L17.3334 20H38.6667ZM38.6667 36H17.3334V22.6667L28.0001 29.3333L38.6667 22.6667V36Z"
        fill="#6F3BB1"/>
    </svg>
  )
}





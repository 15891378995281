import React, {useCallback, useEffect, useState} from "react";
import MapGL from "react-map-gl";
import {Feature} from "@nebula.gl/edit-modes";
import "./map-selection-area.scss";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {projectsApi} from "../../api/projectsApi";
import {useAuth} from "../../context/authContext";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId, selectProjectsById} from "../../redux/selectors/selectors";
import DrawControl from "../map/tools/DrawControl";
import {ViewState, ViewStateChangeEvent} from "react-map-gl/src/types/external";
import {useTheme} from "../../context/themeContext";
import Sources from "../../components/map/Sources";
import MapboxGlLayers from "../../components/map/MapboxGlLayers/MapboxGlLayers";
import {useFastProjectGenerationZones} from "../../hooks/map/useFastProjectGenerationZones";
import {LoadingBackdrop} from "../../components/LoadingBackdrop/LoadingBackdrop";
import {useProjectsLoader} from "../../hooks/projects/useProjectsLoader";
import {Button} from "../../components";


const DEFAULT_VIEW_STATE: ViewState = {
  longitude: 14.3329,
  latitude: 51.7563,
  zoom: 13,
  bearing: 0,
  pitch: 0,
  padding: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }
};

const staticStyle = {
  width: "100%",
  height: "100%",
  mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
};

interface State {
  viewState: ViewState;
}

const MapSelectionArea = () => {
  const {t} = useTranslation();
  const {theme} = useTheme();
  const navigate = useNavigate();
  const {user} = useAuth();
  const projectId = useTypedSelector(selectCurrentProjectId);
  const projectsById = useTypedSelector(selectProjectsById);
  const {loadData: reloadProjects} = useProjectsLoader();
  const [config, setConfig] = useState<State>({
    viewState: DEFAULT_VIEW_STATE
  });
  const {zonesLoading} = useFastProjectGenerationZones();
  const [features, setFeatures] = useState<Feature | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const mapStyle = `mapbox://styles/mapbox/${theme === 'light' ? 'streets-v11' : 'dark-v11'}?optimize=true`;

  useEffect(() => {
    if (projectId && Object.entries(projectsById).length) {
      const {centerCoord} = projectsById[projectId] ?? {};

      if (centerCoord) {
        navigate(`/${projectId}`, {replace: true});
      }
    }
  }, [projectId, projectsById])

  function updateViewState(viewState: ViewStateChangeEvent) {
    setConfig({...config, ...viewState});
  }

  async function onSaveHandler(feature: Feature) {
    try {
      setLoading(true);
      await projectsApi.generateProject(projectId, feature, user);
      await reloadProjects();
      setLoading(false);

      navigate(`/${projectId}`, {replace: true});
    } catch (e) {
      setLoading(false);
    }
  }

  const onUpdate = useCallback(event => {
    const feature = event.features[0];
    setFeatures(feature);
  }, [])

  const onDelete = useCallback(() => {
    setFeatures(null);
  }, [])

  return (
      <LoadingBackdrop
          isVisible={loading || !projectId || !Object.entries(projectsById).length || zonesLoading}
          msg={t('project-selection-area.spinner-msg')}
          transparent
      >
      <MapGL
        {...config.viewState}
        {...staticStyle}
        mapStyle={mapStyle}
        onMove={updateViewState}
      >
        <Sources/>
        <MapboxGlLayers/>
        <DrawControl
          position="top-right"
          displayControlsDefault={false}
          controls={{
            polygon: true,
          }}
          boxSelect={true}
          defaultMode='simple_select'
          onCreate={onUpdate}
          onDelete={onDelete}
        />
        <div className="selection-tips-cntrls-wrapper">
          <div className={`selection-tip ${theme}`}>
            <span>{t('project-selection-area.tip')}</span>
          </div>
          <div className="selection-save-btn-wrapper">
            <Button
                width={200}
                onClick={() => onSaveHandler(features!)}
                text={t('common.save')}
                disabled={!features}
                colorType="dark"
            />
          </div>
        </div>
      </MapGL>
      </LoadingBackdrop>
  );
};

export default MapSelectionArea;

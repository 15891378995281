import {useEffect, useRef} from "react";
import {Position} from "@deck.gl/core";
import {TripsLayer} from "@deck.gl/geo-layers";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {useTripsLayerDataLoader} from "./useTripsLayerDataLoader";
import {animate} from "popmotion";
import {selectDeckGlLayerById, selectTripsSource} from "../../redux/selectors/selectors";
import {DeckLayerType} from "../../api/enums/enums";
import {isLayerVisibleOnMap} from "../../utils/mapUtils";
import {useTripsLayerContext} from "../../context/tripsLayerContext";


type Trip = {
    vendor: number;
    path: Position[];
    timestamps: number[];
    trailLength: number;
    currentTime: number;
};


export const getTripsLayer = ({id, visible, data, trailLength, currentTime}) => {
    return new TripsLayer<Trip>({
        id,
        data,
        getPath: d => d.path,
        getTimestamps: d => d.timestamps,
        getColor: d => (d.vendor === 0 ? [253, 128, 93] : [23, 184, 190]),
        opacity: 0.3,
        widthMinPixels: 2,
        rounded: true,
        trailLength,
        currentTime,
        visible,

        // shadowEnabled: false
    })
}

export const useGetTripsLayer = (): { tripsLayer: TripsLayer } => {
    const loopLength = 87000;
    const animationSpeed = 50;
    const animationRef = useRef<{ stop: () => void; }>();

    const {startTime, time, trailLength, animationSpeedCoefficient, isPlaying, updateTime} = useTripsLayerContext();

    const {id, queryable} = useTypedSelector(selectTripsSource) ?? {};
    const layer = useTypedSelector(state => selectDeckGlLayerById(state, id!));
    const {sourceData} = useTripsLayerDataLoader({
        sourceId: id!,
        type: DeckLayerType.ARC,
        layerQueryable: queryable
    })

    const isLayerVisible = isLayerVisibleOnMap(layer)

    const tripsLayer = getTripsLayer({
        id,
        data: sourceData,
        visible: isLayerVisibleOnMap(layer),
        trailLength,
        currentTime: time
    })

    useEffect(() => {
        if (sourceData && isLayerVisibleOnMap(layer) && isPlaying) {
            animationRef.current = animate({
                from: startTime,
                to: loopLength,
                duration: (loopLength * 60) / (animationSpeed * animationSpeedCoefficient),
                repeat: Infinity,
                onUpdate: updateTime
            });
        }

        return () => {
            animationRef.current?.stop();
        }
    }, [sourceData, isLayerVisible, isPlaying, startTime, loopLength, animationSpeed, animationSpeedCoefficient, updateTime]);

    return {tripsLayer};
}
import React, {CSSProperties} from "react";


type OptionsProps = React.PropsWithChildren<{
  style?: CSSProperties;
}>;

const Options = ({ style, children }: OptionsProps) => {
  const cssVars = {'--options-width': style?.width};
  
  return (
    <div style={{...style, ...cssVars}} className="dropdown-options">
      { children }
    </div>
  );
};
export default Options;

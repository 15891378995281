import React from "react";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {Source} from "react-map-gl";
import {
  selectSourcesWithoutTripsById
} from "../../redux/selectors/selectors";
import {useLayerDataLoader} from "../../hooks/map/useLayerDataLoader";
import {GeoJSONSourceRaw} from "mapbox-gl";
import {MapboxLayerType} from "../../api/enums/enums";
import {DataById, SourceItem} from "../../redux/map/types";


type SourceLayerProps = {
  id: string;
  mapConfigSourceType: MapboxLayerType;
  children?: any;
  layerQueryable?: boolean;
  dayTimeDependent?: boolean;
  zoomDependent?: boolean;
} & GeoJSONSourceRaw;

const SourceWrapper = ({id, mapConfigSourceType, layerQueryable, dayTimeDependent, zoomDependent, ...props}: SourceLayerProps) => {
  const {sourceData} = useLayerDataLoader({sourceId: id!, type: mapConfigSourceType, layerQueryable, dayTimeDependent, zoomDependent});

  // "Sources" are used for deckGl layers (except Trips layers) to load data by viewport, but real mapboxGl Sources aren't created
  return (
    <>
      {sourceData && mapConfigSourceType in MapboxLayerType ?
          <Source
              {...props}
              id={id!}
              data={sourceData}
          />
          : null
      }
    </>
  );
};

const MemoSource = React.memo(SourceWrapper);

const Sources = React.memo(() => {
  const sourcesById = useTypedSelector<DataById<SourceItem>>(selectSourcesWithoutTripsById);

  return (
    <>
      {
        Object.entries(sourcesById).map(([id, {type: mapConfigSourceType, cluster, clusterMaxZoom, clusterRadius, queryable, dayTimeDependent, zoomDependent}]) => (
          <MemoSource
            key={id}
            id={id}
            type={'geojson'}
            mapConfigSourceType={mapConfigSourceType as any}
            cluster={cluster}
            {...(clusterMaxZoom ? {clusterMaxZoom} : {})}
            {...(clusterRadius ? {clusterRadius} : {})}
            layerQueryable={queryable}
            dayTimeDependent={dayTimeDependent}
            zoomDependent={zoomDependent}
          />
        ))
      }
    </>
  );
});

export default Sources;

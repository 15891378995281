import {useCallback} from "react";
import {
    disabledAllLayersInteractivity,
    disabledLayersInteractivityExcept,
    enabledLayersInteractivity,
    setRedirectOnFeatureSelection
} from "../../redux/map/map-reducer";
import {useDispatch} from "react-redux";
import {setDrawnFeatures, setIsDrawMode} from "../../redux/draw/draw-reducer";

type ReturnType = {
    recoverLayersInteractivity: () => void;
    disableLayersInteractivity: () => void;
    disableLayersInteractivityExceptEntityName: (entityName: string) => void;
    toggleRedirectOnFeatureSelection: (enable: boolean) => void;
    setDrawing: (isDraw: boolean) => void;
    saveDrawnFeatures: (features: GeoJSON.Feature<GeoJSON.Geometry>[]) => void;
    cleanDrawnFeatures: () => void;
};

export const useLayersInteractivity = (): ReturnType => {
    const dispatch = useDispatch();

    const recoverLayersInteractivity = useCallback(() => {
        dispatch(enabledLayersInteractivity());
    }, []);

    const disableLayersInteractivity = useCallback(() => {
        dispatch(disabledAllLayersInteractivity());
    }, []);

    const disableLayersInteractivityExceptEntityName = useCallback((entityName: string) => {
        dispatch(disabledLayersInteractivityExcept(entityName));
    }, []);

    const toggleRedirectOnFeatureSelection = useCallback((enable = false) => {
        dispatch(setRedirectOnFeatureSelection(enable));
    }, []);

    //----------------------------------

    const saveDrawnFeatures = useCallback((features: GeoJSON.Feature<GeoJSON.Geometry>[]) => {
        dispatch(setDrawnFeatures(features));
    }, []);

    const cleanDrawnFeatures = useCallback(() => {
        dispatch(setDrawnFeatures());
    }, []);

    const setDrawing = useCallback((isDrawing: boolean) => {
        dispatch(setIsDrawMode(isDrawing));
        if (isDrawing) {
            disableLayersInteractivity();
        } else {
            recoverLayersInteractivity();
            cleanDrawnFeatures();
        }
    }, []);

    return {
        recoverLayersInteractivity,
        disableLayersInteractivity,
        disableLayersInteractivityExceptEntityName,
        toggleRedirectOnFeatureSelection,
        setDrawing,
        saveDrawnFeatures,
        cleanDrawnFeatures
    };
}
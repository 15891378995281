import React from "react";
import {useTranslation} from "react-i18next";
import VerticalTabs from "../../../components/VerticalTabs/VerticalTabs";


const EvManager = () => {
  const {t} = useTranslation();

  const tabs = [
    {
      url: './',
      title: t('ev-infrastructure.chargers'),
      path: undefined,
      testId: 'chargersVerticalTab'
    },
    {
      url: './layers',
      title: t('ev-infrastructure.layers'),
      path: 'layers',
      testId: 'layersVerticalTab'
    },
  ];

    return <VerticalTabs tabs={tabs} />;
}

export default EvManager;
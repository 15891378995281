import restClient from "./RestClient";
import {ProjectEntity} from "./entities";
import {ProjectCreate, ProjectCreated} from "./entities/local/ProjectCreate";
import {Feature} from "@nebula.gl/edit-modes";
import {UploadedFile} from "./entities/replancity_UploadedFile";
import {CompanyEntity} from "./entities/replancity_Company";
import {MetadataPropertyEntity} from "./entities/replancity_MetadataProperty";
import {RunTaskEntity} from "./entities/replancity_RunTask";


export const projectsApi = {
  getProjects: function (): Promise<Array<ProjectEntity>> {
    return restClient.fetch({
      method: "GET",
      path: `projects`
    })
  },

  getProjectConfig: function (projectId: string): Promise<string> {
    return restClient.fetch({
      method: "GET",
      path: `projects/${projectId}/config-file`
    })
  },


  createProject: function (create: ProjectCreate): Promise<ProjectCreated> {
    return restClient.fetch<ProjectCreated>({
      method: "POST",
      path: "projects/create",
      data: create
    });
  },

  generateProject: function (
    projectId: string,
    feature: Feature,
    user: string,
  ): Promise<any> {
    const data = JSON.stringify(feature);
    return restClient.fetch({
      method: "POST",
      path: `projects/${projectId}/generate?user=${user}`,
      data,
      fetchOptions: {
        headers: {
          "Content-Type": "application/json",
        },
      }
    })
  },

  deleteProject: function (projectId: string): Promise<any> {
    return restClient.fetch<ProjectCreated>({
      method: "GET",
      path: `projects/${projectId}/remove_project/`,
    });
  },

  uploadFiles: function (
    projectId: string,
    data: FormData,
  ): Promise<Array<UploadedFile>> {
    return restClient.uploadFiles(`projects/${projectId}/upload`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Content-Encoding": "gzip"
      },
    });
  },

  startSimulation: function (
    data: any
  ): Promise<any> {
    return restClient.fetch({
      method: "POST",
      path: `services/replancity_AlgorithmRunService/runTask`,
      data
    });
  },

  interruptSimulation: function (
    data: {runId: string;}
  ): Promise<any> {
    return restClient.fetch({
      method: "POST",
      path: `services/replancity_AlgorithmRunService/interruptSimulation`,
      data
    });
  },

  restartSimulation: function (
    data: {runId: string;}
  ): Promise<any> {
    return restClient.fetch({
      method: "POST",
      path: `services/replancity_AlgorithmRunService/restartSimulation`,
      data
    });
  },

  getSimulationLogs: function (
    data: {runId: string;}
  ): Promise<any> {
    return restClient.fetch({
      method: "POST",
      path: `services/replancity_AlgorithmRunService/getLogsById`,
      data
    });
  },

  getAllowedModes: function (
    projectId: string,
    abortSignal: AbortSignal
  ): Promise<CompanyEntity> {
    return restClient.fetch({
      method: "GET",
      path: `services/replancity_UserAndCompanyManagementService/allowedModes?projectId=${projectId}`,
      fetchOptions: {signal: abortSignal}
    });
  },

  recalculateDrtStatistics: function (
    projectId: string,
  ): Promise<string> {
    return restClient.fetch({
      method: "GET",
      path: `/projects/${projectId}/update_state_and_progress?state=COMPLETE&progress=100`,
    });
  },

  getFastGenerationZones: function (): Promise<GeoJSON.FeatureCollection> {
    return restClient.fetch({
      method: "GET",
      path: `/services/replancity_ProjectApiService/getFastGenerationZones`,
    });
  },

  fetchEntityMetadata: function <T extends Record<string, unknown> = any>({entityName, presetId, abortSignal}: {
    entityName: string,
    presetId?: string,
    abortSignal: AbortSignal
  }): Promise<MetadataPropertyEntity<T>[]> {
    return restClient.fetch({
      method: "GET",
      path: `projects/metadata/${entityName}`,
      data: {
        ...(presetId ? {presetId} : {})
      },
      fetchOptions: {signal: abortSignal}
    });
  },

  getRuns: function <T extends Record<string, unknown> = any>({projectId, abortSignal}: {
    projectId: string,
    abortSignal: AbortSignal
  }): Promise<RunTaskEntity[]> {
    return restClient.fetch({
      method: "GET",
      path: `services/replancity_AlgorithmRunService/listCompletedRuns?projectId=${projectId}`,
      fetchOptions: {signal: abortSignal}
    });
  },
};

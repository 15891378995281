import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';


const TripsLayerContext = createContext({
    time: 0,
    startTime: 0,
    animationSpeedCoefficient: 1,
    trailLength: 50,
    isPlaying: false,
    updateTimeBySliderClick: (event: Event, newValue: number | number[]) => {
        return;//
    },
    updateTime: (milliSeconds: number) => {
        return;
    },
    setPlay: () => {
        return;
    },
    setAnimationSpeedCoefficient: (coef: number) => {
        return;
    },
    updateTrailLength: (event: Event, length: number | number[]) => {
        return;
    },
});

const useTripsLayerContext = () => {
    return useContext(TripsLayerContext);
};

const TripsLayerContextProvider = ({children}) => {
    const [time, setTime] = useState(0);
    const [trailLength, setTrailLength] = useState(50);
    const [animationSpeedCoefficient, setAnimationSpeedCoefficient] = useState(1);
    const [startTime, setStartTime] = useState(0);
    const [isPlaying, setPlaying] = useState<boolean>(false);

    useEffect(() => {
        if (time > startTime) {
            setStartTime(time);
        }
    }, [isPlaying, animationSpeedCoefficient])

    const updateTimeBySliderClick = useCallback((event: Event, newValue: number | number[]) => {
        if (newValue) {
            setStartTime(newValue as number);
        }
    }, [])

    const updateTime = useCallback((milliseconds: number) => {
        setTime(milliseconds);
    }, [setTime]);

    const updateTrailLength = useCallback((event: Event, length: number | number[]) => {
        if (length) {
            setTrailLength(length as number);
        }
    }, [setTime]);

    const setPlay = useCallback(() => {
        setPlaying(prev => !prev);
    }, [setPlaying])

    return (
        <TripsLayerContext.Provider value={{
            startTime,
            time,
            animationSpeedCoefficient,
            trailLength,
            updateTimeBySliderClick,
            updateTrailLength,
            updateTime,
            isPlaying,
            setPlay,
            setAnimationSpeedCoefficient
        }}>
            {children}
        </TripsLayerContext.Provider>
    );
};

export {TripsLayerContextProvider, useTripsLayerContext};
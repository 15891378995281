import React from "react";
import {Row} from "../form/Form/Form";
import * as TextField from "../form/TextField/TextField";
import * as ColorPicker from "../form/ColorPickerField/ColorPickerField";
import {BaseEntity} from "../../api/entities/BaseEntity";


const EntityListRowForm = <T extends BaseEntity & { color?: string }>({entity: {id, name, color}}: { entity: T }) => {
    return (
        <Row>
            {
                color
                    ? <div style={{minWidth: '32px', flexGrow: 0}}>
                        <ColorPicker.default
                            name="color"
                            value={color}
                            linkable
                        />
                    </div>
                    : null
            }
            <TextField.default
                name="name"
                type="text"
                value={name ?? id}
                style={{minWidth: '80px'}}
                linkable
            />
        </Row>
    );
}

export default EntityListRowForm;
import React, {useCallback, useEffect} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {
    PublicTransitLayerEntityName,
    PublicTransitRouteEntity,
    PublicTransitScheduleElementEntity
} from "../../../../api/entities/replancity_PublicTransitLine";
import EntityEditor from "../../../../components/Entities/EntityEditor";
import {removeEntity} from "../../../../redux/entity/entity-reducer";
import {useDispatch} from "react-redux";
import {TransitRouteContext} from "../../types";


const DepartureEdit = () => {
    const entityName = PublicTransitLayerEntityName.DEPARTURE;
    const {departureId} = useParams<any>();
    const {scheduleId} = useOutletContext<TransitRouteContext>();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(removeEntity(entityName));
        }
    }, [])

    const onSaveFn = useCallback((entity: PublicTransitRouteEntity) => {
        if (!departureId) {
            //TODO update routes, because "departures/new" is a single step and '../' not navigates to "departures" as expected
            navigate(`../departures/${entity.id}/edit`);
        }
    }, [departureId]);

    const onDeleteFn = useCallback((entity: PublicTransitScheduleElementEntity) => {
        navigate('../departures');
    }, []);

    const saveArgsFn = useCallback((entity: PublicTransitScheduleElementEntity) => ({
        publicTransitSchedule: {id: scheduleId}
    }), [scheduleId]);

    return (
        <EntityEditor<PublicTransitScheduleElementEntity>
            entityId={departureId!}
            entityName={entityName}
            layerId={''} // make optional?
            saveFnArgs={saveArgsFn}
            onSaveFn={onSaveFn}
            onDeleteFn={onDeleteFn}
        />
    )
}

export default DepartureEdit;
import {combineReducers} from "redux";
import mapReducer from "../map/map-reducer";
import projectsReducer from "./projectsReducer";
import showInfoReducer from "../showInfo/showInfoReducer";
import TimeLine from "./TimeLine";
import algorithmsReducer from "../algorithms/algorithms-reducer";
import drawReducer from "../draw/draw-reducer";
import entityReducer from "../entity/entity-reducer";


export const rootReducer = combineReducers({
  map: mapReducer,
  draw: drawReducer,
  projects: projectsReducer,
  showInfo: showInfoReducer,
  entity: entityReducer,
  TimeLine,
  algorithms: algorithmsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

import React, {useCallback} from "react";
import ProjectPage from "../../pages/ProjectPages/ProjectPage";
import ProjectPresetsSelector from "../../components/ProjectPresetsSelector/ProjectPresetsSelector";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectPresetType, selectSelectedPresetId} from "../../redux/selectors/selectors";
import MapContainerWithSources from "../../containers/map/MapContainer/MapContainerWithSources";
import {EntityServiceName} from "../../api/enums/enums";
import {useNavigationOnFeaturesSelection} from "../../hooks/transitlLines/useNavigationOnFeaturesSelection";
import {GeoJsonGeometryTypes} from "geojson";
import ScenarioEventsManager from "./Manager/ScenarioEventsManager";
import {ScenarioEventsEntityNames} from "../../api/types/types";


const ScenarioPresetsModePage = () => {
    const presetEntityName = 'replancity_ScenarioCasePreset';
    const presetId = useTypedSelector(selectSelectedPresetId);
    const currentPresetType = useTypedSelector(selectPresetType);
    const {t} = useTranslation();

    const getUrlFunc = useCallback(({childId, entityName}: {
        parentId?: string;
        childId: string;
        featureType: GeoJsonGeometryTypes,
        entityName: string;
    }) => {
        switch (entityName) {
            case ScenarioEventsEntityNames.PopulationEvent: {
                return `./${childId}/edit`;
            }
            case ScenarioEventsEntityNames.NetworkEvent: {
                return `./network/${childId}/edit`;
            }
            case ScenarioEventsEntityNames.TransitEvent: {
                return `./transit/${childId}/edit`;
            }
            default:
                return `./${childId}/edit`;
        }
    }, []);

    const {navigateOnFeatureSelection} = useNavigationOnFeaturesSelection({getUrlFunc});

    return (
        <ProjectPage
            showSkeleton={!presetId || presetEntityName !== currentPresetType}
            subHeaderLeft={
                <ProjectPresetsSelector
                    presetEntityName={presetEntityName}
                    serviceName={EntityServiceName.SCENARIO}
                />
            }
            main={
                <MapContainerWithSources
                    mapConfigType={EntityServiceName.SCENARIO}
                    isDrawerEnabled={true}
                    editableFeatureTypes={{Polygon: true}}
                    handleMapClick={navigateOnFeatureSelection}
                />
            }
            leftPanel={<ScenarioEventsManager/>}
            leftPanelTitle={t('ev-infrastructure.ev-infrastructure-editor')}
        />
    )
}

export default ScenarioPresetsModePage;
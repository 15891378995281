import React from "react";
import {useTranslation} from "react-i18next";
import {AuthPagesWrapper} from "../../components/AuthPagesWrapper/AuthPagesWrapper";
import {CircledCheckIcon} from "../../components/icons/icons/CircledCheckIcon";
import {Divider} from "@mui/material";
import './password-reset-finished-page.scss';
import BackToSignInButton from "../../components/BackToSignInButton/BackToSignInButton";


const PasswordResetFinishedPage = () => {
  const {t} = useTranslation();

  return (
    <AuthPagesWrapper showLogoColumn={false}>
      <div className="password-reset-finished-page__row password-reset-finished-page__icon">
        <CircledCheckIcon/>
      </div>
      <h1 className="password-reset-finished-page__row password-reset-finished-page__title">
        {t('password-reset-finished-page.title')}
      </h1>
      <p className="password-reset-finished-page__row password-reset-finished-page__description">
        {t('password-reset-finished-page.description')}
      </p>
      <Divider/>
      <div className="password-reset-finished-page__row password-reset-finished-page__buttons">
        <BackToSignInButton title={t('reset-password-form.back-to-login-link')} />
      </div>
    </AuthPagesWrapper>
  );
}

export default PasswordResetFinishedPage;
import {PresetEntityName} from "../../hooks/projects/usePresetsLoader";
import {PublicTransitPresetEntity} from "../entities/replancity_PublicTransitPreset";
import restClient, {ApiError} from "../RestClient";
import {
    CopyPresetFnArgs,
    TraceRoutesToNetworkArgsType
} from "./types";


export const presetsApi = {
    getPresets: function ({projectId, presetEntityName, abortSignal}: {
                              projectId: string,
                              presetEntityName: PresetEntityName,
                              abortSignal: AbortSignal,
                          }
    ): Promise<PublicTransitPresetEntity[]> {
        return restClient.fetch({
            method: "GET",
            path: `queries/${presetEntityName}/preset?projectId=${projectId}`,
            fetchOptions: {
                signal: abortSignal,
                params: {view: '_local'}
            },
        });
    },

    undoLastOperation: function ({projectId, abortSignal}: {
                                     projectId: string,
                                     abortSignal: AbortSignal,
                                 }
    ): Promise<PublicTransitPresetEntity[]> {
        return restClient.fetch({
            method: "GET",
            path: `services/replancity_RoadInfrastructureService/undoLastOperationNoPreset?projectId=${projectId}`,
            fetchOptions: {signal: abortSignal}
        });
    },

    copyPreset: function (args: CopyPresetFnArgs): Promise<PublicTransitPresetEntity | ApiError> {
        const {serviceName, data, abortSignal} = args;

        return restClient.fetch({
            method: "GET",
            path: `services/${serviceName}/copyPreset`,
            data,
            fetchOptions: {signal: abortSignal}
        });
    },

    traceRoutesToNetwork: function (args: TraceRoutesToNetworkArgsType): Promise<any | ApiError> {
        const {serviceName, data, abortSignal} = args;

        return restClient.fetch({
            method: "GET",
            path: `services/${serviceName}/traceRoutesToNetwork`,
            data,
            fetchOptions: {signal: abortSignal}
        });
    },

}
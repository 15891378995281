import {createTheme} from "@mui/material";


export const customizedTheme = createTheme({
  palette: {
    // primary: {
    //   main: '#800080FF',
    // }
  }
});

export const themeColors = {
  navyBlue: '#2f374c',
  textLink: '#2c39b5',
  purple1: '#4C39AA',
  purple2: '#8A75F5',
  greyPurple: '#473065',
  bluePurple: '#6f3bb1',
  lightPurple: '#a06be5',
  purple: 'purple',

  darkGrey: '#20232C',
  grey1: '#EDF0FF',
  grey2: '#4C5570',
  grey3: '#8893B1',
  grey4: '#3C4356',
  textGrey: '#8f8f8f',
  grey: '#d7d9e3',
  stroke: '#C8C8C8',
  lightGrey: '#f0f2f6',
  white: '#f8f8f8',

  blueSky: '#289ddf',
  statusCompleted: '#58BBAF',
  statusInProgress: '#FF9E58',
  red: 'red',

  validColor: '#3A6754',
  validColorBg: '#e4f8ef',
  invalidColor: '#863737',
  invalidColor1: '#D06363',
  invalidColorBg: '#faecec',
}
import {useLoadChartData} from "../hooks/useLoadChartData";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId} from "../../../redux/selectors/selectors";
import {dashboardApi, ModeCountResponse} from "../../../api/dashboardApi";
import React, {useMemo, useRef} from "react";
import {Mode} from "../types";
import {Paper, Skeleton} from "@mui/material";
import {useTheme} from "../../../context/themeContext";
import {themeColors} from "../../../styles/theme";


type Props = {
  mode: Mode;
}

const ModeCount = ({mode}: Props) => {
  const projectId = useTypedSelector(selectCurrentProjectId);
  const abortControllerRef = useRef(new AbortController());
  const {theme} = useTheme();

  const background = theme === 'light' ? themeColors.white : themeColors.grey4

  const promise: Promise<ModeCountResponse> = useMemo(() => dashboardApi.getModeCount({
    projectId,
    mode,
    abortSignal: abortControllerRef.current.signal
  }), [projectId, mode]);
  const {loading, data} = useLoadChartData<ModeCountResponse>({promiseOrPromises: promise});

  return (
    <div className="mode-count-container">
      {
        (
          <Paper sx={{padding: '20px', background}}>
            <p style={{color: theme === 'light' ? themeColors.navyBlue : themeColors.grey3}}>{mode}, model</p>
            {
              data && !loading
                ? <h2 style={{color: theme === 'light' ? themeColors.navyBlue : themeColors.white}}>{data?.count}</h2>
                : <Skeleton variant="rectangular"/>
            }
          </Paper>
        )
      }
    </div>
  )
}

export default ModeCount;
import React, {useState} from "react";
import {FieldWithLabel} from "../../../hoc/withClass";
import {Slider} from "@mui/material";
import './slider-field.scss';
import {useTheme} from "../../../context/themeContext";
import {styled} from "@mui/material/styles";
import {themeColors} from "../../../styles/theme";


export const StyledSlider = styled((props: any) => (
    <Slider {...props} />
))(() => {
    const {theme} = useTheme();
    const color = theme === 'light' ? themeColors.navyBlue : themeColors.white;

    return {
        '& .MuiSlider-markLabel': {
            color
        }
    }
});

const SliderField = ({label, name, min, max, step, defaultValue, required}) => {
    const [valueState, setValueState] = useState(defaultValue);

    const marks: { value: number; label: string; }[] = [];
    for (let i = min; i < max; i += step) {
        marks.push({
            value: i,
            label: `${parseFloat(i).toFixed(1)}`,
        });
    }

    const changeHandler = (event: Event, newValue: number | number[]) => {
        setValueState(newValue as number);
    };

    return (
        <div className="slider-field">
            <StyledSlider
                aria-label="name"
                value={valueState}
                valueLabelDisplay="auto"
                marks={marks}
                step={step}
                min={min}
                max={max}
                onChange={changeHandler}
            />
            <input
                name={name}
                type="number"
                value={valueState}
                onChange={() => {
                    return;
                }}
                hidden
            />
        </div>
    )
}

const Labeled = FieldWithLabel(SliderField);

export {Labeled, SliderField}

export default SliderField;
import React from "react";
import {Outlet} from "react-router-dom";
import BreadcrumbsByRoutes from "../BreadcrumbsByRoutes/BreadcrumbsByRoutes";


const BreadcrumbedComponent = () => {
    return (
        <>
            <BreadcrumbsByRoutes/>
            <Outlet/>
        </>
    );
}

export default BreadcrumbedComponent;
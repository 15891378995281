import {useCallback, useEffect, useState} from "react";
import {dashboardApi} from "../../api/dashboardApi";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId, selectSelectedFeatures, selectSelectedRunId} from "../../redux/selectors/selectors";
import {isErrorResponse} from "../../utils/utils";
import {ClickResponseElement} from "../../api/entities/replancity_ClickResponse";


export const useEntityDashboardLoader = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ClickResponseElement[]>([]);
  const projectId = useTypedSelector(selectCurrentProjectId);
  const selectedRunId = useTypedSelector(selectSelectedRunId);
  const selectedFeatures = useTypedSelector(selectSelectedFeatures);

  const loadLinkData = useCallback(async ({elements, projectId, selectedRunId}: {
    elements: {
      entityName: string;
      featureId: string;
    }[];
    projectId: string;
    selectedRunId: string;
  }) => {
    setLoading(true);

    const resp = await dashboardApi.getClickedLinkDashboards({
      clickRequest: {
        projectId,
        runId: selectedRunId,
        elements
      }
    });

    if (!isErrorResponse(resp)) {
      const {elements} = resp;
      setData(elements);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (selectedFeatures?.length) {
      const elements: { entityName: string; featureId: string; }[] = selectedFeatures?.map(({properties}) => {
        const {entityName, id} = properties ?? {};
        return {entityName, featureId: id};
      });

      (async function () {
        await loadLinkData({elements, projectId, selectedRunId});
      })();
    } else {
      setData([]);
    }
  }, [selectedFeatures, projectId, selectedRunId])

  return {data, loading};
}
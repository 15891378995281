import React, {memo, useCallback} from "react";
import './edit-geometry-toolbar.scss';
import {useTheme} from "../../../context/themeContext";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectDrawMode,} from "../../../redux/selectors/selectors";
import Button from "../../buttons/Button";
import {DrawControlsClass} from "../../../utils/DrawControlsClass";
import {useLayersInteractivity} from "../../../hooks/map/useLayersInteractivity";
import {useToastContext} from "../../../context/toastContext";


type Props = {
    children?: React.ReactElement | null;
    drawnFeatures: GeoJSON.Feature<GeoJSON.Geometry>[] | undefined;
    onApplyFn?: () => void;
    onDiscardFn?: () => void;
};

const EditGeometryToolbar: React.FC<Props> = ({children, drawnFeatures, onApplyFn, onDiscardFn}) => {
    const drawMode = useTypedSelector(selectDrawMode);
    const {saveDrawnFeatures, cleanDrawnFeatures} = useLayersInteractivity();
    const {theme} = useTheme();
    const {t} = useTranslation();
    const {addToast} = useToastContext();

    const modeString = `Edit mode: ${drawMode}`;

    const applyChangesClickHandler = useCallback(async () => {
        if (drawnFeatures?.length) {
            saveDrawnFeatures(drawnFeatures);
        }
        DrawControlsClass.setDrawControlVisibility({drawType: 'AllControls', visible: false});
        addToast(t('forms.geometry-updated-toast'), 'success');

        onApplyFn?.();
    }, [drawnFeatures]);

    const discardGeometryChanges = useCallback(() => {
        cleanDrawnFeatures();
        onDiscardFn?.();
    }, []);

    return (
        <div className={`edit-geometry-toolbar ${theme}`}>
            {children}
            {modeString}
            <Button
                text={t('map.apply-changes-btn')}
                onClick={applyChangesClickHandler}
                // disabled={!drawnFeatures?.length}
                colorType="transparent"
                testId="applyGeometryUpdateBtn"
            />
            <Button
                text={t('map.discard-geometry-change')}
                onClick={discardGeometryChanges}
                // disabled={!drawnFeatures?.length}
                colorType="transparent"
                testId="cancelGeometryUpdateBtn"
            />
        </div>
    )
}

export default memo(EditGeometryToolbar);
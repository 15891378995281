import React, {useEffect, useMemo, useRef} from "react";
import {DataById, LayerTypes} from "../../../../redux/map/types";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {useDispatch} from "react-redux";
import {changedLayerVisibility} from "../../../../redux/map/map-reducer";
import "./layers-list.scss";
import {useTranslation} from "react-i18next";
import {
    selectCalculationState,
    selectLayersWithSameSourceById,
    selectAllLayersById,
    selectAllLayersIds,
    selectSourcesById,
} from "../../../../redux/selectors/selectors";
import LoadingSpinner from "../../../../components/Spinner/LoadingSpinner";
import {
    isLayerVisibleOnMap,
    isLayerVisibleInList,
    layerHasDontShowState,
} from "../../../../utils/mapUtils";
import {useTheme} from "../../../../context/themeContext";
import {AlertRhombusIcon} from "../../../../components/icons/icons/AlertRhombusIcon";
import Tooltip from "@mui/material/Tooltip";


type RowProps<T> = {
    rowItem: T;
    visible: boolean;
    children: any;
};

type LayerRowProps = RowProps<LayerTypes>;

export const LayerRow = React.memo(({
                                        children,
                                        rowItem,
                                        visible,
                                    }: LayerRowProps) => {
    const mounted = useRef<boolean>(false);
    const layersWithSameSource: DataById<LayerTypes> = useTypedSelector(state => selectLayersWithSameSourceById(state, rowItem.source));
    const dispatch = useDispatch();

    useEffect(() => {
        if (mounted.current) {
            dispatch(changedLayerVisibility({layer: rowItem, visible}));
        } else {
            mounted.current = true;
        }
    }, [visible]);

    const changeVisibility = ({target}) => {
        Object.values(layersWithSameSource).map(layer => {
            dispatch(changedLayerVisibility({layer, visible: target.checked}));
        })
    }

    return (
        <div className="layer-row-item layer-row-item_colored">
            <div className="row-check">
                {
                    rowItem.loading
                        ? <LoadingSpinner
                            size="13px"
                            backgroundColor={'transparent'}
                        />
                        : <input
                            type="checkbox"
                            checked={visible}
                            onChange={changeVisibility}
                        />
                }
            </div>
            <div className="row-name">
                <span>{rowItem.layerName}</span>
            </div>
            <div className="layer-row-item__controls">
                {
                    children
                }
            </div>
        </div>
    );
});

const GroupRow = ({title}: { title: string }) => {
    return (
        <div className="layer-row-item">
            {title}
        </div>
    )
}

export const LayersList = () => {
    const allLayersById = useTypedSelector(selectAllLayersById);
    const allLayerIds = useTypedSelector(selectAllLayersIds);
    const sourcesById = useTypedSelector(selectSourcesById);
    //TODO probably calculationStateBySourceId should be get in sources => remove calculationStateBySourceId in map reducer
    const calculationStateBySourceId = useTypedSelector(selectCalculationState);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {theme} = useTheme();

    const layersList = useMemo(() => {

        const layersByGroup: DataById<LayerTypes[]> = allLayerIds
            .filter(id => !layerHasDontShowState(calculationStateBySourceId[id]) && isLayerVisibleInList(allLayersById[id]))
            .reduce((acc, id) => {
                const layer = allLayersById[id];
                const {group} = sourcesById[id];

                if (!acc[group]) {
                    acc[group] = [];
                }
                acc[group].push(layer);

                return acc;
            }, {});

        const layersList: any[] = [];
        for (const [group, layers] of Object.entries(layersByGroup)) {
            layersList.push(<GroupRow title={group} key={group}/>);

            for (const layer of layers) {
                const {dayTimeDependent} = sourcesById[layer.source];

                layersList.push(
                    <LayerRow
                        key={layer.id}
                        rowItem={layer}
                        visible={isLayerVisibleOnMap(layer)}
                    >
                        {
                            dayTimeDependent
                                ?
                                <Tooltip title={t('layers-list.daytime-dependent-layer-warning')} placement="top">
                                    <span>
                                        <AlertRhombusIcon/>
                                    </span>
                                </Tooltip>
                                : null
                        }
                    </LayerRow>
                );
            }
        }

        return layersList;
    }, [allLayersById, calculationStateBySourceId, sourcesById]);

    const allLayersSelectionHandler = ({target}) => {
        Object.values(allLayersById).map(layer => dispatch(changedLayerVisibility({layer, visible: target.checked})));
    };

    return (
        <div
            className={`layers-list-container ${theme}`}
            data-testid="layersList"
        >
            {
                layersList.length
                    ? <>
                        <div className="layers-list-header">
                            <div className="row-check">
                                <input
                                    type="checkbox"
                                    onChange={allLayersSelectionHandler}
                                />
                            </div>
                            <div className="row-name">
                                <span>{t('layers-list.all-layers')}</span>
                            </div>
                        </div>
                        {layersList}
                    </>
                    : t('map.no-layers-exist-warning')
            }
        </div>
    );
};

export default LayersList;

import React, {ReactNode, useState} from "react";
import {PropsWithChildren} from "react";
import {ArrowSplit} from "../icons/icons";
import {ResizableProps} from "re-resizable";
import "./left-panel.scss";
import {useTheme} from "../../context/themeContext";


export type InfoPanelProps = {
  title?: string;
  button?: ReactNode;
  side?: 'left' | 'right';
  initWidth?: number;
  initHeight?: number | string;
  showCloseIcon?: boolean;
  setVisible?: any;
};

const LeftPanel = ({
                                 title,
                                 side = 'left',
                                 initWidth = 430,
                                 initHeight = '100%',
                                 children
                               }: PropsWithChildren<InfoPanelProps>) => {
  const [height, setHeight] = useState<number | string>(initHeight);
  const [width, setWidth] = useState<number | string>(initWidth);
  const {theme} = useTheme();

  function resizeHeigth(event: MouseEvent) {
    setHeight(event.clientY - 120);
  }

  function onEnd() {
    window.removeEventListener("mousemove", resizeHeigth);
    window.removeEventListener("mouseup", onEnd);
  }

  function onStart() {
    // setHeight(parent.current?.getBoundingClientRect().height || 0);
    window.addEventListener("mousemove", resizeHeigth);
    window.addEventListener("mouseup", onEnd);
  }

  function resizeWidth(event: MouseEvent) {
    if (event.clientX > 32) {
      setWidth(event.clientX - 16);
    }
  }

  function onMoveLeftStop() {
    window.removeEventListener("mousemove", resizeWidth);
    window.removeEventListener("mouseup", onMoveLeftStop);
  }

  function onMoveLeft() {
    window.addEventListener("mousemove", resizeWidth);
    window.addEventListener("mouseup", onMoveLeftStop);
  }

  return (
    <div className="overlay-panel-container" style={{width}}>
      <div
        className={`overlay-panel ${theme} ${side == 'left' ? 'overlay-panel-left' : 'overlay-panel-right'}`}
        style={{height, width}}
      >
        <div
          className="overlay-panel-header"
          style={height <= 1 ? {border: "none"} : {}}
        >
          <div
            className="overlay-panel-header__title"
            style={{paddingTop: '12px'}}
          >
            <span>{title}</span>
          </div>
          <div className="overlay-panel-header__buttons">
          </div>
        </div>
        <div className="overlay-panel-body">
          {children}
        </div>
      </div>
      <div className={`overlay-panel-side ${theme}`} onMouseDown={onMoveLeft}>
        <ArrowSplit width={16} height={16}/>
      </div>
      <div className={`overlay-panel-footer ${theme}`} onMouseDown={onStart}>
        <ArrowSplit width={16} height={16}/>
      </div>
    </div>
  );
};

export default LeftPanel;

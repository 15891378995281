import React from 'react';
import {DeleteIcon} from "../icons/icons/DeleteIcon";
import './entities-list-row.scss';
import {IconButton} from "../buttons/IconButton/IconButton";
import LinkWrapper from "../LinkWrapper/LinkWrapper";
import classNames from "classnames";
import {BaseEntity, BaseEntityNameLess} from "../../api/entities/BaseEntity";


export type EntitiesListRowProps<T extends BaseEntity | BaseEntityNameLess> = {
    entity: T;
    deleteFn: ({entity}: { entity: T }) => Promise<any>;
    form?: any; // fix type
    selected?: boolean;
    getUrlFunc?: (entity: T) => string;
    onRowClick: (entity: T) => Promise<void>;
    hasLink?: boolean;
}

export const EntitiesListRow = React.memo(React.forwardRef(<T extends BaseEntityNameLess, >({
                                                                                                entity,
                                                                                                deleteFn,
                                                                                                form: Form,
                                                                                                selected,
                                                                                                getUrlFunc,
                                                                                                onRowClick,
                                                                                                hasLink = true
                                                                                            }: EntitiesListRowProps<T>, ref: React.ForwardedRef<HTMLDivElement>) => {
    const classes = classNames(
        'entities-list-row',
        selected && 'entities-list-row_selected'
    )

    const onDeleteHandler = async (event, entity: T) => {
        //required in order not to go to "edit stop" page
        event.stopPropagation();
        event.preventDefault();

        deleteFn?.({entity});
    }

    return (
        <LinkWrapper
            to={getUrlFunc?.(entity) ?? `./${entity.id}/`}
            hasLink={hasLink}
        >
            <div
                className={classes}
                onClick={() => onRowClick(entity)}
                ref={ref}
                data-testid={`entitiesListRow_${entity.id}`}
            >
                <div className={`entities-list-row__fields`}>
                    {Form ?
                        <Form entity={entity}/>
                        : entity.id
                    }
                </div>
                <div className="entities-list-row__actions">
                    <IconButton
                        className="entities-list-row__delete-btn"
                        icon={DeleteIcon}
                        width={20}
                        height={20}
                        onClick={(event) => onDeleteHandler(event, entity)}
                        borderless
                        testId="entitiesListRowDeleteBtn"
                    />
                </div>
            </div>
        </LinkWrapper>
    )
}))
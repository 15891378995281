import React from "react";
import {Link, Outlet, useMatch} from "react-router-dom";
import {useTheme} from "../../../context/themeContext";
import {useTranslation} from "react-i18next";
import BreadcrumbsByRoutes from "../../../components/BreadcrumbsByRoutes/BreadcrumbsByRoutes";


const MaasManager = () => {
  const {theme} = useTheme();
  const {t} = useTranslation();

  //TODO update?
  const match = useMatch('/:projectId/:mode/:entity');
  const mode = match?.params?.entity;

  const tabs = [
    {
      url: './',
      title: t('drt.tab-zones'),
      path: undefined
    },
    {
      url: './layers',
      title: t('drt.tab-layers'),
      path: 'layers'
    }
  ];

  return (
    <div className={`transit-manager ${theme}`}>
      <nav className="transit-manager__menu">
        {
          tabs.map(({url, title, path}, ind) => {
            return (
              <Link
                key={ind}
                to={url}
                className={`transit-manager__link ${mode === path ? 'active' : ''}`}
              >
                <div className="transit-manager__tab">
                  <div className="transit-manager__tab-title">
                    {title}
                  </div>
                </div>
              </Link>
            )
          })
        }
        <div className="transit-manager__tab transit-manager__tab_empty"></div>
      </nav>
      <main className="transit-manager__main">
        <div className="actions"></div>
          <BreadcrumbsByRoutes/>
          <Outlet/>
      </main>
    </div>
  )
}

export default MaasManager;
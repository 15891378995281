import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectSelectedPresetId} from "../../redux/selectors/selectors";
import Modal from "../Modal/Modal";
import {getFilledFieldsObjOnFormSubmit, isErrorResponse} from "../../utils/utils";
import {LoadingComponent} from "../LoadingComponent/LoadingComponent";
import {Form, Row} from "../form/Form/Form";
import * as SelectBox from "../form/SelectBox/SelectBox";
import * as TextField from "../form/TextField/TextField";
import {useTraceRoutesToNetwork} from "../../hooks/transitlLines/useTraceRoutesToNetwork";
import {TraceRoutesToNetworkData} from "../../api/presetsApi/types";
import {useToastContext} from "../../context/toastContext";
import Button from "../buttons/Button";


const TraceRoutesToNetwork = () => {
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const transitPresetId = useTypedSelector(selectSelectedPresetId);
    const {loading, loadRoadPresets, roadPresetsOptions, traceRoutesToNetwork} = useTraceRoutesToNetwork();
    const {addToast} = useToastContext();
    const {t} = useTranslation();

    const handleBtnClick = useCallback(async () => {
        setModalVisible(true);
        await loadRoadPresets();
    }, [setModalVisible, loadRoadPresets])

    const onFormSubmit = useCallback(async (event) => {
        const formDataObj = getFilledFieldsObjOnFormSubmit(event);
        const resp = await traceRoutesToNetwork(formDataObj as TraceRoutesToNetworkData);

        if (!isErrorResponse(resp)) {
            addToast(`${t('transit-lines.trace-routes-to-network-successful')}`, 'success');
        }
        setModalVisible(false);
    }, [setModalVisible, traceRoutesToNetwork]);

    return (
        <>
            <div>
                <Button
                    text={t('transit-lines.trace-routes-to-network-btn')}
                    onClick={handleBtnClick}
                    disabled={!transitPresetId}
                    colorType="transparent"
                />
            </div>
            {
                modalVisible ?
                    <Modal title={t("transit-lines.trace-routes-to-network-form-title")} setVisible={setModalVisible}>
                        <LoadingComponent isLoading={loading}>
                            <Form
                                name="traceRoutesForm"
                                onFormSubmit={onFormSubmit}
                                submitBtnTitle={t('transit-lines.trace-routes-to-network-form-btn')}
                                disabled={!transitPresetId}
                            >
                                <Row>
                                    <TextField.Labeled
                                        name='transitPresetId'
                                        type="text"
                                        value={transitPresetId}
                                        hidden
                                    />
                                </Row>
                                <Row>
                                    <SelectBox.Labeled
                                        label={t('transit-lines.road-preset')}
                                        name='roadPresetId'
                                        options={roadPresetsOptions}
                                    />
                                </Row>
                            </Form>
                        </LoadingComponent>
                    </Modal>
                    : null
            }
        </>
    )
}

export default TraceRoutesToNetwork;
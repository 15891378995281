import {Card, CardContent, CardHeader, Grid} from "@mui/material";
import ModeCount from "./charts/ModeCount";
import {Mode} from "./types";
import {useTranslation} from "react-i18next";
import {useTheme} from "../../context/themeContext";
import {themeColors} from "../../styles/theme";


type Props = {
  modes: { label: string; mode: Mode; }[];
}

const ModesCounter = ({modes}: Props) => {
  const {t} = useTranslation();
  const {theme} = useTheme();

  const background = theme === 'light' ? themeColors.white : themeColors.navyBlue

  return (
    <Card sx={{height: '100%', background}}>
      <CardHeader
        title={t('dashboard.trips-number-title')}
        sx={{color: theme === 'light' ? themeColors.navyBlue : themeColors.white}}
      />
      <CardContent>
        <Grid container spacing={2}>
          {
            modes.map(({mode}, ind) => {
              return (
                <Grid key={ind} item xs={6}>
                  <ModeCount mode={mode}/>
                </Grid>
              )
            })
          }
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ModesCounter;
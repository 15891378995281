import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {MetadataPropertyEntity} from "../../api/entities/replancity_MetadataProperty";
import {DataById} from "../map/types";

type State = {
    dataByEntityName: any;
    metadataByEntityName: DataById<MetadataPropertyEntity[]>;
    isChanged: boolean;
}

export const initialState: State = {
    dataByEntityName: {},
    metadataByEntityName: {},
    isChanged: false,
}

const entitySlice = createSlice({
    name: 'entity',
    initialState,
    reducers: {
        setEntity(state, action: PayloadAction<{ entityName: string; entity?: any; }>) {
            const {entityName, entity} = action.payload;
            state.dataByEntityName[entityName] = entity ?? {};
        },
        setEntityMetadata(state, action: PayloadAction<{ entityName: string; metadata: MetadataPropertyEntity[]; }>) {
            const {entityName, metadata} = action.payload;
            state.metadataByEntityName[entityName] = metadata ?? {};
        },
        removeEntity(state, action: PayloadAction<string>) {
            const {[action.payload]: deletedEntity, ...restEntities} = state.dataByEntityName;
            const {[action.payload]: deletedMetaData, ...restMetaData} = state.metadataByEntityName;
            state.dataByEntityName = restEntities;
            state.metadataByEntityName = restMetaData;
        },
        setEntityChanged(state, action: PayloadAction<boolean>) {
            state.isChanged = action.payload;
        },
    },
});

export const {
    setEntity,
    setEntityMetadata,
    removeEntity,
    setEntityChanged,
} = entitySlice.actions;

export default entitySlice.reducer

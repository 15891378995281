import React, {useCallback, useMemo, useRef, useState} from "react";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId, selectProjectsById} from "../../../../redux/selectors/selectors";
import {ChartData} from "chart.js";
import {dashboardApi} from "../../../../api/dashboardApi";
import {useLoadChartData} from "../../hooks/useLoadChartData";
import {Card, CardContent, CardHeader, Grid, Paper, Skeleton} from "@mui/material";
import {useTranslation} from "react-i18next";
import {combineProjectsDatasets, formatFloat} from "../../../../utils/utils";
import {Drt2SimulationMetaResultsEntity} from "../../../../api/entities/replancity_Drt2SimulationMetaResults";
import './drt-statistics-chart.scss';
import {projectsApi} from "../../../../api/projectsApi";
import {useTheme} from "../../../../context/themeContext";
import {themeColors} from "../../../../styles/theme";
import Button from "../../../../components/buttons/Button";


type Props = {
  projectIdToCompare?: string
}

const DrtStatisticsChart = ({projectIdToCompare = ''}: Props) => {
  const projectId = useTypedSelector(selectCurrentProjectId);
  const projectsById = useTypedSelector(selectProjectsById);
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  const [forceReload, setForceReload] = useState<any>({}); // object is used to simply update state
  const {t} = useTranslation();
  const {theme} = useTheme();
  const abortControllerRef = useRef(new AbortController());

  const background = theme === 'light' ? themeColors.white : themeColors.navyBlue;
  const paperBackground = theme === 'light' ? themeColors.white : themeColors.grey4;
  const projectIds = [projectId, ...(projectIdToCompare ? [projectIdToCompare] : [])];

  const promises: Promise<ChartData>[] = useMemo(() => {
    return projectIds.map(projectId => dashboardApi.getDrtStatistics({
      projectId,
      abortSignal: abortControllerRef.current.signal
    }))
  }, [projectId, projectIdToCompare, forceReload]);

  const {loading, data} = useLoadChartData<Drt2SimulationMetaResultsEntity[]>({promiseOrPromises: promises});

  const combinedData: Partial<Record<keyof Drt2SimulationMetaResultsEntity, any[]>> = combineProjectsDatasets<Drt2SimulationMetaResultsEntity>(data || []);

  const {
    rides,
    totalPayment,
    averagePayment,
    requests,
    rejections,
    acceptanceRate,
    averageDirectDistanceMeters,
    averageTravelledDistanceMeters,
    averageDetourMeters,
    averageTravelTime,
    averageInVehicleTravelTime,
    averageWaitingTime,

    totalDrtVehicles,
    totalVehicleDistanceInKM,
    totalPassengerDistanceInKM,
    pkm2Vkm,
    averageEmptyDistance
  } = combinedData || {};

  const labelsValues = [
    {label: t('dashboard.drt-statistics.drt-chart-rides'), value: rides},
    {label: t('dashboard.drt-statistics.drt-chart-requests'), value: requests},
    {label: t('dashboard.drt-statistics.drt-chart-average-direct-distance'), value: averageDirectDistanceMeters},
    {label: t('dashboard.drt-statistics.drt-chart-average-travel-time'), value: averageTravelTime},
    {label: t('dashboard.drt-statistics.drt-chart-total-payment'), value: totalPayment},
    {label: t('dashboard.drt-statistics.drt-chart-rejections'), value: rejections},
    {label: t('dashboard.drt-statistics.drt-chart-average-traveller-distance'), value: averageTravelledDistanceMeters},
    {label: t('dashboard.drt-statistics.drt-chart-average-in-vehicle-travel-time'), value: averageInVehicleTravelTime},
    {label: t('dashboard.drt-statistics.drt-chart-average-payment'), value: averagePayment},
    {label: t('dashboard.drt-statistics.drt-chart-acceptance-rate'), value: acceptanceRate},
    {label: t('dashboard.drt-statistics.drt-chart-detour'), value: averageDetourMeters},
    {label: t('dashboard.drt-statistics.drt-chart-average-waiting-time'), value: averageWaitingTime},
    {label: t('dashboard.drt-statistics.drt-chart-total-drt-vehicles'), value: totalDrtVehicles},
    {label: t('dashboard.drt-statistics.drt-chart-total-vehicle-distance'), value: totalVehicleDistanceInKM},
    {label: t('dashboard.drt-statistics.drt-chart-total-passenger-distance'), value: totalPassengerDistanceInKM},
    {label: t('dashboard.drt-statistics.drt-chart-pkm-to-vkm'), value: pkm2Vkm},
    {label: t('dashboard.drt-statistics.drt-chart-average-empty-distance'), value: averageEmptyDistance}
  ];

  const recalculateDrtStatistics = useCallback(async () => {
    setShowSkeleton(true);
    const resp: string = await projectsApi.recalculateDrtStatistics(projectId);
    setForceReload({});
    setShowSkeleton(false);
  }, [projectId])

  return (
    <Grid item>
      <Card sx={{height: '100%', background}}>
        <CardHeader
          title={
            <Grid container>
              <Grid item xs>
                {t('dashboard.drt-statistics.drt-statistics-title')}
              </Grid>
              <Grid item>
                <Button
                  onClick={recalculateDrtStatistics}
                  text={t('dashboard.drt-statistics.drt-recalculate-statistics-btn')}
                  disabled={loading || showSkeleton}
                  colorType="transparent"
                />
              </Grid>
            </Grid>
          }
          sx={{color: theme === 'light' ? themeColors.navyBlue : themeColors.white}}
        />
        <CardContent>
          {
            !data || loading || showSkeleton
              ? <Skeleton variant="rectangular"/>
              : <Grid container spacing={2}>
                {
                  projectsById[projectIds[1]] &&
                    <Grid container item spacing={2}>
                        <Grid container item xs={6}>
                          <span
                              className={`dashboard-statistics-color ${theme}`}
                          >
                          </span>
                            <span
                                className={`dashboard-statistics-projectname ${theme}`}
                            >
                            {projectsById[projectIds[0]].projectName}
                          </span>
                        </Grid>
                        <Grid container item xs={6}>
                          <span
                              className={`dashboard-statistics-compared-color ${theme}`}
                          >
                          </span>
                            <span
                                className={`dashboard-statistics-compared-projectname ${theme}`}
                            >
                            {projectsById[projectIds?.[1]].projectName}
                          </span>
                        </Grid>
                    </Grid>
                }
                <Grid container item spacing={2}>
                  {
                    labelsValues.map(({label, value}, ind) => (
                      <Grid key={ind} item>
                        <Paper sx={{padding: '10px', background: paperBackground}}>
                          <p style={{color: theme === 'light' ? themeColors.navyBlue : themeColors.grey3}}>{label}</p>
                          {
                            value
                              ? <>
                                <h2
                                  style={{color: theme === 'light' ? themeColors.navyBlue : themeColors.white}}
                                >
                                  {formatFloat(value[0])}
                                </h2>
                                {
                                  value?.[1] &&
                                    <h2 style={{color: themeColors.invalidColor1}}>{formatFloat(value[1])}</h2>
                                }
                              </>
                              : <Skeleton variant="rectangular"/>
                          }
                        </Paper>
                      </Grid>
                    ))
                  }
                </Grid>
              </Grid>
          }
        </CardContent>
      </Card>
    </Grid>
  )
}

export default DrtStatisticsChart;
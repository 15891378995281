import React from 'react';
import {useForm, Controller} from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
  Typography
} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {Trans, useTranslation} from "react-i18next";
import {AuthPagesWrapper} from "../components/AuthPagesWrapper/AuthPagesWrapper";


export const SignUpPage = () => {
  const [showPassword, setPasswordVisibility] = React.useState<boolean>(false);
  const {handleSubmit, control} = useForm();
  const {t} = useTranslation();

  const onSubmit = (data: any) => {
    return;
  };

  const handleClickShowPassword = () => {
    setPasswordVisibility(prevState => !prevState);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <AuthPagesWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Typography component="h1" variant="h5">
            {t('sign-up.title')}
          </Typography>
          <Typography component="p">
            {t("sign-up.title-message")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="email"
              control={control}
              rules={{required: true}}
              render={({field, fieldState, formState}) =>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label={t('sign-up.email')}
                  autoFocus
                  onChange={field.onChange}
                />}
            />
            <Controller
              name="password"
              control={control}
              rules={{required: true}}
              render={({field, fieldState, formState}) =>
                <FormControl sx={{my: 1, width: '100%'}} variant="outlined" required>
                  <InputLabel htmlFor="create-account-password">{t('sign-up.password')}</InputLabel>
                  <OutlinedInput
                    id="create-account-password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={field.onChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={t('sign-up.password')}
                  />
                </FormControl>
              }
            />
            <Controller
              name="password"
              control={control}
              rules={{required: true}}
              render={({field, fieldState, formState}) =>
                <FormControl sx={{my: 1, width: '100%'}} variant="outlined" required>
                  <InputLabel htmlFor="sign-up-repeat-password">{t('sign-up.repeat-password')}</InputLabel>
                  <OutlinedInput
                    id="sign-up-repeat-password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={field.onChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={t('sign-up.repeat-password')}
                  />
                </FormControl>
              }
            />
            <Divider sx={{mt: 5}}
            />
            <Grid container justifyContent="space-between">
              <Controller
                name="readTerms"
                control={control}
                render={({field, fieldState, formState}) =>
                  <FormControlLabel
                    sx={{width: '100%'}}
                    control={<Checkbox value="readTerms" color="primary"/>}
                    label={
                      <Trans i18nKey="sign-up.read-terms-checkbox">
                        I've read and accept the <Link href="#"> Terms & conditions</Link>
                      </Trans>
                    }
                    onChange={field.onChange}
                  />
                }
              />
              <Controller
                name="subscribeNews"
                control={control}
                render={({field, fieldState, formState}) =>
                  <FormControlLabel
                    sx={{width: '100%'}}
                    control={<Checkbox value="subscribeNews" color="primary"/>}
                    label={t("sign-up.subscribe-to-news-checkbox")}
                    onChange={field.onChange}
                  />
                }
              />
              <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{mt: 3, mb: 2}}
                  style={{background: "themeColors.purple"}}
                >
                  {t('sign-up.create-account-button')}
                </Button>
              </Box>
            </Grid>
            <Grid container sx={{mt: 3, whiteSpace: "pre-wrap"}} justifyContent="center">
              <Trans i18nKey="sign-up.already-have-account-link">
                Already have an account? <Link href="/signin">Sign in</Link>
              </Trans>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </AuthPagesWrapper>
  )
}
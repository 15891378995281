import React from 'react';
import LoadingSpinner from "../Spinner/LoadingSpinner";

interface Props {
  isLoading: boolean;
}

export const LoadingComponent = ({isLoading = false, children}: React.PropsWithChildren<Props>) => {
  return (
    <>
      {isLoading
        ? <LoadingSpinner/>
        : children
      }
    </>
  )
}
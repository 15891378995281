import React, {useState} from 'react';
import {usersApi} from "../api/usersApi";
import {ApiError, OAuthResponse, UserToken} from "../api/RestClient";
import {useToastContext} from "./toastContext";
import {getLocalStorageItem} from "../utils/utils";


const getLocalStorageUserToken = () => getLocalStorageItem('userToken');

type AuthContextType = {
  token: UserToken | null,
  user: string,
  runLogin: (email: string, password: string) => Promise<void>,
  runRefreshToken: any
  runLogout: any;
  loading: boolean;
}

export const AuthContext = React.createContext<AuthContextType>({
  token: null,
  user: '',
  runLogin: async (email: string, password: string) => {
    return;
  },
  runRefreshToken: () => {
    return;
  },
  runLogout: async () => {
    return;
  },
  loading: false
});

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const AuthProvider = ({children}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = React.useState<UserToken | null>(getLocalStorageUserToken);
  const [user, setUser] = React.useState<string>(getLocalStorageItem('userName'));
  const {addToast} = useToastContext();

  const handleLogin = async (email: string, password: string) => {
    setLoading(true);
    const oAuthResponse: OAuthResponse | ApiError = await usersApi.login(email, password);
    setLoading(false);

    if ('error' in oAuthResponse) {
      addToast(`${oAuthResponse.error['error_description'] ?? oAuthResponse.error_description}`, 'error');
      setToken(null);
      return;
    }

    localStorage.setItem('userToken', JSON.stringify(oAuthResponse));
    localStorage.setItem('userName', email);
    const token = getLocalStorageUserToken();
    setToken(token);
    setUser(email);
  };

  const handleRefreshToken = async () => {
    const newToken = await usersApi.refreshToken();

    if (newToken && !('error' in newToken)) {
      localStorage.setItem('userToken', JSON.stringify(newToken))
      setToken(newToken);
    }
  }

  const handleLogout = async () => {
    setLoading(true);
    await usersApi.logout();
    localStorage.removeItem('userToken');
    localStorage.removeItem('userName');
    setToken(null);
    setUser('');
    setLoading(false);
  };

  const value = {
    token,
    user,
    runLogin: handleLogin,
    runRefreshToken: handleRefreshToken,
    runLogout: handleLogout,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
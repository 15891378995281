import React from 'react';
import EntityListRowForm from "../../../components/Entities/EntityListRowForm";
import {EntityServiceName} from "../../../api/enums/enums";
import {DrtZoneEntity} from "../../../api/entities/replancity_DrtZone";
import EntitiesLoader from "../../../components/Entities/EntitiesLoader/EntitiesLoader";


const getUrlFunc = ({id}: DrtZoneEntity) => `./${id}/edit`

const MaasZonesList = () => {
    const entityName = 'replancity_DrtZone';
    const serviceName = EntityServiceName.MAAS;

    //TODO navigate to list onDelete

    return (
        <EntitiesLoader
            entityName={entityName}
            serviceName={serviceName}
            form={EntityListRowForm}
            getUrlFunc={getUrlFunc}
        />
    )
}

export default MaasZonesList;
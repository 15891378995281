import React from "react";
import {ProjectEntity} from "../api/entities";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import moment from 'moment';
import {SimulationState} from "../api/entities/replancity_RunnedAlgorithm";
import {useTheme} from "../context/themeContext";
import {themeColors} from "../styles/theme";
import {ImagePlaceholder} from "./icons/icons/ImagePlaceholder";
import {getSimulationState, getSimulationStateColor} from "../utils/utils";


interface Props {
  projects: Array<ProjectEntity>;
  onModelClickHandler: any;
}

const ProjectsGrid: React.FC<Props> = ({projects, onModelClickHandler}) => {
  const {t} = useTranslation();
  const {theme} = useTheme();

  return (
    <div className="grid-wrapper">
      {projects.map((el) => {
        return (
          <Link
              key={el.id}
              onClick={() => onModelClickHandler(el.projectName)}
              to={`/${el.id}`}
          >
            <div
              className={`grid-elements ${theme}`}
              data-testid={`project-row_${el.id}`}
            >
              <div className="grid-elements-content">
                <div className={`flex-img ${theme}`}>
                  <ImagePlaceholder
                    width={95}
                    height={95}
                    color={theme === 'light' ? themeColors.textGrey : themeColors.navyBlue}
                    backgroundColor={theme === 'light' ? themeColors.lightGrey : themeColors.grey3}
                  />
                </div>
                <div className="flex-text">
                  <span className={`grid-elements-content__header ${theme}`}>{el.projectName}</span>
                  <span className={`content-text ${theme}`}>
                        {t('common.last-update-date')}: {moment(el.updateTs).format('YYYY-MM-DD HH:mm:ss')}
                       </span>
                  <span className={`content-text ${theme}`}>
                         {t('projects-main.simulation-state')}:
                         <span
                           className="simulation-state"
                           style={{
                             'color': themeColors.navyBlue,
                             'marginLeft': '5px',
                             'background': getSimulationStateColor(el.simulationState! as SimulationState)
                           }}
                         >
                          {getSimulationState(el.simulationState as SimulationState, t)}
                         </span>
                       </span>
                </div>
              </div>
            </div>
          </Link>
        );
      })
      }
    </div>
  );
};
export default ProjectsGrid;

import React from "react";
import GroupBox from "../../components/groupBox/GroupBox";
import "./profile.scss";
import info from "../../assets/info.svg";
import {useTranslation} from "react-i18next";
import {useToastContext} from "../../context/toastContext";
import {useAuth} from "../../context/authContext";
import Header from "../../components/Header";
import Button from "../../components/buttons/Button";

function Profile() {
  const {user} = useAuth();
  const {t} = useTranslation();
  const {addToast} = useToastContext();

  const clickListener = () => {
    addToast(t('user-profile.not-enough-permissions'), 'error');
  }

  return (
    <>
      <Header projectName={t('page-titles.settings-user')}/>
      <div className="profile">
        <div className="profile-container">
          <div className="groupbox-container">
            <GroupBox header={t('user-profile.user')}>
              <div className="user-description">
                <p>{t('user-profile.name')}:</p>
                <p>{user}</p>
              </div>
              <div className="user-description">
                <p>{t('user-profile.user-type')}:</p>
                <p>{t('user-profile.individual-user')}</p>
              </div>
            </GroupBox>
          </div>
          <div className="groupbox-container">
            <GroupBox header={t('user-profile.subscription')}>
              <div className="subscription-description">
                <p>{t('user-profile.subscription-plan')}</p>
                <div className="rate-type">
                  <p>Demo</p>
                  <div className="tooltip">
                    <img src={info} alt="Info"/>
                    <span className="tooltiptext">{t('user-profile.subscription-plan-tooltip')}</span>
                  </div>
                </div>
              </div>
              <div className="subscription-description">
                <p>{t('user-profile.valid-until')}</p>
                <p>22.12.33</p>
              </div>
              <div className="button-block-vertical">
                <Button
                  text={t('user-profile.change-subscription-plan')}
                  onClick={clickListener}
                  colorType="light"
                />
                <Button
                  text={t('user-profile.auto-renewal-turn-off')}
                  onClick={clickListener}
                  colorType="dark"
                />
              </div>
            </GroupBox>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;

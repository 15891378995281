import {IconProps} from "../icons";


export const CloseIcon = ({width = 12, height = 12, color}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.62571 6L9 8.37429V9H8.37429L6 6.62571L3.62571 9H3V8.37429L5.37429 6L3 3.62571V3H3.62571L6 5.37429L8.37429 3H9V3.62571L6.62571 6Z"
        fill={color}
      />
    </svg>
  )
}
import React, {useCallback} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../../../redux/selectors/selectors";
import EntityEditor from "../../../../components/Entities/EntityEditor";
import EntityEditorWrapper from "../../../../components/Entities/EntityEditorWrapper";


const RoadSegmentEdit = () => {
    const entityName = 'replancity_RoadInfrastructureSegment';
    const {roadSegmentId, roadId} = useParams<any>();
    const sourceId = useTypedSelector((state) => selectMapLayerIdByEntityName(state, entityName));
    const navigate = useNavigate();

    const onSaveFn = useCallback((entity: any) => {
        navigate(`../${entity.id}/edit`);
    }, []);

    const onDeleteFn = useCallback((entity: any) => {
        navigate('../');
    }, []);

    const saveArgsFn = useCallback((entity: any) => ({
        roadInfrastructureRoad: {id: roadId}
    }), [roadId]);

    return (
        <EntityEditorWrapper entityName={entityName}>
            <EntityEditor
                entityId={roadSegmentId!}
                entityName={entityName}
                layerId={sourceId}
                saveFnArgs={saveArgsFn}
                presetProperty={'roadInfrastructurePreset'}
                undoLastActionBtn={true}
                onSaveFn={onSaveFn}
                onDeleteFn={onDeleteFn}
            />
        </EntityEditorWrapper>
    )
}

export default RoadSegmentEdit;
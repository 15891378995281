import React from "react";
import DepartureForm from "../Departures/DepartureForm";
import './transit-departures-list.scss';
import {PublicTransitLayerEntityName,} from "../../../../api/entities/replancity_PublicTransitLine";
import {EntityServiceName} from "../../../../api/enums/enums";
import EntitiesLoader from "../../../../components/Entities/EntitiesLoader/EntitiesLoader";
import {useParams} from "react-router-dom";


const getUrlFunc = ({id}: any) => `./${id}/edit`

const TransitRouteDeparturesList = () => {
    const entityName = PublicTransitLayerEntityName.DEPARTURE;
    const serviceName = EntityServiceName.PUBLIC_TRANSIT;
    const {routeId} = useParams();

    return (
        // <div className="transit-departures">
        //     <div className="transit-departures__list">
        <EntitiesLoader
            entityName={entityName}
            serviceName={serviceName}
            parentId={routeId}
            form={DepartureForm}
            getUrlFunc={getUrlFunc}
        />
        //     </div>
        // </div>
    )

}

export default TransitRouteDeparturesList;
import React, {createContext, useCallback, useContext, useState} from 'react';
import {Toast, ToastType} from "../components/toast/toast";
import {AlertColor} from "@mui/material/Alert/Alert";

const ToastContext = createContext({
  addToast: (message: string, type: AlertColor, autoHide?: boolean | undefined) => {
    return;
  },
  onClose: (toastId: string) => {
    return;
  }
});

const useToastContext = () => {
  return useContext(ToastContext);
};

const ToastProvider = ({children}) => {
  const [toasts, setToasts] = useState<any[]>([]);

  const addToast = useCallback((message: string, type: AlertColor, autoHide = true) => setToasts(prevToats => [...prevToats, {
    message,
    type,
    autoHide
  }]), [setToasts]);

  const onClose = useCallback((toastId: string) => setToasts(toasts.filter(toast => toast.id !== toastId)), []);

  return (
    <ToastContext.Provider value={{addToast, onClose}}>
      <div>
        {
          toasts.map((toast: ToastType, ind) =>
            <Toast key={ind} {...toast}/>
          )}
      </div>
      {children}
    </ToastContext.Provider>
  );
};

export {ToastProvider, useToastContext};
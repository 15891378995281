import React from "react";
import ProjectPage from "../../pages/ProjectPages/ProjectPage";
import IFrameComponent from "../../components/IFrameComponent";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId} from "../../redux/selectors/selectors";


const ConfigPage = () => {
  const projectId = useTypedSelector(selectCurrentProjectId);

  return (
      <ProjectPage
          main={
            <IFrameComponent
                url={`${process.env.REACT_APP_API_HOST}app/open?screen=replancity_Project4User.edit&item=replancity_Project-${projectId}`}
            />
          }
      />
  )
}

export default ConfigPage;
import {MapIcon} from "../icons/icons";
import {themeColors} from "../../styles/theme";
import {useTheme} from "../../context/themeContext";
import './skeleton.scss';


const MapSkeleton = () => {
    const {theme} = useTheme();

    return (
        <div className={`skeleton ${theme}`}>
            <div className={'skeleton__inner skeleton__inner_map'}>
                <MapIcon
                    color={themeColors.grey4}
                    width="100%"
                    height="100%"
                />
            </div>
        </div>
    )
}

export default MapSkeleton;
import {useEffect, useRef} from "react";
import {embedDashboard} from "@superset-ui/embedded-sdk";
import './side-panel-dashboards.scss';


const SsSidePanelDashboard = ({data}) => {
    const ref = useRef();

    useEffect(() => {
        if (data) {
            (async function () {
                const {dashbordExternalId, link, secretCode} = data;

                await embedDashboard({
                    id: dashbordExternalId,
                    supersetDomain: link,
                    mountPoint: ref.current!,
                    fetchGuestToken: () => secretCode,
                    dashboardUiConfig: {
                        hideTitle: true,
                        filters: {
                            visible: false,
                            expanded: false
                        }
                    },
                    // debug: true
                });
            }());
        }
    }, [data]);


    return (
        <div
            className="sup-set-dashboard"
            ref={ref as any}
        ></div>
    )
}

export default SsSidePanelDashboard;
import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {
    Outlet,
    useParams
} from "react-router-dom";
import {useDispatch} from "react-redux";
import {isErrorResponse, isUuidString} from "../../../../utils/utils";
import {
    PublicTransitLayerEntityName,
    PublicTransitRouteEntity
} from "../../../../api/entities/replancity_PublicTransitLine";
import {useEntityLoader} from "../../../../hooks/entites/useEntityLoader";
import {EntityView} from "../../../../api/entityApi";
import mapApi from "../../../../api/mapApi";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../../../redux/selectors/selectors";
import {
    removedFeaturesHighlighting,
    requireToHighlightFeatures,
    setSourceReloadRequired
} from "../../../../redux/map/map-reducer";
import {clearedSelectedMapFeatures} from "../../../../redux/showInfo/showInfoReducer";
import {FeatureProperties} from "../../../../redux/map/types";
import EntityEditorWrapper from "../../../../components/Entities/EntityEditorWrapper";


const TransitRoute = () => {
    const entityName = PublicTransitLayerEntityName.ROUTES;
    const entityView: EntityView = 'full';
    const dispatch = useDispatch();
    const routesLayerId: string = useTypedSelector((state) => selectMapLayerIdByEntityName(state, PublicTransitLayerEntityName.ROUTES));
    const abortControllerRef = useRef(new AbortController());

    const {routeId} = useParams<any>();
    const existingRouteId = useMemo(() => isUuidString(routeId) ? routeId : '', [routeId]);

    const {entity, loadData} = useEntityLoader<PublicTransitRouteEntity>({entityName});

    const {defaultRouteProfile, defaultSchedule} = entity;
    const {id: routeProfileId} = defaultRouteProfile ?? {};
    const {id: scheduleId} = defaultSchedule ?? {};

    useEffect(() => {
        return () => {
            dispatch(clearedSelectedMapFeatures());
            dispatch(removedFeaturesHighlighting());
        }
    }, []);

    const loadRoute = useCallback(async (reloadLayer = false) => {
        dispatch(removedFeaturesHighlighting());
        await loadData({entityName, entityId: existingRouteId, entityView});

        //TODO move to some "highlight" method?
        const featureResp: GeoJSON.Feature<GeoJSON.Geometry, FeatureProperties> = await mapApi.getFeatureById(existingRouteId!, entityName, abortControllerRef.current.signal);
        //TODO featureResp doesn't return anything. process this
        if (!isErrorResponse(featureResp) && featureResp && routesLayerId) {
            featureResp.properties['layerId'] = routesLayerId;
            dispatch(requireToHighlightFeatures(featureResp));
        }

        if (reloadLayer && routesLayerId) {
            dispatch(setSourceReloadRequired({sourceId: routesLayerId, isReloadRequired: true}));
        }
    }, [loadData, entityName, entityView, existingRouteId, routesLayerId]);

    useEffect(() => {
        (async function () {
            await loadRoute();
        }());
    }, [loadRoute])

    return (
        <>
            <EntityEditorWrapper entityName={entityName}>
                <Outlet context={{routeProfileId, scheduleId, loadRoute}}/>
            </EntityEditorWrapper>
        </>
    )
}

export default TransitRoute;
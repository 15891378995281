import React from "react";
import ProjectPage from "../../pages/ProjectPages/ProjectPage";
import Dashboard from "../../containers/Dashboard/Dashboard";


const ReportModePage = () => {
  return (
    <ProjectPage
      main={<Dashboard/>}
    />
  )
}

export default ReportModePage;
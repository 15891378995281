import React from "react";
import ProjectPage from "../../pages/ProjectPages/ProjectPage";
import ProjectPresetsSelector from "../../components/ProjectPresetsSelector/ProjectPresetsSelector";
import * as SelectBox from "../../components/form/SelectBox/SelectBox";
import {Row} from "../../components/form/Form/Form";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectPresetType, selectSelectedPresetId} from "../../redux/selectors/selectors";
import MapContainerWithSources from "../../containers/map/MapContainer/MapContainerWithSources";
import {EntityServiceName} from "../../api/enums/enums";
import TraceRoutesToNetwork from "../../components/TraceRoutesToNetwork/TraceRoutesToNetwork";
import {useNavigationOnFeaturesSelection} from "../../hooks/transitlLines/useNavigationOnFeaturesSelection";
import {copyModeOptions} from "../PublicTransitMode/constants";
import EvManager from "./EvManagement/EvManager";
import {GeoJsonGeometryTypes} from "geojson";
import * as SliderField from "../../components/form/SliderField/SliderField";


const getUrlFunc = ({childId}: {
    parentId?: string;
    childId: string;
    featureType: GeoJsonGeometryTypes
}) => `./${childId}/edit`;

const EvInfrastructureModePage = () => {
    const presetEntityName = 'replancity_EvPreset';
    const presetId = useTypedSelector(selectSelectedPresetId);
    const currentPresetType = useTypedSelector(selectPresetType);
    const {t} = useTranslation();

    const {navigateOnFeatureSelection} = useNavigationOnFeaturesSelection({getUrlFunc});

    return (
        <ProjectPage
            showSkeleton={!presetId || presetEntityName !== currentPresetType}
            subHeaderLeft={
                <>
                    <ProjectPresetsSelector
                        presetEntityName={presetEntityName}
                        serviceName={EntityServiceName.EV}
                        copySpinnerMsg={t('transit-lines.preset-copy-spinner-msg')}
                        formFields={
                            <>
                                <Row>
                                    <SelectBox.Labeled
                                        label={t('presets.add-preset-modal-copy-mode')}
                                        name='copyMode'
                                        options={copyModeOptions}
                                        required
                                    />
                                </Row>
                                <Row>
                                    <Row>
                                        <SliderField.Labeled
                                            label={t('simulation.settings-quality')}
                                            name="percentageOfElectricVehicles"
                                            min={0}
                                            max={1}
                                            step={0.1}
                                            defaultValue={1}
                                            required
                                        />
                                    </Row>
                                </Row>
                            </>
                        }
                    />
                    <TraceRoutesToNetwork/>
                </>
            }
            main={
                <MapContainerWithSources
                    mapConfigType={EntityServiceName.EV}
                    // defaultSources={NEW_ROUTE_SOURCE}
                    isDrawerEnabled={true}
                    editableFeatureTypes={{Point: true}}
                    handleMapClick={navigateOnFeatureSelection}
                />
            }
            leftPanel={<EvManager/>}
            leftPanelTitle={t('ev-infrastructure.ev-infrastructure-editor')}
        />
    )
}

export default EvInfrastructureModePage;
import React, {Dispatch, SetStateAction, useState, useEffect, useCallback} from "react";


export type ThemeType = 'light' | 'dark';

type ThemeStateType = {
  theme: ThemeType;
  setTheme: (theme: ThemeType) => void;
};

export const initialThemeState: ThemeStateType = {
  theme: "dark",
  setTheme: (theme: ThemeType) => null
};

const ThemeContext = React.createContext<ThemeStateType>(initialThemeState);

export const useTheme = () => {
  return React.useContext(ThemeContext);
};

const MyThemeProvider = ({children}) => {
  const [theme, setTheme] = useState<ThemeType>(initialThemeState.theme);
  const [loaded, setLoaded] = useState<boolean>(false);

  const localStorage = window.localStorage;

  useEffect(() => {
    const savedThemeLocal = localStorage.getItem("globalTheme");

    if (savedThemeLocal) {
      setTheme(savedThemeLocal as ThemeType);
    }

    setLoaded(true);
  }, []);

  //TODO page is fully reloaded instead of setTheme, because of necessity to completely reload map sources/layers otherwise
  // useEffect(() => {
  //   localStorage.setItem("globalTheme", theme);
  // }, [theme]);

  const updateTheme = useCallback((theme: ThemeType) => {
    // setTheme(theme);
    localStorage.setItem("globalTheme", theme);
    window.location.reload();
  }, [])

  return (
    <ThemeContext.Provider value={{theme, setTheme: updateTheme}}>
      {/*<div className={`theme--${theme}`}>*/}
      {loaded && children}
      {/*</div>*/}
    </ThemeContext.Provider>
  );
};

export default MyThemeProvider;
import {UserToken} from "../api/RestClient";

export const getCacheUserTokenFn = () => {
  let token = localStorage.getItem('userToken');
  let parsedToken = token ? JSON.parse(token) : null;

  return (): UserToken | null => {
    if (parsedToken) {
      return parsedToken;
    }
    token = localStorage.getItem('userToken');
    parsedToken = token ? JSON.parse(token) : null;
    return parsedToken;
  };
}

export const getCachedToken = getCacheUserTokenFn();
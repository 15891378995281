import React, {useMemo, useRef} from 'react';
import {Pie} from "react-chartjs-2";
import {ChartData} from "chart.js";
import {dashboardApi} from "../../../api/dashboardApi";
import {useLoadChartData} from "../hooks/useLoadChartData";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId} from "../../../redux/selectors/selectors";
import {ModalSplitType} from "../types";
import {Skeleton} from "@mui/material";


type Props = {
  source: ModalSplitType;
  showLegend?: boolean;
}


const PieChart = ({source, showLegend = false}: Props) => {
  const projectId = useTypedSelector(selectCurrentProjectId);
  const abortControllerRef = useRef(new AbortController());

  const promise: Promise<ChartData> = useMemo(() => dashboardApi.getModalSplit({
    projectId,
    modal: source,
    abortSignal: abortControllerRef.current.signal
  }), [source, projectId]);
  const {loading, data} = useLoadChartData<ChartData>({promiseOrPromises: promise});

  const getChart = (loading: boolean, data: ChartData<'pie', any, any>) => {
    if (data && !loading) {
      return (
        <Pie
          // width={50}
          height={300}
          data={data as any}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: showLegend
              },
              title: {
                display: false,
                text: "split"
              }
            }
          }}
        />
      )
    }
    return <Skeleton variant="rectangular"/>;
  }

  return (
    <div className="pie-chart-container">
      {
        getChart(loading, data as any)
      }
    </div>
  );
}

export default PieChart;
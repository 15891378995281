import React, {useCallback, useRef} from "react";
import MapControlButton from "./MapControls/MapControlButton";
import MagnetIcon from "../../../assets/magnet.svg";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectSnapMode} from "../../../redux/selectors/selectors";
import {setSnapMode} from "../../../redux/draw/draw-reducer";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";


const MapSnapButton = () => {
    const snapMode = useTypedSelector(selectSnapMode);
    const snapRef = useRef(snapMode);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    snapRef.current = snapMode;

    const snapModeHandler = useCallback(() => {
        dispatch(setSnapMode(!snapRef.current));
    }, [])

    return (
        <MapControlButton
            title={t('map.snap-btn')}
            className={snapMode ? 'mapboxgl-ctrl-icon_active' : ''}
            position="top-right"
            icon={MagnetIcon}
            eventHandler={snapModeHandler}
        />
    )
}

export default MapSnapButton;
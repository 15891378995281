import React from "react";


const IFrameComponent = ({url}) => {
  return (
    <iframe src={url} height="100%" width="100%" >
      Please update a browser
    </iframe>
  )
}

export default IFrameComponent;
import React from "react";
import {useParams} from "react-router-dom";
import EntityListRowForm from "../../../../components/Entities/EntityListRowForm";
import {PublicTransitLayerEntityName} from "../../../../api/entities/replancity_PublicTransitLine";
import {EntityServiceName} from "../../../../api/enums/enums";
import EntitiesLoader from "../../../../components/Entities/EntitiesLoader/EntitiesLoader";

const TransitRoutesList = () => {
    const entityName = PublicTransitLayerEntityName.ROUTES;
    const serviceName = EntityServiceName.PUBLIC_TRANSIT;
    const {lineId} = useParams<any>();

    return (
        <EntitiesLoader
            entityName={entityName}
            serviceName={serviceName}
            parentId={lineId}
            form={EntityListRowForm}
        />
    )
}

export default TransitRoutesList;
import React from "react";
import {IconWrapper} from "../../icons/icons";
import {ExportIcon} from "../../icons/icons/ExportIcon";
import './export-button.scss';
import {useTranslation} from "react-i18next";
import {themeColors} from "../../../styles/theme";
import {useTheme} from "../../../context/themeContext";


type ExportButtonProps = {
  onClick?: any;
  disabled?: boolean;
}

const ExportButton = React.memo(({onClick, disabled = false}: ExportButtonProps) => {
  const {t} = useTranslation();
  const {theme} = useTheme();

  return (
    <button className="export-button" onClick={onClick} disabled={disabled}>
      <IconWrapper
        icon={ExportIcon}
        width={24}
        height={24}
        color={theme === 'light' ? themeColors.bluePurple : themeColors.white}
      />
      <span>{t('common.export')}</span>
    </button>
  )
})

export default ExportButton;
import restClient from "./RestClient";
import {ViewPortCoordinates} from "./entities/local/Borders";
import {AlgorithmsViewMode} from "./algorithmsApi";


export type EntityView = '_local' | 'form' | 'full' | 'project-view_for_run_process' | AlgorithmsViewMode;


export type SaveEntityProps<T = Record<string, unknown>> = {
    entityName: string;
    id?: string;
    // layerId?: string;
    data: T;
    params?: { [key: string]: string } & { responseView: EntityView };
    abortSignal: AbortSignal
}

export type GetEntityProps = {
    entityId?: string;
    entityName: string;
    params?: { [key: string]: string } & { view: EntityView };
    abortSignal: AbortSignal
}

export const entityApi = {
    saveEntity: function <T>({
                                 entityName,
                                 id,
                                 data,
                                 params: params = {responseView: '_local'},
                                 abortSignal
                             }: SaveEntityProps): Promise<T> {
        return restClient.fetch({
            method: id ? 'PUT' : "POST",
            path: `entities/${entityName}/${id ?? ''}`,
            data,
            fetchOptions: {
                params: {
                    ...params,
                },
                signal: abortSignal
            }
        });
    },

    getEntity: function <T>({
                                entityId,
                                entityName,
                                params: params = {view: '_local'},
                                abortSignal
                            }: GetEntityProps): Promise<T> {
        return restClient.fetch({
            method: "GET",
            path: entityId ? `entities/${entityName}/${entityId}` : `projects/template/${entityName}/`,
            fetchOptions: {
                params: {
                    ...params,
                },
                signal: abortSignal
            }
        });
    },

    getLeftPanelEntitiesList: function <T>({
                                               entityName,
                                               presetId,
                                               parentId,
                                               serviceName,
                                               viewPort,
                                               searchPhrase = '',
                                               abortSignal
                                           }: {
        entityName?: string,
        presetId: string,
        parentId?: string,
        serviceName: string;
        viewPort?: Omit<ViewPortCoordinates, 'zoom'>,
        searchPhrase?: string,
        abortSignal: AbortSignal
    }): Promise<T[]> {
        return restClient.fetch({
            method: "POST",
            path: `services/${serviceName}/list_${entityName}`,
            data: {
                requestProperties: {
                    presetId,
                    ...(parentId ? {parentId} : {}),
                    ...(viewPort
                            ? viewPort
                            : {}
                    ),
                    ...(searchPhrase ? {
                        useViewPort: false,
                        searchPhrase,
                    } : {useViewPort: true}),
                }
            },
            fetchOptions: {signal: abortSignal}
        });
    },

    deleteEntity: function ({entityId, entityName, abortSignal}:
                                {
                                    entityId: string;
                                    entityName: string;
                                    abortSignal: AbortSignal;
                                }
    ): Promise<any> {
        return restClient.fetch({
            method: "DELETE",
            path: `entities/${entityName}/${entityId}`,
            fetchOptions: {signal: abortSignal}
        });
    },

}
import * as React from "react";
import MaasModePage from "./MaasModePage";
import {Route} from "react-router-dom";
import MaasZonesList from "./MaasManagement/MaasList";
import MaasZoneEdit from "./MaasManagement/MaasZoneEdit";
import LayersListWrapper from "../../containers/map/tools/LayersList/LayersListWrapper";


export const useMaasRoutes = () => {
    return (
        <Route
            path="maas"
            element={<MaasModePage/>}
            handle={{crumb: (data: any) => ({name: 'Maas zones', ...data})}}
        >
            <Route index element={<MaasZonesList/>}/>
            <Route path="new" element={<MaasZoneEdit/>}/>
            <Route
                path=":zoneId/edit"
                element={<MaasZoneEdit/>}
                loader={({params}) => params}
                handle={{crumb: ({zoneId}) => ({id: zoneId})}}
            />
            <Route path='layers' element={<LayersListWrapper/>}/>
        </Route>
    )
}
import React from "react";
import {useTranslation} from "react-i18next";
import VerticalTabs from "../../../components/VerticalTabs/VerticalTabs";


const TransitManager = () => {
  const {t} = useTranslation();

  const tabs = [
    {
      url: './',
      title: t('transit-lines.lines'),
      path: undefined,
      testId: 'linesVerticalTab'
    },
    {
      url: './stops',
      title: t('transit-lines.stops'),
      path: 'stops',
        testId: 'stopsVerticalTab'
    },
    {
      url: './layers',
      title: t('transit-lines.layers'),
      path: 'layers',
      testId: 'layersVerticalTab'
    },
  ];

    return <VerticalTabs tabs={tabs} />;
}

export default TransitManager;
import {useControl} from "react-map-gl";
import {MapContextValue} from "react-map-gl/dist/esm/components/map";
import {ControlPosition} from "react-map-gl/src/types";
import {MapboxGeneralControl} from "../MapControls/MapGeneralControl";
import './coordinates-control.scss';


type Props = {
    className?: string;
    title?: string;
    position: ControlPosition;
    eventHandler?: any;
}

const MapCoordinatesControl = ({className, title, position, eventHandler}: Props) => {
    useControl<any>(
        ({map}: MapContextValue) => {
            return new MapboxGeneralControl({
                className,
                title,
                eventHandler
            })
        },
        {
            position
        }
    );

    return null;
}

export default MapCoordinatesControl;
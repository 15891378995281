import {IconProps} from "../icons";


export const ExitIcon = ({width = 16, height = 16, color = "#6F3BB1"}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6667 14H6.66667C5.93029 14 5.33333 13.403 5.33333 12.6667V10H6.66667V12.6667H12.6667V3.33333H6.66667V6H5.33333V3.33333C5.33333 2.59695 5.93029 2 6.66667 2H12.6667C13.403 2 14 2.59695 14 3.33333V12.6667C14 13.403 13.403 14 12.6667 14ZM8 10.6667V8.66667H2V7.33333H8V5.33333L11.3333 8L8 10.6667Z"
        fill={color}
      />
    </svg>
  )
}
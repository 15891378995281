export enum MapboxLayerType {
  NETWORK = "NETWORK",
  STOPS = "STOPS",
  LINES = "LINES",
  VEHICLES = "VEHICLES",
  CLICKEDSTOPS = "CLICKEDSTOPS",
  CLICKEDLINES = "CLICKEDLINES",
  POINT = 'POINT',
  LINE = 'LINE',
  LINESTRING = 'LINESTRING',
  POLYGON = 'POLYGON'
}

export enum DeckLayerType {
  ARC = 'ARC',
  TRIPS = 'TRIPS'
}

export enum RouteLegType {
  WALK = "WALK",
  PT = "PT",
}

export enum EntityServiceName {
  MAP_CONFIG = 'map-config',
  PUBLIC_TRANSIT = 'replancity_PublicTransitService',
  ROAD_INFRASTRUCTURE = 'replancity_RoadInfrastructureService',
  MAAS = 'replancity_MaasService',
  EV = 'replancity_EvService',
  SCENARIO = 'replancity_CaseScenarioService'
}

import React, {ChangeEvent} from "react";
import * as SelectBox from "../SelectBox/SelectBox";
import {Option} from "../SelectBox/SelectBox";
import {PublicTransitVehicleType} from "../../../api/entities/replancity_PublicTransitLine";


type Props = {
    options: Option[]; // MetadataPropertyOptionEntity[];
    label: string;
    name: string;
    value: Pick<PublicTransitVehicleType, 'id' | 'name'>;
    onChange?: (event: ChangeEvent<HTMLElement>) => void;
    alertLabel?: string;
    required?: boolean;
    visible?: boolean;
};

const PublicTransitVehicleTypeField = ({
                                           options,
                                           label,
                                           name,
                                           alertLabel,
                                           value,
                                           onChange,
                                           required = false,
                                           visible = true
                                       }: Props) => {
    return (
        <SelectBox.Labeled
            label={label}
            alertLabel={alertLabel}
            name={name}
            options={options}
            value={value?.id}
            required={required}
            onChange={onChange}
            visible={visible}
        />
    )
}

export default PublicTransitVehicleTypeField;
import React, {memo} from "react";
import {StyledSlider} from "../../form/SliderField/SliderField";
import {useTripsLayerContext} from "../../../context/tripsLayerContext";


const TripsLayerTrailLengthSlider = () => {
    const {trailLength, updateTrailLength} = useTripsLayerContext();

    return (
        <StyledSlider
            value={trailLength}
            valueLabelDisplay="auto"
            step={10}
            min={10}
            max={200}
            onChange={updateTrailLength}
        />
    )
}

export default memo(TripsLayerTrailLengthSlider);
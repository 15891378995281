import React, {useState} from "react";
import {
  Button,
  Divider,
  FormControl, FormHelperText,
  Grid, IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {themeColors} from "../../styles/theme";
import {useTranslation} from "react-i18next";
import {CircledLockIcon} from "../../components/icons/icons/CircledLockIcon";
import './new-password-page.scss'
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import {AuthPagesWrapper} from "../../components/AuthPagesWrapper/AuthPagesWrapper";
import PasswordResetFinishedPage from "../ResetPasswordFinishedPage/PasswordResetFinishedPage";
import BackToSignInButton from "../../components/BackToSignInButton/BackToSignInButton";


type FormValuesType = {
  password: string;
  repeatPassword: string;
}

export const NewPasswordPage = () => {
  const [showPassword, setPasswordVisibility] = useState<boolean>(false);
  const [showPasswordRepeat, setPasswordRepeatVisibility] = useState<boolean>(false);
  const [passwordUpdated, setPasswordUpdated] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    register,
    getValues,
    formState: {errors},
  } = useForm<FormValuesType>();
  const {t} = useTranslation();

  const onSubmit: SubmitHandler<FormValuesType> = async ({password}) => {
    //TODO add request
    // await runLogin(email, password)

    setPasswordUpdated(true);
  };

  const handleClickShowPassword = () => {
    setPasswordVisibility(prevState => !prevState);
  };

  const handleClickShowPasswordRepeat = () => {
    setPasswordRepeatVisibility(prevState => !prevState);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  if (passwordUpdated) {
    return <PasswordResetFinishedPage/>;
  }

  return (
    <AuthPagesWrapper showLogoColumn={false}>
      <div className="new-password-page__row new-password-page__icon">
        <CircledLockIcon/>
      </div>
      <h1 className="new-password-page__row new-password-page__title">
        {t('new-password-page.title')}
      </h1>
      <p className="new-password-page__row new-password-page__description">
        {t('new-password-page.description')}
      </p>
      <Grid item xs={12} sm={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="password"
            control={control}
            rules={{required: true}}
            render={({field, fieldState, formState}) =>
              <FormControl sx={{my: 1, width: '100%'}} variant="outlined" required>
                <InputLabel htmlFor="password">{t('new-password-page.password')}</InputLabel>
                <OutlinedInput
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={field.onChange}
                  inputRef={register('password') as any}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t('new-password-page.password')}
                  sx={{background: themeColors.white}}
                />
              </FormControl>
            }
          />
          <Controller
            name="repeatPassword"
            control={control}
            rules={{required: true}}
            render={({field, fieldState, formState}) =>
              <FormControl sx={{my: 1, width: '100%'}} variant="outlined" required>
                <InputLabel htmlFor="repeat-password">{t('new-password-page.repeat-password')}</InputLabel>
                <OutlinedInput
                  id="repeat-password"
                  type={showPasswordRepeat ? 'text' : 'password'}
                  onChange={field.onChange}
                  error={!!errors.repeatPassword}
                  inputRef={
                    register('repeatPassword', {
                      validate: value => value === getValues("password")
                    }) as any
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPasswordRepeat}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPasswordRepeat ? <VisibilityOff/> : <Visibility/>}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t('new-password-page.repeat-password')}
                  sx={{background: themeColors.white}}
                />
                {
                  !!errors.repeatPassword &&
                    <FormHelperText error id="accountId-error">
                      {t('new-password-page.match-error')}
                    </FormHelperText>
                }
              </FormControl>
            }
          />
          <Divider sx={{mt: 4}}/>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{my: 2}}
          >
            <BackToSignInButton title={t('reset-password-form.back-to-login-link')} />
            <Button
              type="submit"
              variant="contained"
              // sx={{mt: 3, mb: 2}}
              style={{background: themeColors.purple}}
            >
              {t('new-password-page.button')}
            </Button>
          </Grid>
        </form>
      </Grid>
    </AuthPagesWrapper>
  );
}
import React from "react";
import {Row} from "../../../../components/form/Form/Form";
import * as TextField from "../../../../components/form/TextField/TextField";
import {useTranslation} from "react-i18next";
import {PublicTransitScheduleElementEntity} from "../../../../api/entities/replancity_PublicTransitLine";


type Props = {
    entity: PublicTransitScheduleElementEntity
};

const DepartureForm = ({entity: {startTime, endTime, interval, type}}: Props) => {
  const {t} = useTranslation();

  return (
    <Row>
      <TextField.default
        name="startTime"
        value={startTime}
        label={t('transit-lines.departure-from')}
        type="time"
        step="1"
        required={true}
        style={{width: 'maxContent'}}
        linkable
      />
      <TextField.default
        name="endTime"
        value={endTime}
        label={t('transit-lines.departure-to')}
        type="time"
        step="1"
        disabled={type === 'DEPARTURE'}
        required={true}
        linkable
      />
      <TextField.default
        name="interval"
        value={interval}
        label={t('transit-lines.departure-interval')}
        type="number"
        required={true}
        linkable
      />
    </Row>
  );
}

export default DepartureForm;
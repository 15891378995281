import React from "react";
import {Route} from "react-router-dom";
import TransitRouteStopsList from "./TransitManagement/TransitRouteStopsList/TransitRouteStopsList";
import TransitRouteEdit from "./TransitManagement/TransitRoutes/TransitRouteEdit";
import TransitRouteDeparturesList from "./TransitManagement/TransitRouteDeparturesList/TransitRouteDeparturesList";
import DepartureEdit from "./TransitManagement/Departures/DepartureEdit";
import TransitLinesList from "./TransitManagement/TransitLines/TransitLinesList";
import TransitLineEdit from "./TransitManagement/TransitLines/TransitLineEdit";
import TransitLine from "./TransitManagement/TransitLines/TransitLine";
import TransitRoutesList from "./TransitManagement/TransitRoutes/TransitRoutesList";
import TransitRoute from "./TransitManagement/TransitRoutes/TransitRoute";
import PublicTransitEditorModePage from "./PublicTransitEditorModePage";
import {useTransitStopsRoutes} from "./TransitManagement/TransitStops/Routes";
import LayersListWrapper from "../../containers/map/tools/LayersList/LayersListWrapper";
import LayersTimeSelectors from "../../components/map/LayersTimeSelectors";
import BreadcrumbedComponent from "../../components/BreadcrumbedComponent/BreadcrumbedComponent";


export const useTransitRoutes = () => {
    return (
        <Route path="transit" element={<PublicTransitEditorModePage/>}>
            {useTransitStopsRoutes()}
            <Route
                element={<BreadcrumbedComponent/>}
                handle={{crumb: (data: any) => ({name: 'Lines', ...data})}}
            >
                <Route index element={<TransitLinesList/>}/>
                <Route path="new" element={<TransitLineEdit/>}/>
                <Route
                    path=":lineId"
                    element={<TransitLine/>}
                    loader={({params}) => params}
                    handle={{crumb: ({lineId}) => ({id: lineId})}}
                >
                    <Route index element={<TransitRoutesList/>}/>
                    <Route path="edit" element={<TransitLineEdit/>}/>
                    <Route
                        path="new"
                        element={<TransitRouteEdit/>}
                        handle={{crumb: (data: any) => ({name: 'New route', ...data})}}
                    />
                    <Route path=":routeId"
                           element={<TransitRoute/>}
                           loader={({params}) => params}
                           handle={{crumb: ({routeId}) => ({id: routeId})}}
                    >
                        <Route index element={<TransitRouteStopsList/>}/>
                        <Route path=":editMode" element={<TransitRouteEdit/>}/>
                        <Route path="departures" element={<TransitRouteDeparturesList/>}/>
                        <Route path="departures/new" element={<DepartureEdit/>}/>
                        <Route path="departures/:departureId/edit" element={<DepartureEdit/>}/>
                    </Route>
                </Route>
            </Route>
            <Route
                path='layers'
                element={
                    <LayersListWrapper>
                        <LayersTimeSelectors/>
                    </LayersListWrapper>
                }
            />
        </Route>
    )
}
import {IconProps} from "../icons";
import {themeColors} from "../../../styles/theme";


export const CircledCheckIcon = ({width = 56, height = 56, color = themeColors.grey}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" r="28" fill="#BFEBD7" fill-opacity="0.4"/>
      <circle cx="28" cy="28" r="20" fill="#BFEBD7"/>
      <path d="M40.0001 21.3333L24.0001 37.3333L16.6667 30L18.5467 28.12L24.0001 33.56L38.1201 19.4533L40.0001 21.3333Z"
            fill="#3A6754"/>
    </svg>
  )
}

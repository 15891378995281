import React, {useState} from "react";
import {FormControl, MenuItem, Select} from "@mui/material";
import {ProjectEntity} from "../../api/entities";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectProjects} from "../../redux/selectors/selectors";
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";
import {themeColors} from "../../styles/theme";
import {useTheme} from "../../context/themeContext";


type Props = {
  compareToProjectFn: (projectId: string) => void;
}

const ProjectComparison = ({compareToProjectFn}: Props) => {
  const [project, setProject] = useState<string>('');
  const projects: Array<ProjectEntity> = useTypedSelector(selectProjects);
  const {theme} = useTheme();

  const textColor = theme === 'light' ? themeColors.navyBlue : themeColors.white;
  const selectBackground = theme === 'light' ? themeColors.white : themeColors.grey4;

  const onProjectSelect = (event: SelectChangeEvent) => {
    setProject(event.target.value);
    compareToProjectFn(event.target.value);
  }

  return (
    <FormControl fullWidth>
      <Select
        value={project}
        onChange={onProjectSelect}
        MenuProps={{
          PaperProps: {
            sx: {
              background: selectBackground,
              color: textColor,
            },
          },
        }}
        sx={{
          background: selectBackground,
          color: textColor,
        }}
      >
        <MenuItem value=""></MenuItem>
        {
          projects.map(project => {
            return (
              <MenuItem
                key={project.id}
                value={project.id}
              >
                {project.projectName}
              </MenuItem>
            )
          })
        }
      </Select>
    </FormControl>
  )
}

export default ProjectComparison;
import React, {useCallback} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../../redux/selectors/selectors";
import EntityEditorWrapper from "../../../components/Entities/EntityEditorWrapper";
import EntityEditor from "../../../components/Entities/EntityEditor";
import {CHARGER_PROPERTY_ENTITYNAME} from "../../../api/entities/replancity_ChargerProperty";


const ChargerEdit = () => {
    const entityName = CHARGER_PROPERTY_ENTITYNAME;
    const {chargerId} = useParams<any>();
    const stopsLayerId = useTypedSelector((state) => selectMapLayerIdByEntityName(state, entityName));
    const navigate = useNavigate();

    const onSaveFn = useCallback((entity: any) => {
        if (!chargerId) {
            navigate(`../${entity.id}/edit`, {relative: 'path'});
        }
    }, [chargerId]);

    const onDeleteFn = useCallback((entity: any) => {
        navigate('../');
    }, []);

    return (
        <EntityEditorWrapper entityName={entityName}>
            <EntityEditor
                entityId={chargerId!}
                entityName={entityName}
                layerId={stopsLayerId}
                presetProperty={'evPreset'}
                onSaveFn={onSaveFn}
                onDeleteFn={onDeleteFn}
            />
        </EntityEditorWrapper>
    )
}

export default ChargerEdit;
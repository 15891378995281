import React from "react";
import {useTheme} from "../context/themeContext";

type Props = {
  leftFields?: React.ReactNode;
  rightFields?: React.ReactNode;
}

const SearchHeader: React.FC<Props> = function ({leftFields, rightFields}) {
  const {theme} = useTheme();

  return (
    <div className={`header-search ${theme}`}>
      <div className="header-search__left">
        {leftFields}
      </div>
      <div className="header-search__right">
        {rightFields}
      </div>
    </div>
  );
}

export default SearchHeader;

import {useEffect, useRef, useState} from "react";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId} from "../../redux/selectors/selectors";
import {isErrorResponse} from "../../utils/utils";
import {useDispatch} from "react-redux";
import {projectsApi} from "../../api/projectsApi";
import {RunState, RunTaskEntity} from "../../api/entities/replancity_RunTask";
import {addedRuns, setSelectedRunId} from "../../redux/reducers/projectsReducer";


export type Run = {
    id: string;
    title: string;
    state: RunState;
};

export const useRunsLoader = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const projectId = useTypedSelector(selectCurrentProjectId);
    const dispatch = useDispatch();
    const abortControllerRef = useRef(new AbortController());

    const loadRuns = async () => {
        const resp: RunTaskEntity[] = await projectsApi.getRuns({
            projectId,
            abortSignal: abortControllerRef.current.signal
        });

        if (!isErrorResponse(resp)) {
            const options: Run[] = resp.map(({id, name, state}) => ({
                id,
                title: name,
                state,
            }));

            dispatch(addedRuns(options));
            if (resp.length) {
                const {id} = resp[0];
                dispatch(setSelectedRunId(id));
            }
        }
    }

    useEffect(() => {
        if (projectId) {
            (async function () {
                setLoading(true);
                await loadRuns();
                setLoading(false);
            }());
        }

        return () => {
            dispatch(setSelectedRunId());
            dispatch(addedRuns());
        }
    }, [projectId])

    return {loading, loadRuns};
}
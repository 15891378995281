import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import SelectionStepper from "./SelectionStepper";
import Typography from "@mui/material/Typography";
import {useTheme} from "../../../../context/themeContext";
import {RoadInfrastructureSegmentIdPairEntity} from "../../../../api/entities/replancity_RoadInfrastructureTollPair";
import {removeEntity} from "../../../../redux/entity/entity-reducer";
import GroupSelectionSearchForm from "./GroupSelectionSearchForm";
import GroupSelectionSelectedEntityForm from "./GroupSelectionSelectedEntityForm";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectDrawnFeatures} from "../../../../redux/selectors/selectors";
import {FeatureProperties} from "../../../../redux/map/types";
import {useLayersInteractivity} from "../../../../hooks/map/useLayersInteractivity";


const GroupSelection = () => {
    const entityName = 'replancity_RoadInfrastructureSegment';
    const [activeStep, setActiveStep] = useState<number>(0);
    const [selectedSegments, setSelectedSegments] = useState<RoadInfrastructureSegmentIdPairEntity[]>([]);
    const drawnFeatures = useTypedSelector(selectDrawnFeatures) as GeoJSON.Feature<GeoJSON.LineString | GeoJSON.Polygon, Omit<FeatureProperties, 'width' | 'color'>>[];
    const {setDrawing} = useLayersInteractivity();

    const {theme} = useTheme();
    const {t} = useTranslation();
    const dispatch = useDispatch();


    useEffect(() => {
        return () => {
            dispatch(removeEntity(entityName));
            setDrawing(false);
        }
    }, []);

    useEffect(() => {
        if (selectedSegments.length && drawnFeatures.length) {
            setActiveStep(1);
        } else {
            setActiveStep(0);
        }
    }, [selectedSegments, drawnFeatures]);

    return (
        <div className={`entity-editor ${theme}`}>
            <div className="entity-editor__header">
                <Typography
                    sx={{fontSize: '14px'}}
                >
                    Group selection
                </Typography>
            </div>
            <SelectionStepper
                activeStep={activeStep}
                setActiveStepFn={setActiveStep}
                onStepChangeFn={() => setSelectedSegments([])}
            />
            {
                selectedSegments.length
                    ? <p>{t('road-network.selected-nth-items', {numItems: selectedSegments.length})}</p>
                    : null
            }
            {
                activeStep === 0
                    ? <GroupSelectionSearchForm setSelectedSegments={setSelectedSegments}/>
                    : <GroupSelectionSelectedEntityForm
                        entityName={entityName}
                        selectedSegments={selectedSegments}
                    />
            }
        </div>
    )
}

export default GroupSelection;
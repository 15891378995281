import React from "react";
import {EntitiesListRow, EntitiesListRowProps} from "../EntitiesListRow";
import './entities-list.scss';
import {BaseEntityNameLess} from "../../../api/entities/BaseEntity";
import {LoadingBackdrop} from "../../LoadingBackdrop/LoadingBackdrop";


type Props<T extends BaseEntityNameLess> = {
    loading?: boolean;
    entities: T[];
    selectedId?: string;
} & Omit<EntitiesListRowProps<T>, 'entity' | 'selected'>;

const EntitiesList = <T extends BaseEntityNameLess>({
                                                        loading = false,
                                                        entities,
                                                        form,
                                                        onRowClick,
                                                        deleteFn,
                                                        getUrlFunc,
                                                        selectedId,
                                                        hasLink
                                                    }: Props<T>) => {

    // const refs = useMemo(() => entities.reduce((acc, entity) => {
    //     if (entity['id']) {
    //         acc[entity.id] = React.createRef();
    //     }
    //     return acc;
    // }, {}), [entities]);

    // useEffect(() => {
    //     if (selectedId && refs[selectedId].current) {
    //         refs[selectedId].current.scrollIntoView({
    //             behavior: 'smooth',
    //             block: 'start',
    //         });
    //     }
    // }, [selectedId]);

    return (
        <div className="entities-list" data-testid="entitiesList">
            <LoadingBackdrop isVisible={loading} transparent>
                {
                    entities?.map(entity =>
                        <EntitiesListRow
                            key={entity.id}
                            entity={entity}
                            deleteFn={deleteFn as any}
                            onRowClick={onRowClick as any}
                            form={form}
                            selected={entity.id === selectedId}
                            // ref={refs[entity.id]}
                            getUrlFunc={getUrlFunc as any}
                            hasLink={hasLink}
                        />
                    )
                }
            </LoadingBackdrop>
        </div>
    )
}

export default EntitiesList;
import {useEffect} from "react";
import {removeEntity} from "../../redux/entity/entity-reducer";
import {useDispatch} from "react-redux";
import {useLayersInteractivity} from "../../hooks/map/useLayersInteractivity";


type Props = {
    children: React.ReactElement;
    entityName: string;
}

const EntityEditorWrapper: React.FC<Props> = ({children, entityName}) => {
    const {setDrawing} = useLayersInteractivity();
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(removeEntity(entityName));
            setDrawing(false);
        }
    }, []);

    return (
        <>
            {children}
        </>
    )
}

export default EntityEditorWrapper;
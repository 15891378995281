import React from "react";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import {NavLink} from "react-router-dom";
import {TabOwnProps, TabProps} from "@mui/material/Tab/Tab";
import Tooltip from "@mui/material/Tooltip";


export type LeftTabs = {
    path: string;
    label?: string;
    url: string;
} & TabOwnProps

type Props = {
    tabs: TabProps<any, LeftTabs>[];
}

const IconTabs = ({tabs}: Props) => {
    return (
        <ToggleButtonGroup exclusive>
            {
                tabs?.map(({label, icon, ...props}, ind) =>
                    <Tooltip
                        key={ind}
                        title={label ?? ''}
                        placement="bottom"
                    >
                        <span data-testid={props.testId}>
                            <NavLink
                                to={props.url}
                                className={({isActive, isPending}) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }

                            >
                                {({isActive, isPending}) => (
                                    <ToggleButton
                                        value={ind}
                                        selected={isActive}
                                    >
                                        {icon}
                                    </ToggleButton>
                                )}
                            </NavLink>
                        </span>
                    </Tooltip>
                )
            }
        </ToggleButtonGroup>
    );
}

export default IconTabs;
import React from "react";
import {Row} from "../../../components/form/Form/Form";
import * as TextField from "../../../components/form/TextField/TextField";
import {ChargerPropertyEntity} from "../../../api/entities/replancity_ChargerProperty";


type Props = {
    entity: ChargerPropertyEntity;
}

const EvEntityRowForm = ({entity}: Props) => {
    return (
        <Row>
            <TextField.default
                name="name"
                type="text"
                value={entity?.name ?? entity?.id}
                linkable
            />
        </Row>
    )
}

export default EvEntityRowForm;
import * as React from 'react';
import './side-panel.scss'
import {useTheme} from "../../context/themeContext";
import {IconButton} from "../buttons/IconButton/IconButton";
import {CloseIcon} from "../icons/icons/CloseIcon";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Tab, Tabs} from "@mui/material";
import TabPanel from '../TabPanel/TabPanel';
import {TabProps} from "@mui/material/Tab/Tab";
import {styled} from '@mui/material/styles';
import {themeColors} from "../../styles/theme";


export interface SidepanelProps {
  open: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  tabs?: SidePanelTab[];
  selectedTabInd?: number;
  sideToolbars?: any;
}

export type SidePanelTab = {
  content: React.ReactElement | null;
  disabled?: boolean;
} & TabProps;

interface StyledTabProps {
  label: React.ReactNode;
  disabled?: boolean;
  theme?: any;
}

export const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({theme}) => {
  return {
    color: theme === 'light' ? themeColors.navyBlue : themeColors.white,
    '&.Mui-disabled': {
      color: theme === 'light' ? themeColors.navyBlue : themeColors.white,
      opacity: 0.6
    }
  }
});

const SidePanel = ({
                     open,
                     setOpened,
                     tabs,
                     selectedTabInd = 0,
                     sideToolbars
                   }: SidepanelProps) => {
  const [isOpen, setOpen] = useState<boolean>(open);
  const {theme} = useTheme();
  const [value, setValue] = useState(selectedTabInd);

  useEffect(() => {
    setValue(selectedTabInd)
  }, [selectedTabInd])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setOpen(open);
  }, [open])

  const hidePanel = () => {
    setOpened(false);
  }

  return (
    <div className={`sidebar ${theme} ${isOpen ? 'open' : ''}`}>
      <div className="sidebar__toolbars">
        {sideToolbars}
      </div>
      {isOpen && (
        <>
          <div className={`sidebar__header ${theme}`}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              {
                tabs?.map(({label, disabled}, ind) =>
                  <StyledTab
                    key={ind}
                    label={label}
                    disabled={!!disabled}
                    theme={theme as any}
                  />
                )
              }
            </Tabs>
            <IconButton
              icon={CloseIcon}
              onClick={hidePanel}
            />
          </div>
          <div className={`sidebar__content ${theme}`}>
            {
              tabs?.map((tab, ind) =>
                <TabPanel
                  key={ind}
                  index={ind}
                  value={value}
                >
                  {tab.content}
                </TabPanel>
              )
            }
          </div>
        </>
      )}
    </div>
  )
}

export default React.memo(SidePanel);
import React, {memo, useCallback, useState} from "react";
import {IconButton} from "../../buttons/IconButton/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {useTranslation} from "react-i18next";
import {SimulationState} from "../../../api/entities/replancity_RunnedAlgorithm";
import {CloseIcon} from "../../icons/icons/CloseIcon";
import {projectsApi} from "../../../api/projectsApi";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectSelectedRunId} from "../../../redux/selectors/selectors";
import {createPortal} from "react-dom";
import Modal from "../../Modal/Modal";
import {themeColors} from "../../../styles/theme";
import {LoadingBackdrop} from "../../LoadingBackdrop/LoadingBackdrop";
import Button from "../../buttons/Button";
import {useTheme} from "../../../context/themeContext";


const InterruptSimulationButton = ({simulationState}: { simulationState: SimulationState }) => {
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [interrapting, setInterrapting] = useState<boolean>(false);

    const selectedRunId = useTypedSelector(selectSelectedRunId);
    const {t} = useTranslation();
    const {theme} = useTheme();

    const showModal = useCallback(async () => {
        setModalVisible(true);
    }, [])

    const interruptSimulation = useCallback(async (runId: string) => {
        setInterrapting(true);
        const resp = await projectsApi.interruptSimulation({runId});
        setInterrapting(false);
        setModalVisible(false);
    }, [])

    return (
        <>
            <Tooltip title={t('simulation.cancel-simulation-tooltip')}>
                <IconButton
                    icon={CloseIcon}
                    width={20}
                    height={20}
                    onClick={showModal}
                    disabled={
                        simulationState === SimulationState.NEVER
                        || simulationState === SimulationState.COMPLETE
                        || simulationState === SimulationState.CANCELED
                        || simulationState === SimulationState.RESTARTING
                        || simulationState === SimulationState.TERMINATING
                        || simulationState === SimulationState.ERROR
                    }
                    borderless
                />
            </Tooltip>
            {modalVisible && createPortal(
                <Modal
                    title={t('simulation.cancel-simulation-tooltip')}
                    setVisible={setModalVisible}
                    style={{
                        color: theme === 'light' ? themeColors.navyBlue : themeColors.white
                    }}
                >
                    <LoadingBackdrop isVisible={interrapting} transparent>
                        <div className="cancel-simulation-modal" style={{display: 'flex', gap: '10px'}}>
                            <Button
                                onClick={() => setModalVisible(false)}
                                text={t("add-project-popup.cancel")}
                                colorType="transparent"
                            />
                            <Button
                                onClick={() => interruptSimulation(selectedRunId)}
                                text={t("add-project-popup.continue")}
                                colorType="dark"
                            />
                        </div>
                    </LoadingBackdrop>
                </Modal>,
                document.getElementById('root') as any)
            }
        </>
    );
}

export default memo(InterruptSimulationButton);
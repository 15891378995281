import {IconProps} from "../icons";
import {themeColors} from "../../../styles/theme";


export const AlertRhombusIcon = ({width = 16, height = 16, color = themeColors.grey}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0001 2C11.5001 2 11.0001 2.19 10.5901 2.59L2.59006 10.59C1.80006 11.37 1.80006 12.63 2.59006 13.41L10.5901 21.41C11.3701 22.2 12.6301 22.2 13.4101 21.41L21.4101 13.41C22.2001 12.63 22.2001 11.37 21.4101 10.59L13.4101 2.59C13.0001 2.19 12.5001 2 12.0001 2ZM12.0001 4L20.0001 12L12.0001 20L4.00006 12L12.0001 4ZM11.0001 7V13H13.0001V7H11.0001ZM11.0001 15V17H13.0001V15H11.0001Z"
        fill={color}/>
    </svg>
  )
}

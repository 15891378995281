import React from "react";
import LayersList from "./LayersList";


const LayersListWrapper: React.PropsWithChildren<any> = ({children}) => {
  return (
    <>
      {children}
      <LayersList/>
    </>
  )
}

export default LayersListWrapper;
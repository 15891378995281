import {BaseEntity, BaseEntityNameLess} from "./BaseEntity";

export const enum RunState {
    NEVER = 'NEVER',
    IN_PROGRESS = 'IN_PROGRESS',
    TERMINATING = 'TERMINATING',
    COMPLETE = 'COMPLETE',
    ERROR = 'ERROR',
    OUTDATED = 'OUTDATED',
    CANCELED = 'CANCELED',
    POSTANALYSIS = 'POSTANALYSIS'
}

export type RunTaskEntity = {
    maxIteration: number;
    state: RunState;
    results: {
        currentIteration: number;
    } & BaseEntityNameLess
} & BaseEntity;
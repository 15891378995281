import React, {ComponentType, MutableRefObject, useEffect, useState} from "react";
import {IconProps, IconWrapper} from "../../icons/icons";
import './icon-button.scss'
import classNames from "classnames";
import {useTheme} from "../../../context/themeContext";
import {themeColors} from "../../../styles/theme";

export type IconButtonProps = {
  className?: string;
  icon: ComponentType<IconProps>;
  iconColor?: string
  iconColorOnHover?: string;
  backgroundColor?: string;
  borderless?: boolean;
  height?: number;
  width?: number;
  isActive?: boolean;
  onClick?: any;
  disabled?: boolean;
  ref?: MutableRefObject<any>;
  testId?: string;
}

export const IconButton = React.memo(React.forwardRef(({
                                                         className,
                                                         icon,
                                                         iconColor,
                                                         iconColorOnHover = iconColor,
                                                         backgroundColor,
                                                         borderless = false,
                                                         height,
                                                         width,
                                                         isActive = false,
                                                         onClick,
                                                         disabled,
                                                           testId,
                                                        ...props
                                                       }: IconButtonProps, ref) => {
  const {theme} = useTheme();
  const [active, setActive] = useState<boolean>(isActive);
  const [isHover, setIsHover] = useState<boolean>(false);

  const themeBgColor = theme === 'light' ? themeColors.white: themeColors.navyBlue;

  const btnClassName = classNames(
    className && className,
    theme,
    'icon-button',
    active && 'pressed',
    borderless && 'borderless'
  )

  useEffect(() => {
    setActive(false);
  }, [icon])

  useEffect(() => {
    setActive(isActive);
  }, [isActive])

  const handleClick = (event): void => {
    if (!isActive) {
      setActive(active => !active);
    }
    onClick?.(event);
  }

  const handlePointer = () => {
    if (!disabled) {
      setIsHover(isHover => !isHover);
    }
  }

  return (
    <button
      onClick={handleClick}
      className={btnClassName}
      onPointerOver={handlePointer}
      onPointerOut={handlePointer}
      type="button"
      disabled={disabled}
      ref={ref as any}
      style={{
        backgroundColor: backgroundColor ?? themeBgColor
      }}
      {...(testId ? {'data-testid': testId} : {})}
      {...props}
    >
      <IconWrapper
        icon={icon}
        height={height}
        width={width}
        color={isHover ? iconColorOnHover : iconColor}
        backgroundColor={backgroundColor}
      />
    </button>
  )
}))
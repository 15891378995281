import React from "react";
import {useTranslation} from "react-i18next";
import VerticalTabs from "../../../components/VerticalTabs/VerticalTabs";


const ScenarioEventsManager = () => {
    const {t} = useTranslation();

    const tabs = [
        {
            url: './',
            title: t('scenario-case-events.population-events-tab'),
            path: undefined,
            testId: 'populationEventsVerticalTab'
        },
        {
            url: './network',
            title: t('scenario-case-events.network-events-tab'),
            path: 'network',
            testId: 'networkEventsVerticalTab'
        },
        {
            url: './transit',
            title: t('scenario-case-events.transit-events-tab'),
            path: 'transit',
            testId: 'transitEventsVerticalTab'
        },
        {
            url: './layers',
            title: t('scenario-case-events.layers-tab'),
            path: 'layers',
            testId: 'layersVerticalTab'
        },
    ];

    return <VerticalTabs tabs={tabs}/>;
}

export default ScenarioEventsManager;
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import React from "react";
import {Layer} from "react-map-gl"
import {selectMapboxLayerById, selectMapboxLayersById} from "../../../redux/selectors/selectors";
import {NamedLayer} from "../../../redux/map/types";


type LayerWrapperProps = {
  layerId: string
}

const LayerWrapper = ({layerId}: LayerWrapperProps) => {
  const layer: NamedLayer = useTypedSelector(state => selectMapboxLayerById(state, layerId));

  return <Layer {...layer} />
}

const MemoLayer = React.memo(LayerWrapper)

const MapboxGlLayers = () => {
  const layersById = useTypedSelector(selectMapboxLayersById);

  return <>
    {
      Object.entries(layersById).map(([id]) =>
        <MemoLayer key={id} layerId={id}/>
      )
    }
  </>
}

export default MapboxGlLayers
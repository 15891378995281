import mapboxgl, {IControl, MapMouseEvent} from "mapbox-gl";

export class MapboxGeneralControl implements IControl {
    private readonly _className: string;
    private readonly _title: string;
    private readonly _eventHandler: (event: any) => void;
    private _elem: HTMLElement | undefined;
    private _container: HTMLDivElement | undefined;

    constructor({
                    className = "",
                    title = "",
                    eventHandler
                }) {
        this._className = className;
        this._title = title;
        this._eventHandler = eventHandler;
    }

    mouseMoveListener = (event: MapMouseEvent) => {
            const {lngLat: {lat, lng}} = event;
            const coords = `<div>lat: ${lat.toFixed(7)}, lon: ${lng.toFixed(7)}</div>`
            this._elem!.innerHTML = coords;
    }

    onAdd(map: mapboxgl.Map) {
        this._elem = document.createElement("div");
        this._elem.className = `mapboxgl-ctrl-icon ${this._className}`;
        this._elem.title = this._title;
        this._elem.onclick = this._eventHandler;

        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this._container.appendChild(this._elem);

        map.on('mousemove', this.mouseMoveListener);

        return this._container;
    }

    onRemove(map: mapboxgl.Map) {
        map.off('mousemove', this.mouseMoveListener);
        this._elem = undefined;
        this._container?.parentNode?.removeChild(this._container);
    }
}
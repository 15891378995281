import React from "react";
import SimulationProgress from "../../components/ProjectSimulation/SimulationProgress/SimulationProgress";
import ProjectPage from "../../pages/ProjectPages/ProjectPage";
import RunSelector from "../../components/RunSelector/RunSelector";
import {useRunsLoader} from "../../hooks/projects/useRunsLoader";
import {LoadingComponent} from "../../components/LoadingComponent/LoadingComponent";
import MapContainerWithSources from "../../containers/map/MapContainer/MapContainerWithSources";
import SidePanelManager from "../../components/LayersManager/SidePanelManager";
import LayersTimeSelectors from "../../components/map/LayersTimeSelectors";
import LayersListWrapper from "../../containers/map/tools/LayersList/LayersListWrapper";
import RunsToCompareSelector from "../../components/RunSelector/RunsToCompareSelector";


const LayersModePage = () => {
    const {loading, loadRuns} = useRunsLoader();

    return (
        <ProjectPage
            subHeaderLeft={
                <>
                    <RunSelector/>
                    <SimulationProgress simulationCompletedFn={loadRuns}/>
                    <RunsToCompareSelector/>
                </>
            }
            main={
                <LoadingComponent isLoading={loading}>
                    <MapContainerWithSources/>
                </LoadingComponent>
            }
            leftPanel={
                <LayersListWrapper>
                    <LayersTimeSelectors/>
                </LayersListWrapper>
            }
            // leftPanelTitle={t('map.visible-layers-title')}
            rightPanel={<SidePanelManager/>}
        />
    )
}

export default LayersModePage;
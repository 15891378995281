import React from "react";
import {debounce} from "lodash";
import {SearchIcon} from "../../icons/icons/SearchIcon";
import * as TextField from "../../form/TextField/TextField";
import {useTranslation} from "react-i18next";
import {useTheme} from "../../../context/themeContext";
import LinkWrapper from "../../LinkWrapper/LinkWrapper";
import Button from "../../buttons/Button";
import {PlusIcon} from "../../icons/icons/PlusIcon";
import {themeColors} from "../../../styles/theme";
import './entities-controls.scss';


type Props = {
    searchPhrase?: string;
    onSearch: (searchPhrase: string) => void;
    newEntityUrl?: string;
    showNewButton?: boolean;
    controls?: React.ReactElement;
    disabled?: boolean;
}

const EntitiesControls = ({
                              searchPhrase,
                              onSearch,
                              newEntityUrl,
                              showNewButton = true,
                              controls,
                              disabled
                          }: Props) => {
    const {t} = useTranslation();
    const {theme} = useTheme();

    const searchHandler = debounce(async (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;
        // setSearch(value);
        onSearch?.(value);
    }, 500);

    return (
        <div className="entities-list-row">
            <div className={`entities-list-controls__search ${theme}`}>
                <div className="entities-list-controls__loupe">
                    <SearchIcon/>
                </div>
                <TextField.default
                    name="name"
                    type="text"
                    value={searchPhrase}
                    placeholder={t('common.search')}
                    autoFocus
                    onChange={searchHandler}
                    testId="entitySearchInput"
                    disabled={disabled}
                />
            </div>
            {
                showNewButton ?
                    <div className="entities__new">
                        <LinkWrapper
                            to={newEntityUrl ?? "./new"}
                        >
                            <Button
                                img={<PlusIcon
                                    color={theme === 'light' ? themeColors.bluePurple : themeColors.white}/>}
                                text={t('transit-lines.add-new')}
                                colorType="transparent"
                                testId="addNewEntityBtn"
                            />
                        </LinkWrapper>
                    </div>
                    : null
            }
            {
                controls
            }
        </div>
    )
}

export default EntitiesControls;
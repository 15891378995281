import {IconProps} from "../icons";
import {themeColors} from "../../../styles/theme";


export const CircledLockIcon = ({width = 56, height = 56, color = themeColors.grey}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" r="28" fill="#6F3BB1" fillOpacity="0.1"/>
      <circle cx="28" cy="28" r="20" fill="#6F3BB1" fillOpacity="0.2"/>
      <path
        d="M35.9999 38.6667V25.3333H19.9999V38.6667H35.9999ZM35.9999 22.6667C36.7072 22.6667 37.3854 22.9476 37.8855 23.4477C38.3856 23.9478 38.6666 24.6261 38.6666 25.3333V38.6667C38.6666 39.3739 38.3856 40.0522 37.8855 40.5523C37.3854 41.0524 36.7072 41.3333 35.9999 41.3333H19.9999C18.5199 41.3333 17.3333 40.1333 17.3333 38.6667V25.3333C17.3333 24.6261 17.6142 23.9478 18.1143 23.4477C18.6144 22.9476 19.2927 22.6667 19.9999 22.6667H31.9999V20C31.9999 18.9391 31.5785 17.9217 30.8283 17.1716C30.0782 16.4214 29.0608 16 27.9999 16C26.9391 16 25.9216 16.4214 25.1715 17.1716C24.4213 17.9217 23.9999 18.9391 23.9999 20H21.3333C21.3333 18.2319 22.0356 16.5362 23.2859 15.286C24.5361 14.0357 26.2318 13.3333 27.9999 13.3333C28.8754 13.3333 29.7423 13.5058 30.5511 13.8408C31.36 14.1758 32.0949 14.6669 32.714 15.286C33.333 15.905 33.8241 16.6399 34.1591 17.4488C34.4941 18.2576 34.6666 19.1245 34.6666 20V22.6667H35.9999ZM27.9999 34.6667C27.2927 34.6667 26.6144 34.3857 26.1143 33.8856C25.6142 33.3855 25.3333 32.7073 25.3333 32C25.3333 31.2928 25.6142 30.6145 26.1143 30.1144C26.6144 29.6143 27.2927 29.3333 27.9999 29.3333C28.7072 29.3333 29.3854 29.6143 29.8855 30.1144C30.3856 30.6145 30.6666 31.2928 30.6666 32C30.6666 32.7073 30.3856 33.3855 29.8855 33.8856C29.3854 34.3857 28.7072 34.6667 27.9999 34.6667Z"
        fill="#6F3BB1"/>
    </svg>
  )
}

import React, {
  ChangeEventHandler,
  FormEvent,
  forwardRef,
  ForwardRefExoticComponent,
  InputHTMLAttributes,
  PropsWithChildren,
  RefAttributes
} from "react";
import "./form.scss";
import Button from "../../buttons/Button";


type FormProps = {
  name: string;
  onFormSubmit?: (event: any) => void;
  onChange?: React.FormEventHandler<HTMLFormElement>;
  submitBtnTitle?: string;
  disabled?: boolean;
  submitBtnTestId?: string;
  testId?: string;
};

export type FormFieldProps = {
  label?: string;
  alertLabel?: string;
  required?: boolean;
  hidden?: boolean;
  name: string;
  inline?: boolean;
  value?: any;
  visible?: boolean;
  onChange?: React.ChangeEventHandler<any> | undefined;
  testId?: string;
};

const Row = React.forwardRef((props: React.PropsWithChildren<any>, ref: React.ForwardedRef<HTMLDivElement>) => {
  return <div className="form-edit-row" ref={ref}>{props.children}</div>;
});

const Form: ForwardRefExoticComponent<PropsWithChildren<FormProps> & RefAttributes<HTMLFormElement>> = forwardRef((
  props,
  ref?
) => {
  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    props.onFormSubmit && props.onFormSubmit(event);
  }

  return (
    <form
      name={props.name}
      className="form-edit"
      onSubmit={onSubmit}
      onChange={props.onChange}
      ref={ref}
      {...(props.testId ? {'data-testid': props.testId} : {})}
      // onSubmitCapture={(e) => console.log("onSubmitCapture", e)}
      // onInvalid={(e) => console.log("onInvalid", e)}
      // onInvalidCapture={(e) => console.log("onInvalidCapture", e)}
    >
      <div className="form-edit_body">
        {props.children}
      </div>
      <div>
        <Button
        type="submit"
        text={props.submitBtnTitle || "Submit"}
        disabled={props?.disabled}
        colorType="dark"
        {...(props.submitBtnTestId ? {'testId': props.submitBtnTestId} : {})}
      />
      </div>
    </form>
  );
});

export {Row, Form};

import mapboxgl, {IControl} from "mapbox-gl";

export class MapboxGLButtonControlClass implements IControl {
  private readonly _className: string;
  private readonly _title: string;
  private readonly _icon: SVGElement | undefined;
  private readonly _eventHandler: (event: any) => void;
  private _btn: HTMLButtonElement | undefined;
  private _container: HTMLDivElement | undefined;
  private _map: mapboxgl.Map | undefined;

  constructor({
                className = "",
                title = "",
                icon,
                eventHandler
              }) {
    this._className = className;
    this._title = title;
    this._icon = icon;
    this._eventHandler = eventHandler;
  }

  onAdd(map: mapboxgl.Map) {
    this._btn = document.createElement("button");
    this._btn.className = `mapboxgl-ctrl-icon ${this._className}`;
    this._btn.type = "button";
    this._btn.title = this._title;
    if (this._icon) {
      this._btn.style.backgroundImage = `url(${this._icon})`;
      this._btn.style.position = 'center center';
      this._btn.style.backgroundSize = 'cover';
    }

    this._btn.onclick = (event: MouseEvent) => {
      this._btn?.classList.toggle('mapboxgl-ctrl-icon_active');
      this._eventHandler(event);
    };

    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
    this._container.appendChild(this._btn);

    return this._container;
  }

  onRemove(map: mapboxgl.Map) {
    this._btn = undefined;
    this._container?.parentNode?.removeChild(this._container);
    this._map = undefined;
  }
}
import React from 'react';
import {DeckGLOverlay} from "../../../containers/map/tools/DeckGl/DeckGl";
import {useGetArcLayers} from "../../../hooks/map/useGetArcLayers";
import {useGetTripsLayer} from "../../../hooks/map/useGetTripsLayer";
import TripsLayerControl from "./TripsLayerControl";


const DeckGlLayers = React.memo(() => {
    const {arcLayers} = useGetArcLayers();
    const {tripsLayer} = useGetTripsLayer();

    const deckLayers = [...arcLayers, tripsLayer];

    return (
        <>
            <DeckGLOverlay layers={deckLayers}/>
            <TripsLayerControl/>
        </>
    )
})

export default DeckGlLayers;
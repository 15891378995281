import React, {useCallback, useEffect, useRef} from "react";
import {useEntityLoader, UseEntityLoaderProps} from "../../hooks/entites/useEntityLoader";
import {LoadingComponent} from "../LoadingComponent/LoadingComponent";
import EntityForm from "./EntityForm";
import {isErrorResponse} from "../../utils/utils";
import {useToastContext} from "../../context/toastContext";
import {useTranslation} from "react-i18next";
import {BaseEntity, BaseEntityNameLess} from "../../api/entities/BaseEntity";
import {setEntity} from "../../redux/entity/entity-reducer";
import {useDispatch} from "react-redux";
import UndoLastOperationButton
    from "../../project-modes/RoadNetworkMode/RoadNetworkManager/UndoLastOperationButton/UndoLastOperationButton";
import {setSourceReloadRequired} from "../../redux/map/map-reducer";


//TODO loadByDefault is to avoid entity load in TransitRouteEdit => update architecture
type Props = {
    entityId: string;
    entityName: string;
    layerId: string;
    onSaveFn?: any;
    onDeleteFn?: (resp: any) => void;
    actionButtons?: JSX.Element | JSX.Element[];
    undoLastActionBtn?: boolean;
    loadByDefault?: boolean;
} & UseEntityLoaderProps

const EntityEditor = <T extends BaseEntityNameLess>({
                                                        entityId,
                                                        entityName,
                                                        layerId,
                                                        saveFnArgs,
                                                        presetProperty,
                                                        onSaveFn,
                                                        onDeleteFn,
                                                        actionButtons,
                                                        undoLastActionBtn = false,
                                                        entityView,
                                                        loadByDefault = true
                                                    }: Props) => {
    const {
        loading,
        entity,
        metadata,
        loadData,
        saveFn,
        deleteFn
    } = useEntityLoader<T>({entityName, saveFnArgs, presetProperty});
    const {addToast} = useToastContext();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const abortControllerRef = useRef(new AbortController());

    useEffect(() => {
        if (loadByDefault) {
            (async function () {
                //TODO redirect to list or project if entity doesn't exist
                await loadData({entityName, entityId, entityView});
            })();
        }
    }, [entityName, entityId, entityView, loadByDefault]);

    const changeFormHandler = useCallback((formDataObj: { [key: string]: string | number }) => {
        dispatch(setEntity({entityName, entity: {...entity, ...formDataObj}}));
    }, [entity])

    const saveEntity = useCallback(async (formDataObj: { [key: string]: string | number }) => {
        const {id} = entity ?? {};

        const resp = await saveFn({
            id,
            entityName,
            data: formDataObj,
            abortSignal: abortControllerRef.current.signal
        });

        if (isErrorResponse(resp)) {
            addToast(`${resp.error_description}`, 'error');
        } else {
            addToast(t('common.save-successful'), 'success');
            await loadData({entityName, entityId: id, entityView});
            if (layerId) {
                dispatch(setSourceReloadRequired({sourceId: layerId, isReloadRequired: true}));
            }
            onSaveFn?.(resp);
        }

    }, [entityName, entityId, layerId, entity]);

    const deleteEntity = useCallback(async () => {
        const resp = await deleteFn?.({entity});

        if (isErrorResponse(resp)) {
            addToast(`${resp.error_description}`, 'error');
        } else {
            addToast(t('common.save-successful'), 'success');
            onDeleteFn?.(resp);

            if (layerId) {
                dispatch(setSourceReloadRequired({sourceId: layerId, isReloadRequired: true}));
            }
        }
    }, [entityName, layerId, entity])

    const onLastActionUndo = useCallback(async () => {
        await loadData({entityName, entityId});
    }, [loadData, entityId, entityName]);

    return (
        <LoadingComponent isLoading={loading}>
            <EntityForm
                entity={entity}
                metadata={metadata}
                changeFormFn={changeFormHandler}
                saveFn={saveEntity as any}
                deleteFn={deleteEntity}
                icons={
                    <>
                        {actionButtons}
                        {
                            undoLastActionBtn
                                ? <UndoLastOperationButton onFinishFn={onLastActionUndo}/>
                                : null
                        }
                    </>
                }
            />
        </LoadingComponent>
    )
}

export default EntityEditor;
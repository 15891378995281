import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {dashboardApi} from "../../api/dashboardApi";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId} from "../../redux/selectors/selectors";
import SsSidePanelDashboard from "../SidePanelDashboard/SsSidePanelDashboard";
import {isErrorResponse} from "../../utils/utils";
import {
    DashboardEmbeddingResultEnvelopeEntity
} from "../../api/entities/local/replancity_DashboardEmbeddingResultEnvelope";
import {LoadingComponent} from "../../components/LoadingComponent/LoadingComponent";


const SuperSetDashboard = () => {
    const {runId, dashboardId} = useParams();
    const [dashboard, setDashboard] = useState<DashboardEmbeddingResultEnvelopeEntity>();
    const [loading, setLoading] = useState<boolean>(false);
    const projectId = useTypedSelector(selectCurrentProjectId);
    const abortControllerRef = useRef(new AbortController());

    useEffect(() => {
        if (projectId && runId && dashboardId) {
            (async function () {
                setLoading(true);

                const resp = await dashboardApi.getDashboard({
                    projectId,
                    dashboardId: dashboardId!,
                    runId,
                    abortSignal: abortControllerRef.current.signal
                });

                if (!isErrorResponse(resp)) {
                    setDashboard(resp);
                }

                setLoading(false);
            }());
        }
    }, [projectId, dashboardId, runId]);

    return (
        <LoadingComponent isLoading={loading}>
            <SsSidePanelDashboard data={dashboard}/>
        </LoadingComponent>
    )
}

export default SuperSetDashboard;
import React from "react";
import ProjectPage from "../../pages/ProjectPages/ProjectPage";
import ProjectPresetsSelector from "../../components/ProjectPresetsSelector/ProjectPresetsSelector";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectPresetType, selectSelectedPresetId} from "../../redux/selectors/selectors";
import MaasManager from "./MaasManagement/MaasManager";
import {useTranslation} from "react-i18next";
import MapContainerWithSources from "../../containers/map/MapContainer/MapContainerWithSources";
import {EntityServiceName} from "../../api/enums/enums";
import {useNavigationOnFeaturesSelection} from "../../hooks/transitlLines/useNavigationOnFeaturesSelection";


const MaasModePage = () => {
    const presetEntityName = 'replancity_MaasPreset';
    const presetId = useTypedSelector(selectSelectedPresetId);
    const currentPresetType = useTypedSelector(selectPresetType);
    const {navigateOnFeatureSelection} = useNavigationOnFeaturesSelection();
    const {t} = useTranslation();

    return (
        <ProjectPage
            showSkeleton={!presetId || presetEntityName !== currentPresetType}
            subHeaderLeft={
                <ProjectPresetsSelector
                    presetEntityName={presetEntityName}
                    serviceName={EntityServiceName.MAAS}
                />
            }
            main={
                <MapContainerWithSources
                    mapConfigType={EntityServiceName.MAAS}
                    isDrawerEnabled={true}
                    handleMapClick={navigateOnFeatureSelection}
                />
            }
            leftPanel={<MaasManager/>}
            leftPanelTitle={t('drt.drt-zones-list')}
        />
    )
}

export default MaasModePage;
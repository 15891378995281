import React from 'react';
import {CircledEnvelopeIcon} from "../../components/icons/icons/CircledEnvelopeIcon";
import {Trans, useTranslation} from "react-i18next";
import {Button, Divider} from "@mui/material";
import {themeColors} from "../../styles/theme";
import {Link} from "react-router-dom";
import './check-email-page.scss';
import {AuthPagesWrapper} from "../../components/AuthPagesWrapper/AuthPagesWrapper";
import BackToSignInButton from "../../components/BackToSignInButton/BackToSignInButton";


type Props = {
  email: string;
}

const CheckEmailPage = ({email}: Props) => {
  const {t} = useTranslation();

  return (
    <AuthPagesWrapper showLogoColumn={false}>
      <div className="check-email-page__row check-email-page__icon">
        <CircledEnvelopeIcon/>
      </div>
      <h1 className="check-email-page__row check-email-page__title">
        {t('check-email-page.title')}
      </h1>
      <p className="check-email-page__row check-email-page__description">
        {t('check-email-page.description')}&nbsp;<span className="check-email-page__content_bold">{email}</span>
      </p>
      <p className="check-email-page__row check-email-page__resend-msg">
        <Trans i18nKey="check-email-page.click-to-resend-msg">
          Didn’t receive the e-mail?
          <Link className="check-email-page__content_bold" to="#">Click to resend</Link>
        </Trans>
      </p>
      <Divider/>
      <div className="check-email-page__row check-email-page__buttons">
        <BackToSignInButton title={t('reset-password-form.back-to-login-link')} />
        <Button
          type="submit"
          variant="contained"
          // sx={{mt: 3, mb: 2}}
          style={{background: themeColors.purple}}
        >
          {t('check-email-page.button')}
        </Button>
      </div>
    </AuthPagesWrapper>
  );
}

export default CheckEmailPage;
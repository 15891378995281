import React, {useCallback, useEffect, useMemo, useState} from "react";
import {PresetEntityName, usePresetsLoader} from "../../hooks/projects/usePresetsLoader";
import {useTranslation} from "react-i18next";
import DropDown, {OptionsAlign} from "../DropDown/DropDown";
import {themeColors} from "../../styles/theme";
import {useTheme} from "../../context/themeContext";
import {PlusIcon} from "../icons/icons/PlusIcon";
import {IconButton} from "../buttons/IconButton/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Modal from "../Modal/Modal";
import {Form, Row} from "../form/Form/Form";
import * as TextField from "../form/TextField/TextField";
import * as SelectBox from "../form/SelectBox/SelectBox";
import {Option} from "../form/SelectBox/SelectBox";
import {getFilledFieldsObjOnFormSubmit, isErrorResponse} from "../../utils/utils";
import {LoadingBackdrop} from "../LoadingBackdrop/LoadingBackdrop";
import {useToastContext} from "../../context/toastContext";
import {useDispatch} from "react-redux";
import {setSelectedPreset} from "../../redux/reducers/projectsReducer";
import {DropDownOptionType} from "../DropDown/Option";
import {EntityServiceName} from "../../api/enums/enums";


type Props = {
    presetEntityName: PresetEntityName;
    serviceName: EntityServiceName;
    formFields?: React.ReactNode;
    copySpinnerMsg?: string;
    testId?: string;
}

const ProjectPresetsSelector = ({presetEntityName, serviceName, formFields, copySpinnerMsg, testId}: Props) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const {loading, selectedPresetId, presets, addPreset} = usePresetsLoader({presetEntityName});
    const [presetOptions, setPresetOptions] = useState<DropDownOptionType[]>([]);
    const [selectedOptionIdx, setSelectedOptionIdx] = useState(0);
    const {theme} = useTheme();
    const {t} = useTranslation();
    const {addToast} = useToastContext();
    const dispatch = useDispatch();

    useEffect(() => {
        const options: DropDownOptionType[] = presets.map((preset, index) => {
            return {
                id: preset['id'] ?? '',
                title: preset.title,
                onClick: () => selectPreset(preset.id, presetEntityName)
            }
        });
        setPresetOptions(options);
        setSelectedOptionIdx(presets.findIndex(({id}) => id === selectedPresetId));
    }, [presets, selectedPresetId]);

    const selectPreset = useCallback((id: string, presetEntityName: PresetEntityName) => {
        dispatch(setSelectedPreset({presetId: id, presetType: presetEntityName}));
    }, []);

    const presetsFormFieldOptions: Option[] = useMemo(() => {
        return presets.map(preset => ({value: preset['id'] ?? '', caption: preset.title}));
    }, [presets]);

    const copyBtnClick = () => {
        setShowModal(true);
    }

    const onSubmit = useCallback(async (event: any) => {
        event.preventDefault();

        const formDataObj = getFilledFieldsObjOnFormSubmit(event);
        const resp = await addPreset({serviceName, data: formDataObj as any});

        if (isErrorResponse(resp)) {
            addToast(t('presets.preset-copied-with-error-toast'), 'error');
        } else {
            addToast(t('presets.preset-successfully-copied-toast'), 'success');
        }

        setShowModal(false);
    }, [serviceName, presetEntityName])

    const getModal = (setVisible: (arg: boolean) => void, formFields: React.ReactNode, presetEntityName: PresetEntityName) => {
        return (
            <LoadingBackdrop
                isVisible={loading}
                msg={copySpinnerMsg}
                transparent
            >
                <Modal
                    title={t("presets.add-preset-modal-title")}
                    setVisible={setVisible}
                    testId="clonePresetModal"
                >
                    <Form
                        name="presetsClone"
                        onFormSubmit={onSubmit}
                        submitBtnTestId="clonePresetFormSubmitBtn"
                    >
                        <Row>
                            <SelectBox.Labeled
                                label={t('presets.add-preset-modal-copied-preset-name')}
                                name='sourcePresetId'
                                testId="clonePresetFormClonedPresetSelector"
                                defaultCaption={"Select to compare"}
                                options={presetsFormFieldOptions}
                                value={''}
                            />
                        </Row>
                        <Row>
                            <TextField.Labeled
                                label={t('presets.add-preset-modal-new-preset-name')}
                                name="presetName"
                                type="text"
                                testId="clonePresetFormInputName"
                                required
                                autoFocus
                            />
                        </Row>
                        {formFields}
                    </Form>
                </Modal>
            </LoadingBackdrop>
        )
    }

    return (
        <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
            {
                presetOptions.length ?
                    <>
                        <Tooltip title={t('presets.clone-preset-btn')} placement="top">
                            <IconButton
                                icon={PlusIcon}
                                iconColor={theme === 'light' ? themeColors.navyBlue : themeColors.white}
                                backgroundColor={theme === 'light' ? themeColors.white : themeColors.navyBlue}
                                onClick={copyBtnClick}
                                testId={testId}
                            />
                        </Tooltip>
                        <DropDown
                            options={presetOptions}
                            selectedOptionInd={selectedOptionIdx}
                            coloredButton={true}
                            optionsAlign={OptionsAlign.LEFT}
                            // disabled={allowedModesDontExist}
                            titleColorLight={themeColors.navyBlue}
                            titleColorDark={themeColors.white}
                            optionColorLight={themeColors.navyBlue}
                            optionColorDark={themeColors.white}
                            iconColorLight={themeColors.white}
                            iconColorDark={themeColors.navyBlue}
                            iconRectangleWidth={35}
                            tooltip={t('header.select-preset')}
                            testId="presetSelector"
                        />
                    </>
                    : null
            }
            {
                showModal
                    ? getModal(setShowModal, formFields, presetEntityName)
                    : null
            }
        </div>
    )
}

export default ProjectPresetsSelector;
import React, {Dispatch, SetStateAction} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import "./add-model-popup.scss";
import {projectsApi} from "../api/projectsApi";
import {useTranslation} from "react-i18next";
import Modal from "../components/Modal/Modal";
import {isErrorResponse} from "../utils/utils";
import {useToastContext} from "../context/toastContext";
import {useTheme} from "../context/themeContext";
import {useProjectsLoader} from "../hooks/projects/useProjectsLoader";
import Button from "../components/buttons/Button";
import {addedProjectId} from "../redux/reducers/projectsReducer";


type Props = {
  setVisible: Dispatch<SetStateAction<any>>;
}

type PROJECT_CREATION_OPTIONS = 'generate' | 'load';

const AddProjectPopup: React.FC<Props> = ({setVisible}) => {
  const dispatch = useDispatch();
  const {loadData: reloadProjects} = useProjectsLoader();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {addToast} = useToastContext();
  const {theme} = useTheme();

  const onSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const formDataObj = {} as {
      name: string;
      code: number;
      projectCreationType: PROJECT_CREATION_OPTIONS;
      copiedProjectId?: string
    };
    for (const [key, val] of formData) {
      if (val) {
        formDataObj[key] = val;
      }
    }

    const {projectCreationType} = formDataObj;

    const data = await projectsApi.createProject({name: formDataObj.name, code: formDataObj.code});

    if (isErrorResponse(data)) {
      addToast(t('toasts.error.project-name-already-exists'), 'error');
      return;
    }

    if (data.projectId) {
      const {projectId} = data;

      dispatch(addedProjectId(projectId));
      if (projectCreationType === "load") {
        setVisible("creation");
      }
      if (projectCreationType === "generate") {
        await reloadProjects();

        setVisible("");
        navigate(`/${data.projectId}`);
      }
    }

  };

  return (
    <Modal title={t("add-project-popup.model-title")} setVisible={setVisible}>
      <form onSubmit={onSubmit}>
        <div className={`addmodel-input-block ${theme}`}>
          <label htmlFor="projName">{t("add-project-popup.project-name")}</label>
          <input
            name='name'
            required
            id="projName"
            type="text"
            autoFocus
          />
        </div>
        <div className="addmodel-input-block">
          <input
            name='code'
            id="code"
            type="number"
            defaultValue={25832}
            hidden
          />
        </div>
        <div className="nav-block">
          <span className="nav-block-header">
            {t("add-project-popup.model-generation-method")}
          </span>
          <div className="input-block">
            <input
              id="generate"
              name='projectCreationType'
              type="radio"
              defaultChecked
              defaultValue="generate"
            />
            <label htmlFor="generate">
              <span className="label-header">
                {t("add-project-popup.generate")}
              </span>
              <span className="label-text">
                {t("add-project-popup.automatic-generation")}
              </span>
            </label>
          </div>
          <div className="input-block">
            <input
              id="load"
              name='projectCreationType'
              type="radio"
              defaultValue="load"
            />
            <label htmlFor="load">
              <span className="label-header">
                {t("add-project-popup.download-initial-files")}
              </span>
              <span className="label-text">
                {t("add-project-popup.create-by-user-files")}
              </span>
            </label>
          </div>
        </div>
        <div className="button-block-addmodel">
        <div className="transparent-button-block">
          <Button
            onClick={() => setVisible("")}
            text={t("add-project-popup.cancel")}
            colorType="transparent"
          />
        </div>
        <div className="disabled-button-block">
          <Button
            type="submit"
            text={t("add-project-popup.continue")}
            colorType="dark"
          />
        </div>
      </div>
      </form>
    </Modal>
  );
};

export default AddProjectPopup;

interface IInitialState {
  currentTime: number;
  isRunning: boolean;
}
interface IAction {
  type: string;
  payload: any;
}
const initialState: IInitialState = {
  currentTime: 0,
  isRunning: false,
};

const TimeLine = (state = initialState, action: IAction) => {
  switch (action.type) {
    case "CURRENTTIME":
      return {
        ...state,
        currentTime: action.payload,
      };
    case "ISRUNNING":
      return {
        ...state,
        isRunning: action.payload,
      };
    case "RESETTIMELINE":
      return {
        ...state,
        currentTime: 0,
      };
    default:
      return state;
  }
};

export default TimeLine;

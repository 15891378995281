import {useTheme} from "../../context/themeContext";
import {Skeleton} from "@mui/material";
import './skeleton.scss';
import {SkeletonProps} from "@mui/material/Skeleton/Skeleton";


type Props = SkeletonProps & {
    skeletonsNum?: number;
};

const SkeletonComponent: React.FC<Props> = ({children, skeletonsNum = 1, ...rest}) => {
    const {theme} = useTheme();

    const getSkeletons = (skeletonsNum: number) => {
        return Array
            .from(Array(skeletonsNum), (_, ind) => {
                return (
                    <Skeleton
                        key={ind}
                        width="100%"
                        height="100%"
                        {...rest}
                    />
                );
            });
    }

    return (
        <div className={`skeleton ${theme}`}>
            <div className={'skeleton__inner'}>
                {
                    children
                        ? children
                        : getSkeletons(skeletonsNum)
                }
            </div>
        </div>
    )
}

export default SkeletonComponent;
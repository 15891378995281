import React, {useEffect} from "react";
import {useProjectsLoader} from "../hooks/projects/useProjectsLoader";
import {LoadingComponent} from "../components/LoadingComponent/LoadingComponent";
import ErrorComponent from "../components/ErrorComponent/ErrorComponent";
import {Outlet} from "react-router-dom";
import {useAuth} from "../context/authContext";


const ProjectsLoaderWrapper = () => {
  const {token} = useAuth();
  const {loading, loaded, loadData: loadProjects, error} = useProjectsLoader();

  useEffect(() => {
    if (token) {
      (async function () {
        await loadProjects();
      })();
    }
  }, [])

  return (
    <>
      {
        token ?
          <LoadingComponent isLoading={loading}>
            <ErrorComponent error={error}>
              {
                loaded
                    ? <Outlet/>
                    : null
              }
            </ErrorComponent>
          </LoadingComponent>
          : null
      }
    </>
  );
}

export default ProjectsLoaderWrapper;
import {IconProps} from "../icons";
import {themeColors} from "../../../styles/theme";


export const CheckIcon = ({width = 12, height = 12, color = themeColors.grey}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 3.49992L4.5 9.49992L1.75 6.74992L2.455 6.04492L4.5 8.08492L9.795 2.79492L10.5 3.49992Z"
            fill={color}/>
    </svg>
  )
}
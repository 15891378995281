import {useMemo, useRef} from 'react';
import React from "react";
import {Line} from "react-chartjs-2";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId, selectProjectsById} from "../../../redux/selectors/selectors";
import {dashboardApi} from "../../../api/dashboardApi";
import {useLoadChartData} from "../hooks/useLoadChartData";
import {Mode} from "../types";
import {ChartData} from "chart.js";
import {Skeleton} from "@mui/material";
import {ProjectEntity} from "../../../api/entities";
import {DataById} from "../../../redux/map/types";


type Props = {
  mode: Mode;
  projectIdToCompare?: string
}

const combineProjectsDatasets = (data: ChartData<'line', any, any>[], projectsById: DataById<ProjectEntity>, projectIds: string[]) => {
  return data.reduce((acc, cur, ind) => {
    if (cur?.datasets) {
      const {projectName} = projectsById[projectIds[ind]];

      for (const dataset of cur.datasets) {
        const datasetCopy = {
          ...dataset,
          label: `${projectName} ${dataset.label}`,
          values: [...dataset.data]
        }
        acc.datasets.push(datasetCopy);
      }

    }
    if (!acc?.labels?.length && cur?.labels) {
      acc.labels = cur.labels;
    }
    return acc;
  }, {datasets: [], labels: []});
}

const DoubleLineChart = ({mode, projectIdToCompare = ''}: Props) => {
  const projectId = useTypedSelector(selectCurrentProjectId);
  const projectsById = useTypedSelector(selectProjectsById);
  const abortControllerRef = useRef(new AbortController());

  const projectIds = [projectId, ...(projectIdToCompare ? [projectIdToCompare] : [])];

  const promises: Promise<ChartData>[] = useMemo(() => {
    return projectIds.map(projectId => dashboardApi.getModeStats({
      projectId,
      mode,
      abortSignal: abortControllerRef.current.signal
    }))
  }, [projectId, projectIdToCompare, mode]);

  const {loading, data} = useLoadChartData<ChartData<'line', any, any>[]>({promiseOrPromises: promises});

  const combinedData = useMemo(() =>
    combineProjectsDatasets(data || [], projectsById, projectIds), [data]
  );

  const getChart = (loading: boolean, data: ChartData<'line', any, any>) => {
    const {datasets, labels} = data;
    if (datasets.length && labels?.length && !loading) {
      return (
        <Line
          data={data}
          options={{
            // maintainAspectRatio: false,
            plugins: {
              title: {
                display: false,
                text: 'hi'
              },
              legend: {
                display: true
              }
            }
          }}
        />
      )
    }
    return <Skeleton variant="rectangular"/>;
  }

  return (
    <div className="double-line-chart">
      {
        getChart(loading, combinedData as any)
      }
    </div>
  )
}
export default DoubleLineChart;
import {SearchRoadSegmentsDataType} from "../../../../api/types/types";
import {roadManagementApi} from "../../../../api/roadManagementApi";
import {isErrorResponse} from "../../../../utils/utils";
import {Form, Row} from "../../../../components/form/Form/Form";
import * as DrawGeometryField from "../../../../components/form/DrawGeometryField/DrawGeometryField";
import * as SelectBox from "../../../../components/form/SelectBox/SelectBox";
import * as TextField from "../../../../components/form/TextField/TextField";
import {LoadingComponent} from "../../../../components/LoadingComponent/LoadingComponent";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {selectDrawnFeatures, selectSelectedPresetId} from "../../../../redux/selectors/selectors";
import {useDispatch} from "react-redux";
import {useToastContext} from "../../../../context/toastContext";
import {useTranslation} from "react-i18next";
import {RoadInfrastructureSegmentIdPairEntity} from "../../../../api/entities/replancity_RoadInfrastructureTollPair";
import {HIGHWAY_TYPES} from "../../types";
import {FeatureProperties} from "../../../../redux/map/types";


const GroupSelectionSearchForm = ({setSelectedSegments}: {
    setSelectedSegments: Dispatch<SetStateAction<RoadInfrastructureSegmentIdPairEntity[]>>;
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [roadType, setRoadType] = useState<string>();
    const [numberOfLanes, setNumberOfLanes] = useState<string>();
    const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);
    const formRef = useRef<HTMLFormElement>(null);
    const presetId = useTypedSelector(selectSelectedPresetId);
    const drawnFeatures = useTypedSelector(selectDrawnFeatures) as GeoJSON.Feature<any, Partial<FeatureProperties>>[];

    const feature = drawnFeatures?.[0] ? JSON.stringify(drawnFeatures?.[0]) : '';

    const {addToast} = useToastContext();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const abortControllerRef = useRef(new AbortController());

    const highwayTypesOptions = HIGHWAY_TYPES.map(value => ({
        value
    }));

    useEffect(() => {
        setDisabledSubmit(!feature);
    }, [feature]);

    const submitFn = async (event) => {
        setLoading(true);

        const formData = new FormData(event.target);
        const formDataObj = {roadInfrastructureSelection: {presetId}} as SearchRoadSegmentsDataType;
        for (const [key, val] of formData) {
            if (val) {
                //TODO geometryJson property is created by EditGeometryToolbar => replace geometryJson by feature everywhere and in backend
                if (key === 'geometryJson') {
                    formDataObj['roadInfrastructureSelection']['feature'] = val as string;

                } else {
                    formDataObj['roadInfrastructureSelection'][key] = val;
                }
            }
        }

        const resp: RoadInfrastructureSegmentIdPairEntity[] = await roadManagementApi.searchRoadSegments(formDataObj, abortControllerRef.current.signal);

        if (isErrorResponse(resp)) {
            addToast(`${resp.error}`, 'error');
            setLoading(false);
        } else {
            if (!resp.length) {
                addToast(`${t('road-network.group-selection-found-no-features')}`, 'warning');
            }

            setSelectedSegments(resp ?? []);

            setLoading(false);
        }
    }

    return (
        <LoadingComponent isLoading={loading}>
            <Form
                name="segmentsParamsForm"
                onFormSubmit={submitFn}
                submitBtnTitle={t('road-network.select-btn')}
                disabled={disabledSubmit}
                ref={formRef}
                testId="groupSelectionSearchForm"
                submitBtnTestId="groupSelectionSearchFormSubmitBtn"
            >
                <Row>
                    <DrawGeometryField.Labeled
                        label={t('road-network.polygon')}
                        name='geometryJson'
                        value={feature}
                        drawMode='Polygon'
                        required={true}
                    />
                </Row>
                <Row>
                    <SelectBox.Labeled
                        label={t('road-network.road-type')}
                        name='highwayType'
                        options={highwayTypesOptions}
                        value={roadType}
                        onChange={(event) => setRoadType(event.target.value)}
                    />
                </Row>
                <Row>
                    <TextField.Labeled
                        name='numberOfLanes'
                        label={t('road-network.number-of-lanes')}
                        type="number"
                        min="1"
                        value={numberOfLanes}
                        onChange={(event) => setNumberOfLanes(event.target.value)}
                    />
                </Row>
            </Form>
        </LoadingComponent>
    )
}

export default GroupSelectionSearchForm;
import React from 'react';
import ReplanIcon from '../../assets/replan-city-login.svg';
import './auth-pages-wrapper.scss';
import {isReplanApp} from "../../utils/utils";
import PlansimLogo from "../../assets/plansim-auth-logo.svg";
import HeaderLogo from "../HeaderLogo/HeaderLogo";
import classNames from "classnames";

type Props = {
  children: React.ReactNode;
  showLogoColumn?: boolean;
}

export const AuthPagesWrapper: React.FC<Props> = ({children, showLogoColumn = true}) => {
  const getLogoIconByOrigin = (url: string) => {
    return isReplanApp(url) ? ReplanIcon : PlansimLogo;
  }

  const wrapperClasses = classNames(
    'auth-pages-wrapper',
    !showLogoColumn && 'logo-col-hidden'
  );

  return (
    <div className={wrapperClasses}>
      <div className="auth-pages-wrapper__header">
        <HeaderLogo active/>
      </div>
      <div className="auth-pages-wrapper__form-col">
        <div className="auth-pages-wrapper__form">
          {children}
        </div>
      </div>
      {
        showLogoColumn ?
          <div className='auth-pages-wrapper__logo-col'>
            <img
              className="auth-pages-wrapper__icon"
              src={getLogoIconByOrigin(window.location.origin)}
            />
          </div>
          : null
      }
    </div>
  )
}
import React, {useState} from "react";
import {Alert, Snackbar} from "@mui/material";
import {AlertColor} from "@mui/material/Alert/Alert";
import {useToastContext} from "../../context/toastContext";


export type ToastType = {
  id: string,
  message: string
  type: AlertColor;
  autoHide?: boolean;
}

export const Toast = ({message, type, autoHide = true}: ToastType) => {
  const [open, setOpen] = useState<boolean>(true);
  const {onClose} = useToastContext();

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(prevOpen => !prevOpen);
    //TODO Add normal id
    onClose('1');
  };

  return (
    <Snackbar
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      open={open}
      autoHideDuration={autoHide ? 5000 : null}
      onClose={handleClose}
    >
      <Alert variant="filled" severity={type} onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  )
}
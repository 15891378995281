import {IconProps} from "../icons";
import {themeColors} from "../../../styles/theme";


export const DeleteIcon = ({width = 16, height = 16, color = themeColors.textGrey}: IconProps) => {
  return (
    <svg className="icon" width={width} height={height} viewBox="0 0 16 16" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.99998 12.6667C3.99998 13.0203 4.14046 13.3594 4.3905 13.6095C4.64055 13.8595 4.97969 14 5.33331 14H10.6666C11.0203 14 11.3594 13.8595 11.6095 13.6095C11.8595 13.3594 12 13.0203 12 12.6667V4.66667H3.99998V12.6667ZM5.33331 6H10.6666V12.6667H5.33331V6ZM10.3333 2.66667L9.66665 2H6.33331L5.66665 2.66667H3.33331V4H12.6666V2.66667H10.3333Z"
        fill={color}/>
    </svg>
  )
}
import React, {CSSProperties, PropsWithChildren, useRef} from "react";
import useOutsideClickListener from "../DropDown/useOutsideClickListener";
import './modal.scss';
import windowClose from "../../assets/window-close.svg";
import {useTheme} from "../../context/themeContext";


export interface ModalProps {
  title: string;
  setVisible: (close: boolean) => void;
  testId?: string;
  style?: CSSProperties
}


const Modal = ({title, setVisible, testId, children, style}: PropsWithChildren<ModalProps>) => {
  const ref = useRef<any>(null);
  const {theme} = useTheme();
  useOutsideClickListener(ref, setVisible);

  const handleCloseClick = (e) => {
    setVisible(false);
  }

  return (
    <div
        className="modal"
        {...(testId ? {'data-testid': testId} : {})}
    >
      <div
        className={`modal-wrapper ${theme}`} ref={ref}
        style={style}
      >
        <div className="modal-content">
          <div className={`content-header ${theme}`}>
            <span>{title}</span>
            <img onClick={handleCloseClick} src={windowClose} alt="close"/>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal;
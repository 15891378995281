import {BaseEntity} from "./BaseEntity";

export type ChargerPropertyEntity = {
    chargerTypePseudonameForSimulation: string;
    dynamicLocationsEnabled: boolean;
    geometryJson: string;
    initialPosition: string;
    numberOfPlugs: number;
    operatesFrom: string;
    operatesTo: string;
    powerPerPlugKW: number;
    servedTransportModes: number;
} & BaseEntity;

export const CHARGER_PROPERTY_ENTITYNAME = 'replancity_ChargerProperty';